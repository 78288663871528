/* Create Account screen */

import { Component } from "react";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
    MDBModalHeader,
    MDBInput,
    MDBModalBody,
} from "mdbreact";

import TandC from "../../../components/CreateAccount/TandC";

import queryString from "query-string";

import axios from "../../../axios";

import brandsData from "../../../brands/common/brands.json";




const parsed = queryString.parse(window.location.search);

class Review extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tandc: false,
			modalTandC: false,
			loadingTandC: false,
		};
	}

	componentDidMount() {
		this.getTandC();
	}

	getTandC = () => {
		let locale;
		if (parsed["locale"]) {
			locale = parsed["locale"];
		} else {
			if (
				sessionStorage.getItem("i18nextLng") === "en_us" ||
				sessionStorage.getItem("i18nextLng") === "en" ||
				sessionStorage.getItem("i18nextLng") === "en-US"
			) {
				locale = "en-us";
			} else {
				locale = "en-us";
			}
		}
		let data = {
			locale: locale,
		};
		this.setState({ loadingTandC: true });
		axios
			.post("/subscriberconsole/viewTandC", data, {
				headers: {},
			})
			.then((response) => {
				this.setState({ tandctext: response.data.TandC });

				this.setState({ loadingTandC: false });
			})
			.catch((error) => {
				console.log("🚀 ~ file: Review.js:70 ~ Review ~ error", error)
				this.setState({ loadingTandC: false });
			});
	};

	toggleCheckboxValueTandC = () => {
		this.setState({ tandc: !this.state.tandc });
	};

	toggleTandC = () => {
		this.setState({
			modalTandC: !this.state.modalTandC,
		});
	};

	render() {
		let BrandName;

		brandsData.forEach(({ name, url }) => {
			if (url.includes(window.location.hostname)) {
				BrandName = name;
			}
		});
		return (
			<>
				<div className="CreateAccount">
					<MDBModal
						isOpen={this.state.modalTandC}
						size="lg"
						className="form"
						centered
						overflowScroll
					>
						<MDBModalHeader toggle={this.toggleTandC}>
							{BrandName} Terms and Conditions
						</MDBModalHeader>
						<MDBModalBody className="faqModal" id="faq">
							<TandC />
							<button
								className="btn Ripple-parent btn-default nextButton"
								onClick={this.toggleTandC}
							>
								Close
							</button>
						</MDBModalBody>
					</MDBModal>
					<MDBContainer className="Form" fluid>
						<MDBRow className="g-0 m-0">
							<MDBCol
								sm="12"
								md="12"
								lg="12"
								className="ml-auto p-0"
							>
								<div className="topBanner">
									<h2>Review</h2>
								</div>
								<div className="FormContainer">
									<p className="title">
										You're Switching from{" "}
										{sessionStorage.getItem(
											"servicePlanNameSubscriber"
										)}{" "}
										to <br />
										{sessionStorage.getItem(
											"newServicePlanName"
										)}
									</p>
									<div className="loginDisclosure">
										<div className="loginIcon">
											<MDBIcon
												icon="check"
												className="faUser"
											/>
											<p>
												Your new plan will begin once
												you accept the terms and click
												submit.
											</p>
										</div>
										<div className="loginIcon">
											<MDBIcon
												icon="check"
												className="faUser"
											/>
											<p>
												The prorated amount for the new
												service plan effective today
												will be included in your next
												bill.
											</p>
										</div>
										<MDBRow className="g-0 plan">
											<MDBCol lg="8">
												<div>
													<p>
														<strong>
															New Montly Service
															Plan
														</strong>
													</p>
													<p>
														{sessionStorage.getItem(
															"newServicePlanName"
														)}
													</p>
													<p>
														Start Date:{" "}
														{
															Intl.DateTimeFormat("en-US", {
																month: "2-digit",
																day: "2-digit",
																year: "numeric",
														  }).format(new Date(sessionStorage.getItem(
															"dateStart"
														)))
														}
													</p>
													{sessionStorage.getItem(
														"daysofservice"
													) ? (
														<p>
															Days of Service:{" "}
															{sessionStorage.getItem(
																"daysofservice"
															)}
														</p>
													) : (
														""
													)}
												</div>
											</MDBCol>
											<MDBCol lg="4">
												<div>
													<br />
													<p>
														$
														{sessionStorage.getItem(
															"billingCyclePrice"
														) / 100}
													</p>
												</div>
											</MDBCol>
										</MDBRow>
										<MDBRow className="g-0 plan">
											<MDBCol lg="8">
												<div>
													<p>
														<strong>
															Service Address
														</strong>
													</p>
													<p>
														{sessionStorage.getItem(
															"venueName"
														)}
													</p>
													<p>
														{sessionStorage.getItem(
															"address"
														) +
															", Unit " +
															sessionStorage.getItem(
																"unitName"
															)}
													</p>
													<p>
														{sessionStorage.getItem(
															"city"
														) +
															", " +
															sessionStorage.getItem(
																"state"
															) +
															" " +
															sessionStorage.getItem(
																"postalcode"
															)}
													</p>
												</div>
											</MDBCol>
										</MDBRow>
										<MDBRow className="g-0 plan">
											<MDBCol lg="8">
												<div>
													<p>
														<strong>
															Account Holder
														</strong>
													</p>
													<p>
														{sessionStorage.getItem(
															"firstName"
														) +
															" " +
															sessionStorage.getItem(
																"lastName"
															)}
													</p>
													<p>
														{sessionStorage.getItem(
															"emailSubscriber"
														)}
													</p>
													<p>
														{sessionStorage.getItem(
															"phoneNumberSubscriber"
														)}
													</p>
												</div>
											</MDBCol>
										</MDBRow>
										<MDBRow className="g-0 plan">
											<MDBCol lg="8">
												<div>
													<p>
														<strong>
															Billing Address
														</strong>
													</p>
													<p>
														{sessionStorage.getItem(
															"venueName"
														)}
													</p>
													<p>
														{sessionStorage.getItem(
															"address"
														) +
															", Unit " +
															sessionStorage.getItem(
																"unitName"
															)}
													</p>
													<p>
														{sessionStorage.getItem(
															"city"
														) +
															", " +
															sessionStorage.getItem(
																"state"
															) +
															" " +
															sessionStorage.getItem(
																"postalcode"
															)}
													</p>
												</div>
											</MDBCol>
										</MDBRow>
										<MDBRow>
											<MDBCol
												col="12"
												className="leftAlign"
											>
												<MDBInput
													type="checkbox"
													id="tandc"
													name="tandc"
													onChange={
														this
															.toggleCheckboxValueTandC
													}
													value={this.state.tandc}
												/>
												<p className="checkboxLabel">
													I agree to the billing{" "}
													<button
														onClick={
															this.toggleTandC
														}
													>
														Terms and Conditions
													</button>
												</p>
											</MDBCol>
										</MDBRow>
										<div className="backButton">
											<a
												className="btn Ripple-parent btn-default"
												href="/myaccount/upgrade"
											>
												Back
											</a>
										</div>
										<div className="nextButton">
											<a
												className={
													this.state.tandc !== false
														? "btn Ripple-parent btn-default"
														: "btn Ripple-parent btn-default disabled"
												}
												href="/myaccount/upgrade/checkout"
											>
												Next
											</a>
										</div>
									</div>
								</div>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</div>
			</>
		);
	}
}

export default Review;