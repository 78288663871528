import React, { Component } from "react";
import {
  MDBModalBody,
  MDBModalHeader,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBModal,
  toast,
  MDBProgress,
  MDBBtn
} from "mdbreact";
import AddSubscriber from "../../../../components/NetworkMgmt/Forms/AddSubscriber/AddSubscriber";
import ViewModifySubscribers from "../../../../components/NetworkMgmt/Venues/ViewVenueSubscribers";
import ResendActivation from "../../../../components/VenueMgmt/ResendActivation/ResendActivation";
import ServicesEndingSoon from "../../../../components/VenueMgmt/UpcomingExpirations/ServicesEndingSoon";
import axios from "../../../../axios";
import { withTranslation } from "react-i18next";
import DownloadSubscribers from "../../../../components/NetworkMgmt/Forms/BulkDownloadSubscribers/BulkDownloadSubscribers";
import UploadSubscribers from "../../../../components/NetworkMgmt/Forms/BulkUploadSubscribers/BulkUploadSubscribers";
import AbandonedSubscribers from "../../../../components/NetworkMgmt/Transactions/AbandonedSubscribers";
import InvoicesTable from "../../../../components/NetworkMgmt/Transactions/Invoices";
import PaymentUpdateRequiredTable from "../../../../components/NetworkMgmt/Transactions/PaymentUpdateRequired";
import SearchSubscribers from "../../../../components/NetworkMgmt/Subscribers/SearchSubscribers";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import brandsData from "../../../../brands/common/brands.json";
let showSendEmail;
let BrandDirectory;
class SubscriberManagementNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddNewSubscriber: false,
      modalViewModifySubscriber: false,
      modalResendActivation: false,
      modalServicesEndingSoon: false,
      activeData: this.props.activeData,
      allActiveData: this.props.allActiveData,
      loading: false,
      loading_upload: false,
      uploadSucceeded: undefined,
      lastProcessed: 0,
      unitForm: (
        <MDBSelect
          id="selectUnit"
          getValue={this.resetBuildingForm}
          className="form-control"
        >
          <MDBSelectInput
            className="selectInput selectUnit"
            selected="Choose your unit"
          />
        </MDBSelect>
      ),
      buildingForm: (
        <MDBSelect
          id="selectBuilding"
          getValue={this.handleSelectChangeBuilding}
          className="form-control"
        >
          <MDBSelectInput
            className="selectInput selectBuilding"
            selected="Choose your building"
          />
        </MDBSelect>
      ),
      floorForm: (
        <MDBSelect
          id="selectFloor"
          getValue={this.handleSelectChangeFloor}
          className="form-control"
        >
          <MDBSelectInput
            className="selectInput selectFloor"
            selected="Choose your floor"
          />
        </MDBSelect>
      ),
      editorHeaderState: EditorState.createEmpty(),
      editorMessageState: EditorState.createEmpty(),
      emailAddresses: [],
      value: "",
      error: null,
      paymentFailed: false,
      modalAddPayment: true
    };
  }
  componentDidMount() {
    this.getVenues();
  }
  toggleAddNewSubscriber = () => {
    this.setState({
      modalAddNewSubscriber: !this.state.modalAddNewSubscriber,
    });
  };
  toggleViewModifySubscriber = () => {
    this.setState({
      modalViewModifySubscriber: !this.state.modalViewModifySubscriber,
    });
  };
  toggleResendActivation = () => {
    this.setState({
      modalResendActivation: !this.state.modalResendActivation,
    });
  };
  toggleServicesEndingSoon = () => {
    this.setState({
      modalServicesEndingSoon: !this.state.modalServicesEndingSoon,
    });
  };
  toggleBulkDownloadSubscribers = () => {
    this.setState({
      modalDownloadSubscribers: !this.state.modalDownloadSubscribers,
    });
  };
  toggleBulkUploadSubscribers = () => {
    this.setState({
      modalBulkUploadSubscribers: !this.state.modalBulkUploadSubscribers,
    });
  };
  toggleSearchSubscriber = () => {
    this.setState({
      modalSearchSubscriber: !this.state.modalSearchSubscriber,
    });
  };
  toggleAbandonedSubscribersTable = () => {
    this.setState({
      modalAbandonedSubscribersTable: !this.state.modalAbandonedSubscribersTable,
    });
  };
  toggleInvoicesTable = () => {
    this.setState({
      modalInvoicesTable: !this.state.modalInvoicesTable,
    });
  };
  togglePaymentUpdateRequiredTable = () => {
    this.setState({
      modalPaymentUpdateRequiredTable: !this.state.modalPaymentUpdateRequiredTable,
    });
  };
  toggleSendEmailSubscribers = () => {
    this.setState({
      modalSendEmailSubscribers: !this.state.modalSendEmailSubscribers
    });
  }
  toggleError = () => {
    this.setState({
      modalError: !this.state.modalError,
    });
  };
  togglePayment = () =>{
    this.setState({
            modalAddPayment: !this.state.modalAddPayment,
        });
  }
  submitHandler = (
    venueID,
    dateEnd,
    email,
    firstName,
    lastName,
    phoneNumber,
    language,
    unitID,
    building,
    floor,
    contactpref_email,
    contactpref_sms,
    dateStart,
    unitID_internal,
    servicePlan
  ) => {
    let isoDateString;
    let isoDateStart;
    this.setState({ loading: true });
    if (!isNaN(new Date(dateEnd))) {
      isoDateString = new Date(dateEnd)
        .toISOString()
        .replace(/:00.\d+Z$/g, "Z");
    }
    if (!isNaN(new Date(dateStart))) {
      isoDateStart = new Date(dateStart)
        .toISOString()
        .replace(/:00.\d+Z$/g, "Z");
    }
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      GivenName: firstName,
      FamilyName: lastName,
      "PhoneNumber.E164": phoneNumber,
      locale: language,
      "EndDateTimeUTC.ISO8601": isoDateString,
      "StartDateTimeUTC.ISO8601": isoDateStart,
      VenueID: venueID,
      PolicyforSubscriberKey: {
        UnitID_internal: unitID_internal,
      },
      contactpref_email: true,
      contactpref_sms: contactpref_sms,
      UserTimeZone: new Date().getTimezoneOffset(),
      ServicePlanID: servicePlan
    };
    axios
      .post("/venuemgmtconsole/subscribers/addSubscriber", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Added Subscriber!", {
            closeButton: false,
          });
          this.toggleAddNewSubscriber();
          this.props.updateName(this.state.venueName, venueID);
          this.setState({ loading: false });
          if(response.data.paymentFailed){
            this.setState({
                paymentFailed: true
            })
          }
          sessionStorage.setItem("unitID_internalSubscriber", unitID_internal)
          sessionStorage.setItem("stripeClientSecretSubscriber", response.data.StripeClientSecret)
        },
        (error) => {
          toast.error(error.response.data.message, {
            closeButton: false,
          });
          this.setState({ loading: false });
        }
      );
  };
  submitHandlerResendActivation = (email) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: this.props.venueID,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    this.setState({ loading: true });
    axios
      .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Resent Confirmation!", {
            closeButton: false,
          });
          this.toggleResendActivation();
          this.setState({ loading: false });
        },
        (error) => {
          toast.error("There was an error", {
            closeButton: false,
          });
          this.toggleResendActivation();
          this.setState({ loading: false });
        }
      );
  };
  getVenues = (selectedID) => {
      this.setState({
        venueID: undefined,
        selectedUnit: undefined,
        selectedBuilding: undefined,
        selectedFloor: undefined,
        optionTemplateBuilding: [],
        optionTemplateFloor: [],
        optionTemplateUnits: [],
        unitID_internal: undefined,
        venueName: undefined
      })
      this.setState({ attributeForm: [] });
      let body = { AccessToken: sessionStorage.getItem("AccessToken") };
      axios
        .post("/networkoperatorconsole/venues/listVenues", body, {
          headers: {},
        })
        .then((response) => {
          var formArrayVenues = [];
          let optionTemplateVenues = [];
          this.setState({ venueList: response.data.VenueList });
          Object.keys(response.data.VenueList).map((oneKey, i) => {
            if(selectedID===undefined){
              this.setState({firstID: oneKey})
            }
            if (selectedID === oneKey) {
              optionTemplateVenues.push(
                <MDBSelectOption selected value={oneKey}>
                  {response.data.VenueList[oneKey]["VenueName"]}
                </MDBSelectOption>
              );
            } else {
              optionTemplateVenues.push(<MDBSelectOption value={oneKey}>{response.data.VenueList[oneKey]["VenueName"]}</MDBSelectOption>);
            }
            return true;
          });
          formArrayVenues.push(
            <MDBSelect id="selectVenueSubscriberModal" getValue={this.handleSelectChangeVenue} className="form-control">
              <MDBSelectInput className="selectInput venueSelectedSubscriberModal" selected="Choose your venue" />
              <MDBSelectOptions search>{optionTemplateVenues}</MDBSelectOptions>
            </MDBSelect>
          );
          this.setState({
            attributeForm: formArrayVenues,
          });
        })
        .catch((error) => {
          this.setState({ error: true });
        });
    };
    getVenueDetails = (index) => {
		this.setState({ loadingVenueDetails: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: index,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    this.setState({
      address1: undefined,
      address2: undefined,
      city: undefined,
      region: undefined,
      country: undefined,
      postalcode: undefined,
      unitData: undefined,
      expiringData: undefined,
      searchData: undefined,
      value: "",
      defaultSubscriptionDurationRes: 0,
      loading: true,
      unifForm: undefined,
      hasUpdated: false,
    });
    axios
      .post("/networkoperatorconsole/venues/listVenueDetails", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          address1: response.data[index]["AddressLine1"],
          address2: response.data[index]["AddressLine2"],
          city: response.data[index]["City"],
          region: response.data[index]["State"],
          country: response.data[index]["Country.ISO3166A3"],
          postalcode: response.data[index]["PostalCode"],
          unitData:
            response.data[index]["Units-Building-Floor-List-Subscribers"],
          defaultSubscriptionDurationRes:
            response.data[index][
              "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess"
            ],
          venueServicePlans: response.data[index]["AvailableServicePlans"],
          hasUpdated: true
        });
        let optionTemplatePlans = [];
        Object.keys(response.data[index]["AvailableServicePlans"]).map(
          (oneKey, i) => {
            optionTemplatePlans.push({
              value: oneKey,
              name: response.data[index]["AvailableServicePlans"][oneKey][
                "ServicePlanName"
              ],
            });
            return true;
          }
        );
        this.setState({
          optionTemplatePlans: optionTemplatePlans,
          loading_prop_groups: false,
        });
				this.setState({ loadingVenueDetails: false })
        let units = Object.keys(response.data[index]["Units-Building-Floor-List-Subscribers"]);
        let buildings = [];
        let objUnits = {};
        for (let i = 0; i < units.length; i++) {
          buildings.push(Object.keys(response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]]));
          for (let j = 0; j < buildings[i].length; j++) {
            let floor = Object.keys(response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]]);
            for (let k = 0; k < floor.length; k++) {
                            if (
							  response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["PrimaryUnitID_internal"] === ""
							&& 
							(
							(
								(
							  response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["allowOverlappingSubscriptionWiredUnit"]===false
								) 
								&& 
								(
							 	 Object.keys(response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["Subscriptions"]).length === 0 
								)
							) 
							||
							(
							  response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["allowOverlappingSubscriptionWiredUnit"]===true
							)
							)
			){
                objUnits[i] = response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i]];
              }
            }
          }
        }
        let optionTemplateUnits = [];
        Object.keys(
          objUnits
        ).map((oneKey, i) => {
          optionTemplateUnits.push({ value: units[oneKey], name: units[oneKey] });
          return true;
        });
        let optionTemplateServicePlans = [];
				Object.keys(
					response.data[index]["AvailableServicePlans"]
				).map((value, i) => {
					optionTemplateServicePlans.push({ value: response.data[index]["AvailableServicePlans"][value]['ServicePlanID'], name: response.data[index]["AvailableServicePlans"][value]['ServicePlanName'] });
					return true;
				});
				this.setState({
					optionTemplateUnits: optionTemplateUnits,
					optionTemplateServicePlans: optionTemplateServicePlans,
					loading: false
				});
      })
      .catch((error) => {
        this.setState({ loadingVenueDetails: false });
        this.setState({ 
          error: true,
          loading: false
        });
      });
  };
    handleSelectChangeVenue = (value) => {
      this.setState({
        optionTemplateUnits: [],
        optionTemplateBuilding: [],
        optionTemplateFloor: [],
      });
      var venue = document.getElementsByClassName("venueSelectedSubscriberModal")[0].value;
      var lDiv = document.createElement("div");
      document.body.appendChild(lDiv);
      lDiv.style.fontSize = "" + 20 + "px";
      lDiv.style.position = "absolute";
      lDiv.style.left = -1000;
      lDiv.style.top = -1000;
      lDiv.innerHTML = venue;
      document.body.removeChild(lDiv);
      lDiv = null;
      sessionStorage.setItem("venueName", venue);
      sessionStorage.setItem("venueID", value[0]);
      this.getVenueDetails(value[0]);
      this.setState({ selectedID: value[0] });
      this.updateName(venue, value[0]);
    };
    handleSelectChangeVenueFromList = (venueName, venueID) => {
      sessionStorage.setItem("venueName", venueName);
      sessionStorage.setItem("venueID", venueID);
      this.getVenues(venueID);
      this.setState({ selectedID: venueID });
      this.updateName(venueName, venueID);
    };
    resetBuildingForm = (value) => {
    this.setState({
      buildingForm: (
        <MDBSelect
          id="selectBuilding"
          getValue={this.handleSelectChangeBuilding}
          className="form-control"
        >
          <MDBSelectInput
            className="selectInput selectBuilding"
            selected="Choose your building"
          />
        </MDBSelect>
      ),
    });
    this.setState({
      floorForm: (
        <MDBSelect
          id="selectFloor"
          getValue={this.handleSelectChangeFloor}
          className="form-control"
        >
          <MDBSelectInput
            className="selectInput selectFloor"
            selected="Choose your floor"
          />
        </MDBSelect>
      ),
    });
    setTimeout(() => {
      this.handleSelectChangeUnit(value);
    }, 1);
  };
    handleSelectChangeUnit = (value) => {
    if(this.state.unitData!==undefined){
      this.setState({
        selectedUnit: value,
        optionTemplateBuilding: [],
        optionTemplateFloor: [],
        selectedBuilding: undefined,
        selectedFloor: undefined
      });
      let optionTemplateBuilding = [];
      this.setState({
        optionTemplateBuilding: [],
      });
      Object.keys(this.state.unitData[value]).map((oneKey, i) => {
        if (oneKey === "DEFAULT") {
          optionTemplateBuilding.push({ value: oneKey, name: oneKey });
        } else {
          optionTemplateBuilding.push({ value: oneKey, name: oneKey });
        }
        return true;
      });
      this.setState({
        optionTemplateBuilding: optionTemplateBuilding,
      });
    }
  };
  resetFloorForm = (value) => {
    setTimeout(() => {
      this.handleSelectChangeBuilding(value);
    }, 1);
  };
  handleSelectChangeBuilding = (value) => {
    if (value !== null) {
      this.setState({ selectedBuilding: value });
      let optionTemplateFloor = [];
      Object.keys(
        this.state.unitData[this.state.selectedUnit][value]
      ).map((oneKey, i) => {
        if (oneKey === "DEFAULT") {
          optionTemplateFloor.push({ value: oneKey, name: oneKey });
        } else {
          optionTemplateFloor.push({ value: oneKey, name: oneKey });
        }
        return true;
      });
      this.setState({
        optionTemplateFloor: optionTemplateFloor,
      });
    }
  };
  handleSelectChangeFloor = (value) => {
    if (value !== null) {
      this.setState({ selectedFloor: value });
      this.setState({
        unitID_internal:
          this.state.unitData[this.state.selectedUnit][
            this.state.selectedBuilding
          ][value]["UnitID_internal"],
      });
    }
  };
    updateName = (value, index) => {
      sessionStorage.setItem("venueName", value);
      sessionStorage.setItem("venueID", index);
      this.setState({ 
        venueID: index,
        venueName: value
      });
    };
  onEditorHeaderStateChange = (editorHeaderState) => {
    this.setState({
      editorHeaderState,
      editorHeaderContent: draftToHtml(convertToRaw(editorHeaderState.getCurrentContent()))
    });
  };
  onEditorMessageStateChange = (editorMessageState) => {
    this.setState({
      editorMessageState,
      editorMessageContent: draftToHtml(convertToRaw(editorMessageState.getCurrentContent()))
    });
  };
  handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.setState({
          emailAddresses: [...this.state.emailAddresses, this.state.value],
          value: ""
        });
      }
    }
  };
  handleChange = evt => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };
  handleDelete = item => {
    this.setState({
      emailAddresses: this.state.emailAddresses.filter(i => i !== item)
    });
  };
  handlePaste = evt => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));
      this.setState({
        emailAddresses: [...this.state.emailAddresses, ...toBeAdded]
      });
    }
  };
  isValid(email) {
    let error = null;
    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      this.setState({ error });
      return false;
    }
    return true;
  }
  isInList(email) {
    return this.state.emailAddresses.includes(email);
  }
  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  submitSendEmail = (event) => {
    event.preventDefault();
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddresses: this.state.emailAddresses,
      Subject: this.state.emailSubject,
      title: this.state.emailTitle,
      header: this.state.editorHeaderContent,
      message: this.state.editorMessageContent,
      "EmailFromARN":"arn:aws:ses:us-east-1:450961373892:identity/vault-admin@roamingiq.com",
      actionbutton: " "
    };
    this.setState({ sendEmail: true });
    axios
      .post("/operatorconsole/subscribers/sendEmail", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Email Sent!", {
            closeButton: false,
          });
          // this.toggleSendEmailSubscribers();
          this.setState({ 
            sendEmail: false,
            emailAddresses: []
          });
        },
        (error) => {
          toast.error("There was an error", {
            closeButton: false,
          });
          // this.toggleSendEmailSubscribers();
          this.setState({ sendEmail: false });
        }
      );
  }
  render() {
    brandsData.forEach(
      ({ name, url, flag_showEmailSubscribers }) => {
           if (url.includes(window.location.hostname)) {
                showSendEmail = flag_showEmailSubscribers;
           }
      }
 );
    if (this.state.postMessage) {
      var h = document.getElementById("overlaySubscriberManagement");
      h.style.display = "block";
      setTimeout(function () {
        h.style.display = "block";
      }, 5000);
      setTimeout(() => {
        h.style.display = "none";
        this.setState({ postMessage: "" });
      }, 6000);
    }
    const { editorHeaderState, editorMessageState } = this.state;
    return (
      <>
        {this.state.paymentFailed ? 
                    <MDBModal
                    isOpen={this.state.modalAddPayment}
                    toggle={this.togglePayment}
                    size="md"
                    className="form"
                    centered
                    >
                    <MDBModalHeader toggle={this.togglePayment}>
                        Modify Billing Credit Card Info
                    </MDBModalHeader>
                    <MDBModalBody className="error">
                                <h4>The subscriber's credit card info needs to be updated for billing purposes.</h4>
                                <MDBBtn
											color="danger"
											className="btn Ripple-parent btn-default centered upgradePlan"
											href={
												"/update/checkout?ui=" +
												window.location.pathname.slice(
													1
												)
											}
										>
											Modify Billing CC
										</MDBBtn>
                    </MDBModalBody>
                    </MDBModal>
                :""}
        {this.state.lastProcessed > 0 ? (
          <>
            <MDBModal isOpen={this.state.modalError} toggle={this.toggle} size="md" className="form" centered>
              <MDBModalHeader toggle={this.toggleError}>
                <h4>Error Uploading Subscribers</h4>
              </MDBModalHeader>
              <MDBModalBody>
                {this.state.lastProcessed === 1 ? (
                  <p>
                    Line {this.state.lastProcessed} (Unit: {this.state.unitUnprocessed}, Building: {this.state.buildingUnprocessed}, Floor:{" "}
                    {this.state.floorUnprocessed}) could not be processed. Re-upload the CSV after fixing line 1.
                  </p>
                ) : (
                  <p>
                    Line {this.state.lastProcessed} (Unit: {this.state.unitUnprocessed}, Building: {this.state.buildingUnprocessed}, Floor:{" "}
                    {this.state.floorUnprocessed}) could not be processed. Re-upload the CSV after fixing line {this.state.lastProcessed} and removing lines 1-
                    {this.state.lastProcessed - 1}.
                  </p>
                )}
              </MDBModalBody>
            </MDBModal>
          </>
        ) : (
          ""
        )}
        {this.state.loading_upload === true ? (
          <div className="overlay" id="overlay" style={{ display: "block" }}>
            <div className="overlayProgressBar">
              <MDBProgress material value={this.state.progress} height="20px" color="danger" striped />
            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.loading === true ? (
          <div className="overlay" id="overlay" style={{ display: "block" }}>
            <div className="overlayMessage">
              <div className="spinner-border spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <MDBModalHeader toggle={this.props.toggle}>
          <h4>Subscriber Management</h4>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="managementModal">
            <MDBRow>
              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleAddNewSubscriber}>
                  <MDBIcon icon="user-plus" />
                  <p>Add New Subscriber</p>
                </div>
              </MDBCol>
              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleViewModifySubscriber}>
                  <MDBIcon icon="edit" />
                  <p>View/Modify Subscriber</p>
                </div>
              </MDBCol>
              {
                sessionStorage.getItem("customerSupport") > 0 ? "" :
                <>
                <MDBCol md="4">
                  <div className="managementModalButton" onClick={this.toggleBulkDownloadSubscribers}>
                    <MDBIcon icon="cloud-download-alt" />
                    <p>Export Subscribers</p>
                  </div>
                </MDBCol>
                <MDBCol md="4">
                  <div className="managementModalButton" onClick={this.toggleBulkUploadSubscribers}>
                    <MDBIcon icon="cloud-upload-alt" />
                    <p>Upload Subscribers</p>
                  </div>
                </MDBCol>
                </>
              }
              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleSearchSubscriber}>
                  <MDBIcon icon="search" />
                  <p>Subscriber Search</p>
                </div>
              </MDBCol>
              {/* <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleAbandonedSubscribersTable}>
                  <MDBIcon icon="shopping-cart" />
                  <p>Abandoned signups</p>
                </div>
              </MDBCol>
              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleInvoicesTable}>
                  <MDBIcon icon="file-invoice-dollar" />
                  <p>Invoices</p>
                </div>
              </MDBCol>
              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.togglePaymentUpdateRequiredTable}>
                  <MDBIcon icon="credit-card" />
                  <p>Payment update required</p>
                </div>
              </MDBCol> */}
              {showSendEmail ? 
                <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleSendEmailSubscribers}>
                  <MDBIcon icon="envelope-open" />
                  <p>Send E-Mail</p>
                </div>
              </MDBCol>
              : ""
              }
            </MDBRow>
          </div>
        </MDBModalBody>
        <MDBModal isOpen={this.state.modalAddNewSubscriber} toggle={this.toggle} size="lg" className="form" centered>
          <AddSubscriber
            toggleAddNewSubscriber={this.toggleAddNewSubscriber}
            submitHandler={this.submitHandler}
            loading={this.state.loading}
            attributeForm={this.state.attributeForm}
            venueID={this.state.venueID}
            venueName={this.state.venueName}
            unitForm={this.state.unitForm}
            selectedUnit={this.state.selectedUnit}
            buildingForm={this.state.buildingForm}
            selectedBuilding={this.state.selectedBuilding}
            floorForm={this.state.floorForm}
            selectedFloor={this.state.selectedFloor}
            hasUpdated={this.state.hasUpdated}
            hasUpdatedUnit={this.state.hasUpdatedUnit}
            hasUpdatedBuilding={this.state.hasUpdatedBuilding}
            hasUpdatedFloor={this.state.hasUpdatedFloor}
            unitID_internal={this.state.unitID_internal}
            optionTemplateUnits={this.state.optionTemplateUnits}
            optionTemplateBuilding={this.state.optionTemplateBuilding}
            optionTemplateFloor={this.state.optionTemplateFloor}
            optionTemplateServicePlans={this.state.optionTemplateServicePlans}
            handleSelectChangeUnit={this.handleSelectChangeUnit}
            handleSelectChangeBuilding={this.handleSelectChangeBuilding}
            handleSelectChangeFloor={this.handleSelectChangeFloor}
            getVenues={this.getVenues}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalViewModifySubscriber} toggle={this.toggle} size="fluid" className="form" centered>
          <ViewModifySubscribers
            toggleViewModifySubscriber={this.toggleViewModifySubscriber}
            attributeForm={this.state.attributeForm}
            venueID={this.state.venueID}
            venueName={this.state.venueName}
            firstID={this.state.firstID}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalResendActivation} toggle={this.toggle} size="lg" className="form" centered>
          <ResendActivation
            toggleResendActivation={this.toggleResendActivation}
            recentSubscribers={this.props.activeData}
            submitHandlerResendActivation={this.submitHandlerResendActivation}
            loading={this.state.loading}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalServicesEndingSoon} toggle={this.toggle} size="fluid" className="form" centered>
          <ServicesEndingSoon
            toggleServicesEndingSoon={this.toggleServicesEndingSoon}
            expirations={this.props.expirations}
            venueID={this.props.venueID}
            venueName={this.props.venueNameSelected}
            updateName={this.props.updateName}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalDownloadSubscribers} toggle={this.toggle} size="md" className="form" centered>
          <DownloadSubscribers
            toggleDownloadSubscribers={this.toggleBulkDownloadSubscribers}
            attributeForm={this.state.attributeForm}
            venueID={this.state.venueID}
            venueName={this.state.venueName}
            loadingVenueDetails={this.state.loadingVenueDetails}
            hasUpdated={this.state.hasUpdated}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalBulkUploadSubscribers} toggle={this.toggle} size="lg" className="form" centered>
          <UploadSubscribers
            toggleBulkUploadSubscribers={this.toggleBulkUploadSubscribers}
            attributeForm={this.state.attributeForm}
            venueID={this.state.venueID}
            venueName={this.state.venueName}
            updateName={this.props.updateName}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalAbandonedSubscribersTable} toggle={this.toggle} size="fluid" className="form" centered>
          <AbandonedSubscribers
            toggleAbandonedSubscribersTable={this.toggleAbandonedSubscribersTable}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalInvoicesTable} toggle={this.toggle} size="fluid" className="form" centered>
          <InvoicesTable
            toggleInvoicesTable={this.toggleInvoicesTable}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalPaymentUpdateRequiredTable} toggle={this.toggle} size="fluid" className="form" centered>
          <PaymentUpdateRequiredTable
            togglePaymentUpdateRequiredTable={this.togglePaymentUpdateRequiredTable}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalSearchSubscriber} toggle={this.toggle} size="fluid" className="form" centered>
          <SearchSubscribers
            toggleSearchSubscriber={this.toggleSearchSubscriber}
            loading={this.state.loading}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalSendEmailSubscribers} toggle={this.toggle} size="fluid" className="form" centered>
          <MDBModalHeader toggle={this.toggleSendEmailSubscribers}>
            <h4>Send Email to Subscribers</h4>
          </MDBModalHeader>
          <MDBModalBody>
          <form className="needs-validation" onSubmit={this.submitSendEmail} noValidate>
          <label htmlFor="defaultFormLoginEmailEx" className="black-text">
						Email Addresses (separate each by comma)
					</label>
          <br/>
          {this.state.emailAddresses.map(item => (
          <div className="tag-item" key={item}>
            {item}
            <button
              type="button"
              className="button"
              onClick={() => this.handleDelete(item)}
            >
              &times;
            </button>
          </div>
        ))}
        <input
          className={"form-control input " + (this.state.error && " has-error")}
          value={this.state.value}
          placeholder="Type or paste email addresses and press `Enter`..."
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onPaste={this.handlePaste}
        />
        {this.state.error && <p className="error">{this.state.error}</p>}
          <br/>
          <label htmlFor="defaultFormLoginEmailEx" className="black-text">
						Email Subject
					</label>
					<input 
            name="emailSubject" 
            id="emailSubject" 
            className="form-control" 
            onChange={this.changeHandler} 
            value={this.state.emailSubject} 
          />
          <br/>
          <label htmlFor="defaultFormLoginEmailEx" className="black-text">
						Title
					</label>
          <input 
            name="emailTitle" 
            id="emailTitle" 
            className="form-control" 
            onChange={this.changeHandler} 
            value={this.state.emailTitle} 
          />
          <br/>
          <label htmlFor="defaultFormLoginEmailEx" className="black-text">
						Header
					</label>
          <Editor
            name="emailHeader"
            className="form-control"
            editorState={editorHeaderState}
            editorClassName="form-control editorHeader"
            onEditorStateChange={this.onEditorHeaderStateChange}
            toolbar={
              {
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
              }
            }
          />
          <br/>
          <label htmlFor="defaultFormLoginEmailEx" className="black-text">
						Message
					</label>
          <Editor
            className="form-control"
            editorState={editorMessageState}
            editorClassName="form-control editorMessage"
            onEditorStateChange={this.onEditorMessageStateChange}
            toolbar={
              {
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
              }
            }
          />
          <div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								{this.state.sendEmail === true ? (
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : (
									"Send Email"
								)}
							</MDBBtn>
						</div>
            </form>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}
export default withTranslation()(SubscriberManagementNavigation);