import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBSpinner, MDBModal, MDBModalBody, MDBModalHeader, toast } from "mdbreact";
import axios from "../../../axios";
import SearchSubscribersTable from "../../../components/NetworkMgmt/Subscribers/Datatables/SearchSubscribersData";
import ModifyResident from "../../../components/VenueMgmt/Forms/ModifyResident/ModifyResident";
import $ from 'jquery';
class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateStart: undefined,
      dateEnd: undefined,
      modalModifyResident: false,
      subscriptionID: undefined,
      searchResults: undefined
    };
    this.findSubscribers = this.findSubscribers.bind(this);
  }
  componentDidMount = () => {
    window.addEventListener('DOMContentLoaded', () => {
      console.log('hello')
      window.querySelector('.newclass1').classList.add('custom-class');
    })
  }
  componentDidUpdate = () => {
    $('.subscribersTable').find('table').addClass('subscribersTableResults');
  }
  updateClassName = (searchResults) => {
    $(function() {
      var lastIdx = null;
      var table = $('.MuiDataGrid-row');
       for (let i = 0; i < searchResults.length; i++){
        if(searchResults.length!==0){
          if (new Date(searchResults[i]["EndDateTimeUTC.ISO8601"]).getTime() < new Date().getTime()){
            $(table[i]).addClass("inactiveSubscription")
          }
          else if (searchResults[i]["SetupSuccessDateTimeUTC.ISO8601"] === ""){
            $(table[i]).addClass("inactiveSubscription")
          }
          else{
            $(table[i]).addClass("activeSubscription")
          }
        }
       }
  } );
  }
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  toggleModifyResident = () => {
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
    });
  };
  findSubscribers() {
    // event.preventDefault();
    this.setState({
      loading: true,
      searchResults: undefined
    });
    let body = {
      "AccessToken": sessionStorage.getItem("AccessToken"),
      "EmailAddress": this.state.subscriberEmail
    }
        axios
      .post("/networkoperatorconsole/shoppingcart/listSubscriptions", body, {
        headers: {},
      })
      .then(
        (response) => {
                    this.setState({ searchResults: response.data.Subscriptions });
          this.setState({ loading: false });
          // this.updateClassName(response.data.Subscriptions);
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
  }
  getVenueDetails = (key, index) => {
    this.setState({ loadingVenueDetails: true });
    if (index === undefined) {
      index = this.props.firstID;
    }
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      UnitID_internal: index,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    // eslint-disable-next-line no-unused-vars
    let subscriptionID;
        this.setState({
      unitSubscriptionsList: undefined,
    });
    axios
      .post("/networkoperatorconsole/venues/listVenueUnitDetails", body, {
        headers: {},
      })
      .then((response) => {
        subscriptionID = Object.keys(response.data["VenueUnits"][0]["Subscriptions"])
        // console.log(response.data["VenueUnits"][0]["Subscriptions"])
        this.setState({
          unitSubscriptionsList: response.data["VenueUnits"][0],
          subscriptionID: Object.keys(response.data["VenueUnits"][0]["Subscriptions"]),
          venueID: response.data["VenueUnits"][0]["Venue"]["VenueID"],
        });
        this.setState({ loadingVenueDetails: false });
        this.setState({
          SubscriberGivenName: this.state.searchResults[key].GivenName,
          SubscriberFamilyName: this.state.searchResults[key].FamilyName,
          SubscriberEmail: this.state.searchResults[key].EmailAddress,
          SubscriberPhoneNumber: this.state.searchResults[key]["PhoneNumber.E164"],
          contactpref_email: this.state.searchResults[key].contactpref_email,
          contactpref_sms: this.state.searchResults[key].contactpref_sms,
          SubscriberLocale: this.state.searchResults[key].locale,
          UnitFloor: this.state.unitSubscriptionsList["UnitFloor"],
          UnitBuilding: this.state.unitSubscriptionsList["UnitBuilding"],
          SSID: this.state.searchResults[key].ssid,
          StartDateTime: this.state.unitSubscriptionsList["Subscriptions"][this.state.subscriptionID]["StartDateTimeUTC.ISO8601"],
          EndDateTime: this.state.unitSubscriptionsList["Subscriptions"][this.state.subscriptionID]["EndDateTimeUTC.ISO8601"],
          UnitID: this.state.unitSubscriptionsList["UnitID"],
          UnitID_internal: this.state.unitSubscriptionsList["UnitID_internal"],
          servicePlanID: this.state.unitSubscriptionsList["Subscriptions"][this.state.subscriptionID]["ServicePlan"]["ServicePlanID"],
          servicePlan: this.state.unitSubscriptionsList["Subscriptions"][this.state.subscriptionID]["ServicePlan"]["ServicePlanName"],
          vlan: this.state.unitSubscriptionsList["vlan"],
          status: this.state.searchResults[key].AccountStatusAtCreation,
          realpageSyncDate: this.state.unitSubscriptionsList["Subscriptions"][this.state.subscriptionID]["RealPageSyncDateTimeUTC.ISO8601"],
          subscriberManagedviaSSO: this.state.searchResults[key]["SubscriberManagedviaSSO"],
          paymentFailed: this.state.searchResults[key]["paymentFailed"],
          StripeClientSecret: this.state.searchResults[key]["StripeClientSecret"],
          country: this.state.searchResults[key]["Country.ISO3166A3"],
          subscriptionDiscount: this.state.searchResults[key]["VenueUnitPriceAdjustment"],
          venueDiscount: this.state.searchResults[key]["VenuePriceAdjustment"],
          externalSubscriberID: this.state.searchResults[key]["externalSubscriberID"],
          availablePlans: this.state.unitSubscriptionsList["AvailableServicePlans"],
          selectedServicePlanPrice: this.state.searchResults[key]["ListBillingCyclePrice.USD"],
          setupIntentID: this.state.searchResults[key]["SetupIntentId"]
        });
        sessionStorage.setItem("venueID", this.state.searchResults[key]["VenueID"])
        this.toggleModifyResident();
      })
      .catch((error) => {
        this.setState({ error: true });
        this.setState({ loadingVenueDetails: false });
      });
  };
  modifyResident = (key) => {
  };
  submitModify = (
    email,
    firstName,
    lastName,
    phoneNumber,
    language,
    contactpref_email,
    contactpref_sms,
    recentResidents,
    dateStart,
    oldDateStart,
    dateEnd,
    oldDateEnd,
    venueID,
    unitID,
    building,
    floor,
    servicePlanID,
    isUpdated,
    isUpdatedServicePlan,
    isUpdatedStartDate,
    isUpdatedEndDate,
    isUpdatedSubscriptionDiscount,
    unitID_internal,
    selectedServicePlanName,
    subscriberToken,
    updatedUnit,
    subscriptionDiscount,
    setupIntentID
  ) => {
    this.setState({ loading: true });
    let bodyDateStart = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart)
        .toISOString()
        .replace(/:00.\d+Z$/g, "Z"),
      "NewStartDateTimeUTC.ISO8601": new Date(dateStart)
        .toISOString()
        .replace(/:00.\d+Z$/g, "Z"),
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken
    };
    let bodyDateEnd = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      "NewEndDateTimeUTC.ISO8601":
        dateEnd !== undefined
          ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
          : undefined,
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken
    };
    let bodyServicePlan = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "CurrentStartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      NewServicePlanID: servicePlanID,
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken
    };
    let bodyUpdatePII = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      GivenName: firstName,
      FamilyName: lastName,
      "PhoneNumber.E164": phoneNumber,
      locale: language,
      contactpref_email: true,
      contactpref_sms: contactpref_sms,
      VenueID: venueID,
      SubscriberToken: subscriberToken
    };
    let bodyUpdateUnit = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber"),
      SetupIntentId: setupIntentID,
      NewUnitID_internal: unitID_internal,
      VenueID: venueID
    }
    let bodyUpdateSubscriberPrice = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber"),
      SetupIntentId: "",
      VenueID: venueID,
      NewVenueUnitPriceAdjustment: -Math.abs(subscriptionDiscount) * 100,
      "NewEndDateTimeUTC.ISO8601":
        dateEnd !== undefined
          ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
          : "",
    }
    Promise.all([
      new Date(dateStart) > new Date() && isUpdatedStartDate === true
        ? axios.post(
          "/networkoperatorconsole/subscribers/resetSubscriptionStartDate",
          bodyDateStart,
          { headers: {} }
        )
        :
        console.log("🚀 ~ file: ViewVenueSubscribers.js:206 ~ ViewVenueSubscribers ~ No Date Start Change, No Date Start Change"),
      dateEnd !== undefined && isUpdatedEndDate === true
        ? axios.post(
          "/networkoperatorconsole/subscribers/resetSubscriptionEndDate",
          bodyDateEnd,
          { headers: {} }
        )
        :
        console.log("🚀 ~ file: ViewVenueSubscribers.js:214 ~ ViewVenueSubscribers ~ bodyDateEnd", bodyDateEnd),
      isUpdated === true
        ? axios.post(
          "/networkoperatorconsole/subscribers/updateSubscriberPII",
          bodyUpdatePII,
          { headers: {} }
        )
        :
        console.log("🚀 ~ file: ViewVenueSubscribers.js:222 ~ ViewVenueSubscribers ~ No Change, No Change"),
      isUpdatedServicePlan === true
        ? axios.post(
          "/networkoperatorconsole/subscribers/modifyServicePlan",
          bodyServicePlan,
          { headers: {} }
        )
        :
        console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyServicePlan", bodyServicePlan),
      updatedUnit === true
        ? axios.post(
          "/networkoperatorconsole/subscribers/modifyVenueUnit",
          bodyUpdateUnit,
          { headers: {} }
        )
        :
        console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateUnit", bodyUpdateUnit),
      isUpdatedSubscriptionDiscount === true
        ? axios.post(
          "/venuemgmtconsole/subscribers/modifySubscriptionPrice",
          bodyUpdateSubscriberPrice,
          { headers: {} }
        )
        :
        console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateSubscriberPrice", bodyUpdateSubscriberPrice),
    ])
      .then(async ([res1, res2, res3, res4, res5, res6]) => {
        toast.success("Updated Resident!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.setState({ activeData: undefined });
        this.findSubscribers();
        if (isUpdatedServicePlan === true) {
          this.setState({
            loading: false,
            nextInvoiceAdjustedAmount:
              res4.data["NextInvoiceAdjustedAmount.USD"] === undefined ? "" : res4.data["NextInvoiceAdjustedAmount.USD"] / 100,
            nextInvoiceDate:
              res4.data["NextInvoiceDateTimeUTC.ISO8601"],
            billingCycleAdjustment:
              res4.data["BillingCyclePriceAdjustment"] === undefined ? "" : res4.data["BillingCyclePriceAdjustment"] / 100,
            subscriberName: firstName + " " + lastName,
            subscriberEmail: email,
            newServicePlanName: selectedServicePlanName,
            servicePlanID: servicePlanID,
            paymentFailed: res4.data["paymentFailed"]
          });
          sessionStorage.setItem("servicePlanIDSubscriber", servicePlanID);
          // this.toggleModifyService();
        }
        this.setState({
          loading: false
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.setState({ recentResidents: recentResidents });
        this.setState({ loading: false });
      });
  };
  deleteResident = (
    email,
    venueID,
    unitID,
    building,
    floor,
    recentResidents,
    oldDateStart,
    unitID_internal,
    cancellationReason,
    refundAmount
  ) => {
    this.setState({ loadingDelete: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": oldDateStart,
      "NewEndDateTimeUTC.ISO8601": oldDateStart,
      endImmediately: true,
      UserTimeZone: new Date().getTimezoneOffset(),
      "CancellationReasons": cancellationReason
    };
    axios
      .post("/venuemgmtconsole/subscribers/resetSubscriptionEndDate", body, {
        headers: {},
      })
      .then((response) => {
        toast.success("Deleted Resident!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        // this.getVenueDetails(this.props.venueID, this.props.venueName);
        this.setState({ loadingDelete: false });
        this.findSubscribers();
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.setState({ error: true });
        this.setState({ loadingDelete: false });
      });
  };
  render() {
    window.addEventListener('DOMcontentLoaded', () => {
      console.log('hello')
      window.querySelector('.newclass1').classList.add('custom-class');
    })
    return (
      <>
      {this.state.loadingVenueDetails === true ?
      <div
      className="overlay"
      id="overlay"
      style={{ display: "block" }}
    >
      <div className="overlayMessage">
        <div
          className="spinner-border spinner-border"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
      :
      <MDBModal
          isOpen={this.state.modalModifyResident}
          toggle={this.toggle}
          size="fluid"
          className="form"
        >
          <ModifyResident
            toggleModifyResident={this.toggleModifyResident}
            firstName={this.state.SubscriberGivenName}
            lastName={this.state.SubscriberFamilyName}
            email={this.state.SubscriberEmail}
            phoneNumber={this.state.SubscriberPhoneNumber}
            receivetext={this.state.contactpref_email}
            receivephone={this.state.contactpref_sms}
contactpref_sms={this.state.contactpref_sms}
            contactpref_email={this.state.contactpref_email}
            language={this.state.SubscriberLocale}
            floor={this.state.UnitFloor}
            building={this.state.UnitBuilding}
            startDate={this.state.StartDateTime}
            endDate={this.state.EndDateTime}
            unitID={this.state.UnitID}
            submitModify={this.submitModify}
            submitDelete={this.deleteResident}
            venueID={this.state.venueID}
            loading={this.state.loading}
            SSID={this.state.SSID}
            unitID_internal={this.state.UnitID_internal}
            servicePlanID={this.state.servicePlanID}
            servicePlan={this.state.servicePlan}
            availablePlans={this.state.availablePlans}
            // getVenueDetails={this.getVenueDetails}
            vlan={this.state.vlan}
            status={this.state.status}
            realpageSyncDate={this.state.realpageSyncDate}
            subscriberManagedviaSSO={this.state.subscriberManagedviaSSO}
            paymentFailed={this.state.paymentFailed}
            StripeClientSecret={this.state.StripeClientSecret}
            country={this.state.country}
            subscriptionDiscount={this.state.subscriptionDiscount}
            venueDiscount={this.state.venueDiscount}
            externalSubscriberID={this.state.externalSubscriberID}
            selectedServicePlanPrice={this.state.selectedServicePlanPrice}
            setupIntentID={this.state.setupIntentID}
          />
        </MDBModal>
    }
        <MDBModalHeader toggle={this.props.toggleSearchSubscriber}>
          <h4>Search for Subscriber</h4>
        </MDBModalHeader>
        <MDBModalBody>
          {/* <form className="needs-validation" onSubmit={this.findSubscribers} noValidate> */}
          <MDBCol sm="12" lg="6" className="pl-0">
            <label
              htmlFor="defaultFormLoginEmailEx"
              className="black-text"
            >
              Email Address
            </label>
            <input
              required
              name="subscriberEmail"
              id="subscriberEmail"
              className="form-control"
              onChange={this.changeHandler}
              value={this.state.subscriberEmail}
            />
            <label
													htmlFor="defaultFormLoginEmailEx"
													className="black-text"
												>
													Note: Only exact matches will be found
												</label>
          </MDBCol>
          <br />
          {this.state.searchResults !== undefined ? (
            <>
              {this.state.loading === true ?
                <div
                className="overlay"
                id="overlay"
                style={{ display: "block" }}
              >
                <div className="overlayMessage">
                  <div
                    className="spinner-border spinner-border"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
                :
                <>
                <SearchSubscribersTable
                className="subscribersSearch allrecentresidentsExpanded"
                  searchResults={this.state.searchResults}
                  modifyResident={this.modifyResident}
                  getVenueDetails={this.getVenueDetails}
                />
                <label
													htmlFor="defaultFormLoginEmailEx"
													className="black-text"
												>
													Note: Only active subscriptions can be viewed
												</label>
                </>
              }
            </>
          ) :
            this.state.loading === true ?
            <div
            className="overlay"
            id="overlay"
            style={{ display: "block" }}
          >
            <div className="overlayMessage">
              <div
                className="spinner-border spinner-border"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
              :
              <SearchSubscribersTable
                className="subscribersSearch allrecentresidentsExpanded"
                searchResults={this.state.searchResults}
                modifyResident={this.modifyResident}
                getVenueDetails={this.getVenueDetails}
              />
          }
          <div className="text-right submitButton">
            <MDBBtn color="danger" type="submit" onClick={this.findSubscribers}>
              Search
            </MDBBtn>
          </div>
          {/* </form> */}
        </MDBModalBody>
      </>
    )
  }
}
export default Transactions;