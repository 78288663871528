import React, { Component } from "react";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import "./brands/common/css/common.scss";

import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";

import Login from "./components/Login/Login";

import Logout from "./components/Login/Logout";

import Dashboard from "./components/VenueMgmt/Dashboard/Dashboard";

import SubscriberDashboard from "./components/SubscriberMgmt/Dashboard/Dashboard";

import NetworkDashboard from "./components/NetworkMgmt/Dashboard/Dashboard";

import FindDeals from "./components/CreateAccount/FindDeals";

import SelectPlan from "./components/CreateAccount/SelectPlan";

import SelectPlanFacts from "./components/CreateAccount/SelectPlanFacts";

import CreateAccountP1 from "./components/CreateAccount/CreateAccountP1";

import CreateAccountP2 from "./components/CreateAccount/CreateAccountP2";

import CheckOut from "./components/CreateAccount/CheckOut";

import UpdateCheckOut from "./components/UpdateAccount/CheckOut";

import PaymentSuccess from "./components/CreateAccount/PaymentSuccess";

import Verification from "./components/CreateAccount/Verification";

import IdleTimer from "react-idle-timer";

import brandsData from "./brands/common/brands.json";

import ReactGA from 'react-ga4';

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
  }

  handleOnAction(event) {
    // console.log("🚀 ~ file: App.js:53 ~ App ~ handleOnAction ~ event", event)
  }

  handleOnActive(event) {
    // console.log("🚀 ~ file: App.js:57 ~ App ~ handleOnActive ~ event", event)
  }

  handleOnIdle(event) {
    // console.log("🚀 ~ file: App.js:61 ~ App ~ handleOnIdle ~ event", event)
    if (
      sessionStorage.getItem("fromAuthToken") &&
      sessionStorage.getItem("AccessToken") !== null
    ) {
      sessionStorage.removeItem("AccessToken");
      sessionStorage.removeItem("isAdmin");
      sessionStorage.removeItem("adminOf");
      sessionStorage.removeItem("venueName");
      sessionStorage.removeItem("venueID");
      sessionStorage.removeItem("firstName");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("rememberMe");
      sessionStorage.removeItem("phoneNumber");
      sessionStorage.removeItem("lastName");
      sessionStorage.removeItem("domain");
      sessionStorage.removeItem("i18next");
      sessionStorage.removeItem("RefreshToken");
      sessionStorage.removeItem("venueAdmin");
      sessionStorage.removeItem("networkAdmin");
      sessionStorage.removeItem("leasingAgent");
      sessionStorage.removeItem("loginPage");
      window.location.href = "/logout";
    } else {
      if (
        !sessionStorage.getItem("rememberMe") &&
        sessionStorage.getItem("loginPage") !== null
      ) {
        sessionStorage.removeItem("AccessToken");
        sessionStorage.removeItem("isAdmin");
        sessionStorage.removeItem("adminOf");
        sessionStorage.removeItem("venueName");
        sessionStorage.removeItem("venueID");
        sessionStorage.removeItem("firstName");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("rememberMe");
        sessionStorage.removeItem("phoneNumber");
        sessionStorage.removeItem("lastName");
        sessionStorage.removeItem("domain");
        sessionStorage.removeItem("i18next");
        sessionStorage.removeItem("RefreshToken");
        sessionStorage.removeItem("venueAdmin");
        sessionStorage.removeItem("networkAdmin");
        sessionStorage.removeItem("leasingAgent");
        window.location.href = sessionStorage.getItem("loginPage");
        sessionStorage.removeItem("loginPage");
      }
    }
  }

  myFunction() {
    var favicon = document.createElement("link");
    var title = document.createElement("title");
    var description = document.createElement("meta");
    let googleanalytics_id
    brandsData.forEach(
      ({
        url,
        favicon_id,
        favicon_rel,
        favicon_href,
        title_textContent,
        description_name,
        description_content,
        flag_showGoogleAnalytics,
        ga_id,
        brand_directory_name
      }) => {
        
        if (url.includes(window.location.hostname)) {
          favicon.id = favicon_id;
          favicon.rel = favicon_rel;
          favicon.type = "image/x-icon";
          favicon.href = brand_directory_name  + favicon_href;

          title.textContent = title_textContent;

          description.name = description_name;
          description.content = description_content;
          googleanalytics_id = ga_id;

          document.head.appendChild(favicon);
          document.head.appendChild(title);
          document.head.appendChild(description);
          if(flag_showGoogleAnalytics)
          {
            ReactGA.initialize(googleanalytics_id);
          }
        }
      }
    );
  }

  render() {
    const isLogged = !!sessionStorage.getItem("AccessToken");
    const fromAuthToken = sessionStorage.getItem("fromAuthToken");
    let loginURL;
    sessionStorage.getItem("loginPage") === "/signin" ||
    window.location.pathname === "/signin"
      ? (loginURL = "/signin")
      : (loginURL = "/");
    return (
      <>
        <BrowserRouter>

          {this.myFunction()}

          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={1000 * 60 * 10}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />
          <Switch>
          <Route exact path={loginURL} component={Login} />
          {!isLogged && window.location.pathname === "/dashboard" ? (
            <Redirect to={loginURL} />
          ) : (
            <Route exact path="/dashboard" component={Dashboard} />
          )}

          {!isLogged && window.location.pathname === "/myaccount" ? (
            <Redirect to={loginURL} />
          ) : (
            <Route exact path="/myaccount" component={SubscriberDashboard} />
          )}

          {!isLogged && window.location.pathname === "/networkdashboard" ? (
            <Redirect to={loginURL} />
          ) : (
            <Route
              exact
              path="/networkdashboard"
              component={NetworkDashboard}
            />
          )}

          <Route exact path="/terms" component={Login} />
          <Route exact path="/reset" component={Login} />
          <Route exact path="/reset_confirm" component={Login} />
          <Route exact path="/help" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/finddeals" component={FindDeals} />
          <Route exact path="/selectplan" component={SelectPlan} />
          
          <Route exact path="/selectplanfacts" component={SelectPlanFacts} />
          <Route exact path="/createaccount1" component={CreateAccountP1} />
          <Route exact path="/verification" component={Verification} />
          <Route exact path="/createaccount2" component={CreateAccountP2} />
          <Route exact path="/checkout" component={CheckOut} />
          <Route exact path="/paymentsuccess" component={PaymentSuccess} />
          <Route
            exact
            path="/myaccount/upgrade"
            component={SubscriberDashboard}
          />
          <Route
            exact
            path="/myaccount/upgrade/review"
            component={SubscriberDashboard}
          />
          <Route
            exact
            path="/myaccount/upgrade/checkout"
            component={SubscriberDashboard}
          />
          <Route
            exact
            path="/update/checkout"
            component={UpdateCheckOut}
          />
          <Route
            exact
            path="/myaccount/upgrade/paymentsuccess"
            component={SubscriberDashboard}
          />
          <Route
            exact
            path="/myaccount/cancel"
            component={SubscriberDashboard}
          />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;