import React, { Component } from "react";
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBRow,
	MDBCol,
	MDBIcon,
	toast,
	ToastContainer,
	MDBCard,
	MDBCardBody,
} from "mdbreact";
import axios from "../../../axios";
import "react-day-picker/lib/style.css";
import dateFnsFormat from "date-fns/format";
import Data from "../../../components/SubscriberMgmt/CumulativeBandwidth/Datachart/datachart";
import AddProfile from "../../../components/SubscriberMgmt/AddProfile/AddProfile";
import DeviceDetails from "../../../components/SubscriberMgmt/DeviceInformation/DeviceDetails";
import { withTranslation } from "react-i18next";
class KeyManage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading1: false,
			toggleEdit: "disabled",
      showPassword: true,
			modalEditNickname: false,
			modalEditPassword: false,
			venueName: this.props.venueName,
			selectedKeyName:
				this.props.keyInfo[0][this.props.venueName][this.props.keyID] !== undefined
					? this.props.keyInfo[0][this.props.venueName][this.props.keyID]["KeyTag"]
					: "",
			selectedKeyID: this.props.keyID,
			password: this.props.password !== null ? this.props.password : this.props.firstPSK,
			token: this.props.token,
			tag:
				this.props.keyInfo[0][this.props.venueName][this.props.keyID] !== undefined
					? this.props.keyInfo[0][this.props.venueName][this.props.keyID]["psk"].split(".")[1]
					: "",
			category: [],
			name: [],
			data: [],
		};
	}
	toggleAddProfile = () => {
		this.setState({
			modalAddProfile: !this.state.modalAddProfile,
		});
	};
	toggleEditNickname = () => {
		this.setState({
			selectedKeyName: this.props.selectedKeyName,
			modalEditNickname: !this.state.modalEditNickname,
		});
	};
	toggleEditPassword = () => {
		this.setState({
			password: this.props.password,
			modalEditPassword: !this.state.modalEditPassword,
		});
	};
	togglePassword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};
	toggleEdit = () => {
		this.setState({ toggleEdit: !this.state.toggleEdit });
	};
	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	setPassword = (password, token) => {
    let scrubbedPassword = password.replace(/\./g,'')
		// console.log("🚀 ~ file: KeyInformation.js:245 ~ KeyInformation ~ newpassword:", scrubbedPassword)
		this.setState({ password: scrubbedPassword });
		this.setState({ token: token });
	};
	toggleDeviceDetails = (device) => {
		this.setState({
			modalDeviceDetails: !this.state.modalDeviceDetails,
			deviceDetails: device,
		});
	};
	toggleDeviceDetailsClose = () => {
		this.setState({
			modalDeviceDetails: !this.state.modalDeviceDetails,
		});
	};
	submitEditNickname = () => {
		const { t } = this.props;
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			KeyID: this.props.keyID,
			KeyTag: this.state.selectedKeyName,
		};
		axios
			.post("/subscriberconsole/updateKeyTag", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					modalEditNickname: !this.state.modalEditNickname,
				});
				toast.success(t("profiledetails.nicknameupdatd"), {
					closeButton: false,
				});
				this.props.updateNameProfile(this.state.selectedKeyName, this.state.selectedKeyID, this.props.keyInfo, this.props.password, this.state.tag);
			})
			.catch((error) => {
				this.setState({ error: true });
				toast.error(t("profiledetails.nicknamenotupdatd"), {
					closeButton: false,
				});
			});
	};
	submitEditPassword = () => {
		const { t } = this.props;
		if (this.state.password !== null) {
			let body = {
				AccessToken: sessionStorage.getItem("AccessToken"),
				CurrentKeyID: this.props.keyID,
				UserPartForNewKey: this.state.password.split(".")[0],
			};
			axios
				.post("/subscriberconsole/resetKey", body, {
					headers: {},
				})
				.then((response) => {
					this.setState({
						modalEditPassword: !this.state.modalEditPassword,
					});
					toast.success(t("profiledetails.passwordupdated"), {
						closeButton: false,
					});
					this.props.updatePassword(this.props.selectedKeyName, this.props.keyID, this.state.password.split(".")[0], this.state.token);
				})
				.catch((error) => {
					this.setState({ error: true });
					toast.success(t("profiledetails.passwordnotupdated"), {
						closeButton: false,
					});
				});
		} else {
			toast.error("We encountered an error. Please reload the page and try again.", {
				closeButton: false,
			});
		}
	};
	formatDate(date, format, locale) {
		return dateFnsFormat(date, format, { locale });
	}
	copyPassword = () => {
		const { t } = this.props;
		var copyText = document.getElementById("passwordHidden");
		var textArea = document.createElement("textarea");
		textArea.value = copyText.textContent;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("Copy");
		this.setState({ copyText: "Copied" });
		textArea.remove();
		toast.success(t("profiledetails.passwordcopied"), {
			closeButton: false,
		});
	};
	renderDeviceIcon(param) {
		switch (param) {
			case "NetworkDevice":
				return "mobile-alt";
			case "Laptop":
				return "laptop";
			case "Unknown":
				return "question";
			default:
				return "mobile-alt";
		}
	}
	validateForm() {
		if(this.state.password!==null){
        	return this.state.password.split(".")[0].length >= 1;
        }
    }
	render() {
		const { t } = this.props;
		let password = this.props.password;
		return (
			<>
				<ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={5000} />
				<MDBModal isOpen={this.state.modalAddProfile} toggle={this.toggle} size="lg" className="form">
					<AddProfile
						toggleAddProfile={this.toggleAddProfile}
						keyInfo={this.props.keyInfo}
						ssidName={this.props.ssidName}
						password={this.props.password === null ? this.props.firstPSK : this.props.password}
						keyID={this.props.keyID}
						updateNameKey={this.props.updateNameKey}
						updateNameNetwork={this.props.updateNameNetwork}
						SessionList={this.props.SessionList}
					/>
				</MDBModal>
				<MDBModal isOpen={this.state.modalEditNickname} toggle={this.toggle} size="lg" className="form">
					<MDBModalHeader toggle={this.toggleEditNickname}>
						<h4>{t("profiledetails.editnicknametitle")}</h4>
					</MDBModalHeader>
					<MDBModalBody>
						<MDBRow>
							<MDBCol col="6">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									{t("profiledetails.nickname")}
								</label>
								<input
									id="nickname"
									className="form-control"
									onChange={this.changeHandler}
									name="selectedKeyName"
									value={this.state.selectedKeyName === null ? this.props.firstKeyName : this.state.selectedKeyName}
								/>
								<div className="invalid-feedback">Please provide a valid email.</div>
							</MDBCol>
						</MDBRow>
						<div className="text-right submitButton">
							<MDBBtn color="danger" onClick={this.submitEditNickname}>
								{t("profiledetails.submit")}
							</MDBBtn>
							<MDBBtn color="danger" onClick={this.toggleEditNickname}>
								{t("profiledetails.cancel")}
							</MDBBtn>
						</div>
					</MDBModalBody>
				</MDBModal>
				<MDBModal isOpen={this.state.modalEditPassword} toggle={this.toggle} size="lg" className="form">
					<MDBModalHeader toggle={this.toggleEditPassword}>
						<h4>{t("profiledetails.editpasswordtitle")}</h4>
					</MDBModalHeader>
					<MDBModalBody>
						<MDBRow>
							<MDBCol col="6">
								<p>{t("profiledetails.warning")}</p>
								<p>{t("profiledetails.warningdesc")}</p>
								<div className="form-group row">
									<div className="col-sm-2">
										<p>{t("profiledetails.profile")}</p>
									</div>
									<div className="col-sm-6">{this.props.selectedKeyName === null ? this.props.firstKeyName : this.props.selectedKeyName}</div>
								</div>
								<div className="form-group row">
									<div className="col-sm-2">
										<p>{t("profiledetails.network")}</p>
									</div>
									<div className="col-sm-6">{this.props.ssidName}</div>
								</div>
        <p className="wifiDisclaimer">Enter a new Personal Token below. Your Wi-Fi password is the combination of your personal token and your unique ID. For security purposes, the unique ID cannot be modified.</p>
                <label
                  htmlFor="defaultFormLoginEmailEx"
                  className="black-text passwordLabel"
                >
									{t("profiledetails.password")}
								</label>
								<input
									id="editpassword"
									className="form-control"
									onChange={(e) => this.setPassword(e.target.value, password === null ? this.props.firstPSK.split(".")[1] === undefined ? this.props.token : this.props.firstPSK.split(".")[1] : password.split(".")[1])}
									name="password"
									value={this.state.password === null ? this.props.firstPSK.split(".")[0] : this.state.password.split(".")[0]}
								/>
								<span className="password_subscriber">.{password === null ? this.props.firstPSK.split(".")[1] === undefined ? this.props.token : this.props.firstPSK.split(".")[1] : password.split(".")[1]}</span>
								<div className="invalid-feedback">Please provide a valid password.</div>
								<br />
								<br />
								<p>{t("profiledetails.newpassword")}</p>
								<input
									id="editpassword2"
									className="form-control"
									onChange={this.changeHandler}
									name="password"
									value={
										this.state.token !== undefined
											? this.state.password === null
												? this.props.firstPSK.split(".")[1] === undefined
													? this.props.firstPSK + "." + this.props.token
													: this.props.firstPSK
												: this.state.password.split(".")[0] + "." + this.state.token
											: this.state.password === null
											? this.props.firstPSK
											: this.state.password
									}
								/>
							</MDBCol>
						</MDBRow>
						<div className="text-right submitButton">
							<MDBBtn color="danger" disabled={!this.validateForm()} onClick={this.submitEditPassword}>
								{t("profiledetails.submit")}
							</MDBBtn>
							<MDBBtn color="danger" onClick={this.toggleEditPassword}>
								{t("profiledetails.cancel")}
							</MDBBtn>
						</div>
					</MDBModalBody>
				</MDBModal>
				<MDBModal isOpen={this.state.modalDeviceDetails} toggle={this.toggle} size="lg" className="form">
					<DeviceDetails toggleDeviceDetails={this.toggleDeviceDetailsClose} deviceDetails={this.state.deviceDetails} />
				</MDBModal>
				<MDBModalHeader toggle={this.props.toggleManageKey}>
					<h4>{t("profiledetails.title")}</h4>
				</MDBModalHeader>
				<MDBModalBody style={{ margin: "0px 20px 0px 10px" }}>
					<MDBRow>
						<MDBCol sm="6">
							<div className="form-group row">
								<div className="col-sm-4">
									<p>
										<strong>{t("profiledetails.profile")}</strong>
									</p>
								</div>
								<div className="col-sm-6">
									<MDBDropdown>
										<MDBDropdownToggle nav caret>
											<span className="mr-2" onClick={this.click}>
												{this.props.selectedKeyName === null ? this.props.firstKeyName : this.props.selectedKeyName}
											</span>
										</MDBDropdownToggle>
										<MDBDropdownMenu>
											{this.props.keyNameListArray.map((value, index) => {
												return (
													<MDBDropdownItem
														onClick={(e) => this.props.updateNameKey(this.props.keyNameListArray[index][1], this.props.keyNameListArray[index][0])}
													>
														{this.props.keyNameListArray[index][1]}
													</MDBDropdownItem>
												);
											})}
										</MDBDropdownMenu>
									</MDBDropdown>
								</div>
								<div className="col-sm-2 formControls">
									{this.props.isWPA2 === false ? <MDBIcon icon="edit" onClick={this.toggleEditNickname} /> : ""}
								</div>
							</div>
							<div className="form-group row">
								<div className="col-sm-4">
									<p>
										<strong>{t("profiledetails.network")}</strong>
									</p>
								</div>
								<MDBDropdown className="col-sm-6">
									<MDBDropdownToggle nav caret>
										<span className="mr-2">{this.props.ssidName}</span>
									</MDBDropdownToggle>
									<MDBDropdownMenu>
										{this.props.SessionList.map((value, index) => {
											return (
												<MDBDropdownItem onClick={(e) => this.props.updateNameNetwork(this.props.SessionList[index][0]["ssid"], index)}>
													{this.props.SessionList[index][0]["ssid"]}
												</MDBDropdownItem>
											);
										})}
									</MDBDropdownMenu>
								</MDBDropdown>
							</div>
							<div className="form-group">
								<div className="row">
									<div className="col-sm-4">
										<label htmlFor="defaultFormLoginEmailEx" className="black-text col-form-label">
											<strong>{t("profiledetails.password")}</strong>{" "}
                      {/* {this.state.showPassword !== true ? (
												<MDBIcon icon="eye" onClick={this.togglePassword} />
											) : (
                        <MDBIcon
                          icon="eye-slash"
                          onClick={this.togglePassword}
                        />
                      )} */}
										</label>
									</div>
									<div className="col-sm-6">
										<p className="inputBox" id="password" style={{ marginBottom: 0 }}>
											{this.state.showPassword !== false ? (password === null ? this.props.firstPSK : password) : "************"}
										</p>
										<span id="passwordHidden">{password === null ? this.props.firstPSK : password}</span>
									</div>
									<div className="col-sm-2 formControls">
										{this.props.isWPA2 === false ? <MDBIcon icon="edit" onClick={this.toggleEditPassword} /> : ""}
										<MDBIcon icon="copy" onClick={this.copyPassword} />
									</div>
								</div>
							</div>
						</MDBCol>
						<MDBCol sm="6">
							<div className="form-group">
								<div className="row">
									<div className="col-sm-4">
										<p>
											<strong>{t("profiledetails.activated")}</strong>
										</p>
									</div>
									<div className="col-sm-8">
										{this.props.addedOn === null
											? new Intl.DateTimeFormat("en-US", {
													year: "numeric",
													month: "numeric",
													day: "numeric",
											  }).format(new Date(this.props.dateAddedOn))
											: new Intl.DateTimeFormat("en-US", {
													year: "numeric",
													month: "numeric",
													day: "numeric",
											  }).format(new Date(this.props.addedOn))}
									</div>
								</div>
							</div>
							<div className="form-group">
								<div className="row">
									<div className="col-sm-4">
										<p>
											<strong>{t("profiledetails.recentuse")}</strong>
										</p>
									</div>
									<div className="col-sm-8">
										{this.props.recent === null
											? this.props.recentDateUsed !== undefined
												? new Intl.DateTimeFormat("en-US", {
														year: "numeric",
														month: "numeric",
														day: "numeric",
												  }).format(new Date(this.props.recentDateUsed))
												: ""
											: this.props.recent !== undefined
											? new Intl.DateTimeFormat("en-US", {
													year: "numeric",
													month: "numeric",
													day: "numeric",
											  }).format(new Date(this.props.recent))
											: ""}
									</div>
								</div>
							</div>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol sm="6">
							<p>
								<strong>{t("profiledetails.devices")}</strong>
							</p>
							<div>
								{Object.keys(this.props.devices).length > 0 ? (
									<div className="devices" style={{ border: "1px solid #CCC", padding: "10px", borderRadius: "5px", minHeight: "232px" }}>
										{Object.keys(this.props.devices)
											.slice(0, 3)
											.map((oneKey, i) => {
												return (
													<div className="deviceCard" onClick={(e) => this.toggleDeviceDetails(this.props.devices[oneKey])}>
														<MDBIcon icon={this.renderDeviceIcon(this.props.devices[oneKey]["DeviceType"])} />
														<div
															className={this.props.devices[oneKey]["currentlyOnline"] === true ? "deviceStatusConnected" : "deviceStatusDisconnected"}
														></div>
														<p>{this.props.devices[oneKey]["UserDefinedHostName"]}</p>
													</div>
												);
											})}
									</div>
								) : (
									<MDBCard
										style={{
											border: "1px solid #CCC",
											padding: "10px",
											borderRadius: "5px",
											minHeight: "232px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<MDBCardBody className="messageCardBody1" style={{ flex: "unset" }}>
											<h4 style={{ color: "#BBB" }}>{t("profiledetails.nodevices")}</h4>
										</MDBCardBody>
									</MDBCard>
								)}
							</div>
						</MDBCol>
						<MDBCol sm="6">
							<p>
								<strong>{t("profiledetails.bandwidth")}</strong>
							</p>
							<div style={{ border: "1px solid #CCC", padding: "10px", borderRadius: "5px" }}>
								<Data selectedKeyID={this.props.keyID} />
							</div>
						</MDBCol>
					</MDBRow>
				</MDBModalBody>
				<MDBModalFooter>
					<div className="text-right">
						<MDBBtn color="danger" onClick={this.props.toggleManageKey}>
							OK
						</MDBBtn>
					</div>
				</MDBModalFooter>
			</>
		);
	}
}
export default withTranslation()(KeyManage);