import React, { Component } from "react";
import {
  MDBBtn,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBCol,
  MDBModal,
  toast,
  MDBProgress,
} from "mdbreact";
import CSVReader from "react-csv-reader";
import axios from "../../../../axios";
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  quotes: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};
class BulkUploadSubscribers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastProcessed: undefined,
      venueID: undefined
    };
  }
  componentDidUpdate(prevProps) {
    if (this.state.venueID !== this.props.venueID) {
      this.getVenueDetails(this.props.venueID);
      this.setState({ venueID: this.props.venueID });
    }
  }
  getVenueDetails = (index) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: index,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    this.setState({
      unitData: undefined,
      expiringData: undefined,
      searchData: undefined,
      value: "",
      defaultSubscriptionDurationRes: 0,
      loading_units: true,
    });
    axios
      .post("/venuemgmtconsole/venues/listVenueDetails", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          keyPoolID: Object.keys(response.data[index]["Keypools"]),
          venueName: response.data[index]['VenueName']
        });
        this.setState({
          loading_units: false,
        });
      })
      .catch((error) => {
        this.getVenueDetails(index);
        this.setState({
          error: true,
          loading_units: false,
        });
      });
  };
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  formatzip(num) {
    var s = "00000" + num;
    return s.substr(s.length - 5);
  }
  convertCSV = async (data) => {
    this.setState({ modalError: false });
    let dataImported = [];
    if (this.state.keyPoolID !== undefined) {
      this.setState({ loading_upload: true });
      for (let i = 0; i < data.length; i++) {
        dataImported[i] = {
          AccessToken: sessionStorage.getItem("AccessToken"),
          EmailAddress: data[i]["email_"],
          KeyPoolID: this.state.keyPoolID[0],
          GivenName: data[i]["given_name_"],
          FamilyName: data[i]["family_name_"],
          "PhoneNumber.E164": data[i]["phone___format___1xxxyyyzzzz_"],
          locale: data[i]["locale__en_us_"],
          "EndDateTimeUTC.ISO8601": new Date(data[i]["end_date"]).toISOString(),
          "StartDateTimeUTC.ISO8601": new Date(data[i]["start_date_"]).toISOString(),
          VenueID: this.state.venueID,
          contactpref_email: data[i]["contact_by_email___true_false_"] === null ? false : data[i]["contact_by_email___true_false_"],
          contactpref_sms: data[i]["contact_by_text___true_false_"] === null ? false : data[i]["contact_by_text___true_false_"],
          PolicyforSubscriberKey: {
            UnitBuilding: data[i]["building_"],
            UnitFloor: data[i]["floor_"],
            UnitID: data[i]["unit_id_"],
          },
          UserTimeZone: new Date().getTimezoneOffset(),
          externalSubscriberID: data[i]["external_subscriber_id"],
          externalSubscriptionID: data[i]["external_subscription_id"]
        };
        let unitID = data[i]["unit_id_"];
        let building = data[i]["building_"];
        let floor = data[i]["floor_"];
        this.setState({ email: data[i]["email_"] });
        await axios
          .post("/venuemgmtconsole/subscribers/addSubscriber", dataImported[i], {
            headers: {},
          })
          .then(
            (response) => {
              toast.success("Unit " + unitID + ", Building " + building + ", Floor " + floor + " processed", {
                closeButton: false,
              });
              if (i + 1 === data.length) {
                this.props.updateName(this.state.venueName, this.state.venueID);
              }
            },
            (error) => {
              this.setState({
                unitUnprocessed: unitID,
                buildingUnprocessed: building,
                floorUnprocessed: floor,
                lastProcessed: i + 1,
              });
              this.cancelUploadedFile();
              i = data.length;
              this.setState({
                modalError: true,
                errorMessage: error.response.data.message
              });
            }
          );
        this.setState({ progress: ((i + 1) / data.length) * 100 });
      }
      this.setState({ loading_upload: false });
    }
  };
  cancelUploadedFile() {
    const randomString = Math.random().toString(36);
    this.setState({
      theInputKey: randomString,
    });
  }
  toggleError = () => {
    this.setState({
      modalError: !this.state.modalError,
    });
  };
  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";
        line += '"' + array[i][index] + '"';
      }
      str += line + "\r\n";
    }
    return str;
  }
  exportCSVFile(headersExport, items, fileTitle) {
    if (headersExport !== items[0]) {
      items.unshift(headersExport);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = this.convertToCSV(jsonObject);
    var exportedFilename = fileTitle;
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  render() {
    var headers = {
      unitID: "Unit ID*",
      unitBuilding: "Building*",
      unitFloor: "Floor*",
      SubscriberGivenName: "Given Name*",
      SubscriberFamilyName: "Family Name*",
      SubscriberEmail: "Email*",
      SubscriberLocale: "Locale (en-us)",
      SubscriberPhone: "Phone* (Format: +1xxxyyyzzzz)",
      SubscriberAddress1: "Address 1*",
      SubscriberAddress2: "Address 2",
      SubscriberCity: "City*",
      SubscriberState: "State*",
      SubscriberPostalCode: "Postal Code*",
      SubsriberContactEmail: "Contact by Email* (true/false)",
      SubsriberContactSMS: "Contact by Text* (true/false)",
      StartDateTimeUTC: "Start Date*",
      EndDateTimeUTC: "End Date",
      SubscriberToken: "Subscriber Token",
      externalSubscriberID: "External Subscriber ID",
      externalSubscriptionID: "External Subscription ID"
    };
    var itemsFormatted = [];
    var fileTitle = "Subscriber_Upload_Template.csv"; // or 'my-unique-title'
    return (
      <>
        {this.state.lastProcessed > 0 ? (
          <>
            <MDBModal isOpen={this.state.modalError} toggle={this.toggle} size="md" className="form" centered>
              <MDBModalHeader toggle={this.toggleError}>
                <h4>Error Uploading Subscribers</h4>
              </MDBModalHeader>
              <MDBModalBody>
                {this.state.lastProcessed === 1 ? (
                  <p>
                    Line {this.state.lastProcessed + 1} (Unit: {this.state.unitUnprocessed}, Building: {this.state.buildingUnprocessed}, Floor:{" "}
                    {this.state.floorUnprocessed}) could not be processed.
                    <br />Reason: {this.state.errorMessage}
                    <br />Re-upload the CSV after fixing line 2.
                  </p>
                ) : (
                  <p>
                    Line {this.state.lastProcessed + 1} (Unit: {this.state.unitUnprocessed}, Building: {this.state.buildingUnprocessed}, Floor:{" "}
                    {this.state.floorUnprocessed}) could not be processed.
                    <br />Reason: {this.state.errorMessage}
                    <br />Re-upload the CSV after fixing line {this.state.lastProcessed + 1}{" "}
                    and removing lines 2-{this.state.lastProcessed}.
                  </p>
                )}
              </MDBModalBody>
            </MDBModal>
          </>
        ) : (
          ""
        )}
        {this.state.loading_upload === true ? (
          <div className="overlay" id="overlay" style={{ display: "block" }}>
            <div className="overlayProgressBar">
              <MDBProgress material value={this.state.progress} height="20px" color="danger" striped />
            </div>
          </div>
        ) : (
          ""
        )}
        <MDBModalHeader toggle={this.props.toggleBulkUploadSubscribers}>
          <h4>Bulk Upload Subscribers</h4>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="venueSelector">
            <MDBRow>
              <MDBCol col="6" sm="12" lg="4">
                <h5>Venue Name:</h5>
              </MDBCol>
              <MDBCol col="6" sm="12" lg="8">
                {this.props.attributeForm}
              </MDBCol>
            </MDBRow>
            <br />
            <p>Please note, only CSV files are accepted for the upload. Fields marked with "*" in template are required.</p>
          </div>
          <div className="text-right submitButton" key={this.state.theInputKey || ""}>
            <MDBBtn color="danger" onClick={() => this.exportCSVFile(headers, itemsFormatted, fileTitle)}>
              Download Template
            </MDBBtn>
            <MDBBtn color="danger">
              Upload Subscribers
              <CSVReader
                cssClass="csv-reader-input"
                onFileLoaded={this.convertCSV}
                parserOptions={papaparseOptions}
                inputId="csvVenueUnitUploadData"
                inputStyle={{ color: "transparent" }}
              />
            </MDBBtn>
            <MDBBtn color="primary" onClick={this.props.toggleBulkUploadSubscribers}>
              Close
            </MDBBtn>
          </div>
        </MDBModalBody>
      </>
    );
  }
}
export default BulkUploadSubscribers;