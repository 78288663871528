import * as React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }
  function isOverflown(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }
  const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };
    const handleMouseLeave = () => {
      setShowFullCell(false);
    };
    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }
      function handleKeyDown(nativeEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          setShowFullCell(false);
        }
      }
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);
    return (
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: 'center',
          lineHeight: '24px',
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'flex',
        }}
      >
        <Box
          ref={cellDiv}
          sx={{
            height: '100%',
            width,
            display: 'block',
            position: 'absolute',
            top: 0,
          }}
        />
        <Box
          ref={cellValue}
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {value}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </Box>
    );
  });
  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.formattedValue || ''} width={displayTextWidth(params.formattedValue, ".9rem Proxima Nova Rg")} />
    );
  }
  function displayTextWidth(text, font) {
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 25;
  }
export default function Basic(props) {
  let userAttributes;
    let columns = [];
    let rows = [];
  if (props.searchResults !== undefined) {
    if (Object.keys(props.searchResults).length !== 0) {
      userAttributes = [];
      for (const key of Object.keys(props.searchResults)) {
        let activeState;
      if
        (props.searchResults[key]["SetupSuccessDateTimeUTC.ISO8601"] === ""){
          activeState = "Abandoned";
        }else if
        ((new Date(props.searchResults[key]["EndDateTimeUTC.ISO8601"]).getTime() < new Date().getTime())){
          activeState = "Ended";
        }
      else{
          activeState = "Active"
      }
        rows.push({
          id: key,
          unitID_internal: props.searchResults[key]["UnitID_internal"],
          subName: (
            props.searchResults[key]["GivenName"] + " " + props.searchResults[key]["FamilyName"]
          ),
          subPhone: props.searchResults[key]["PhoneNumber.E164"],
          subEmail: props.searchResults[key]["EmailAddress"],
          subInService: props.searchResults[key]["AccountStatusAtCreation"],
          venueName: props.searchResults[key]["VenueName"],
          subStartDate: props.searchResults[key]["StartDateTimeUTC.ISO8601"].toString(),
          subEndDate: props.searchResults[key]["EndDateTimeUTC.ISO8601"].toString(),
          active: activeState,
          // clickEvent: (new Date(props.searchResults[key]["EndDateTimeUTC.ISO8601"]) > new Date()) || props.searchResults[key]["EndDateTimeUTC.ISO8601"] === "" ?
          //   () => props.getVenueDetails(key, props.searchResults[key]["UnitID_internal"]) :
          //   ""
        });
      }
    }
  }
    columns = [
      {
        headerName: "Subscriber Name",
        field: "subName",
        flex: 0.1,
        renderCell: renderCellExpand
      },
      {
        headerName: "Phone",
        field: "subPhone",
        flex: 0.1,
        renderCell: renderCellExpand
      },
      {
        headerName: "Email",
        field: "subEmail",
        flex: 0.1,
        renderCell: renderCellExpand
      },
      {
        headerName: "Venue Name",
        field: "venueName",
        flex: 0.1,
        renderCell: renderCellExpand
      },
      {
        headerName: "Start Date",
        field: "subStartDate",
        flex: 0.1,
        type: "dateTime",
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: renderCellExpand
      }, 
      {
        headerName: "End Date",
        field: "subEndDate",
        flex: 0.1,
        type: "dateTime",
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: renderCellExpand
      },
      {
        headerName: "Subscription Status",
        field: "active",
        flex: 0.1,
        renderCell: renderCellExpand
      }
    ];
  // rows = userAttributes;
  return (
    <div style={{ height: 300, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
          // Hide columns status and traderName, the other columns will remain visible
          unitID_internal: false
        }}
        // onRowClick={(id) => console.log(id["row"]["unitID_internal"])}
        onRowClick={(id) => props.getVenueDetails(id["id"], id["row"]["unitID_internal"])}
        getRowHeight={({ id, densityFactor }) => {
          return 40 * densityFactor;
        }}
        // slots={{ toolbar: QuickSearchToolbar }}
        hideFooterPagination={true}
        hideFooter={true}
        // onRowSelectionModelChange={(ids) => {
        //   const selectedIDs = new Set(ids);
        //   const selectedRowData = rows.filter((row) =>
        //     selectedIDs.has(row.id.toString())
        //   );
        //   props.getSelectedUnits(selectedRowData);
        // }
        // }
        sx={{ '--DataGrid-overlayHeight': '300px' }}
        className='subscribersTable'
onSortModelChange={props.findSubscribers}
        initialState={{
          sorting: {
            sortModel: [{ field: 'active', sort: 'desc' }],
          },
        }}
        getRowClassName={(id) =>
          id["row"]["active"] === "Abandoned" || id["row"]["active"] === "Ended" ? 'inactiveSubscription' : 'activeSubscription'
        }
      />
    </div>
  );
}