import React, { Component } from "react";
import {
  MDBBtn,
  MDBSpinner,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  toast,
} from "mdbreact";
import UpcomingExpirationsTable from "../../../components/VenueMgmt/UpcomingExpirations/Datatables/UpcomingExpirationsTable";
import ModifyResident from "../../../components/VenueMgmt/Forms/ModifyResident/ModifyResident";
import axios from "../../../axios";
import { withTranslation } from "react-i18next";
class UpcomingExpirations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
    };
  }
  toggleConfirmExpiration = (key) => {
    this.setState({
      toggleConfirm: true,
      modal: !this.state.modal,
      confirmName: key.SubscriberGivenName + " " + key.SubscriberFamilyName,
      confirmEmail: key.SubscriberEmail,
      confirmEndDate: Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(key["EndDateTimeUTC.ISO8601"])),
      confirmKey: key,
    });
  };
  confirmExpiration = (key) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: key.SubscriberEmail,
      "StartDateTimeUTC.ISO8601": key["StartDateTimeUTC.ISO8601"],
      "EndDateTimeUTC.ISO8601": key["EndDateTimeUTC.ISO8601"],
      VenueID: this.props.venueID,
      UnitID_internal: key.UnitID_internal,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    axios
      .post("/venuemgmtconsole/subscribers/confirmSubscriptionEnd", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Confirmed to expire", {
            closeButton: false,
          });
          this.setState({
            modal: !this.state.modal,
          });
          this.props.updateName(this.props.venueName, this.props.venueID);
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
        }
      );
  };
  toggleModifyResident = () => {
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
    });
  };
  toggleModifyService = () => {
    this.setState({
      modalModifyServiceInfo: !this.state.modalModifyServiceInfo,
    });
  };
  modifyResident = (key) => {
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
    });
    this.setState({
      SubscriberGivenName: key.SubscriberGivenName,
      SubscriberFamilyName: key.SubscriberFamilyName,
      SubscriberEmail: key.SubscriberEmail,
      SubscriberPhoneNumber: key["SubscriberPhoneNumber.E164"].toString(),
      contactpref_email: key.contactpref_email,
      contactpref_sms: key.contactpref_sms,
      SubscriberLocale: key.SubscriberLocale,
      UnitFloor: key.UnitFloor,
      UnitBuilding: key.UnitBuilding,
      StartDateTime: key["StartDateTimeUTC.ISO8601"],
      EndDateTime: key["EndDateTimeUTC.ISO8601"],
      UnitID: key.UnitID,
      ssid: key.ssid,
      UnitID_internal: key.UnitID_internal,
      servicePlanID: key.ServicePlan["ServicePlanID"],
      servicePlan: key.ServicePlan["ServicePlanName"],
      vlan: key.vlan,
      status: key.Status,
      realpageSyncDate: key["RealPageSyncDateTimeUTC.ISO8601"],
      subscriberManagedviaSSO: key["SubscriberManagedviaSSO"],
      StripeClientSecret: key["StripeClientSecret"],
      paymentFailed: key["paymentFailed"],
      RealPageUnitID: key.RealPageUnitID,
      country: key["Country.ISO3166A3"],
      subscriptionDiscount: key["VenueUnitShoppingCartPriceAdjustment"],
      venueDiscount: key["VenueShoppingCartPriceAdjustment"],
      externalSubscriberID: key["externalSubscriberID"],
      selectedServicePlanPrice: key["ListBillingCyclePrice"],
      setupIntentID: key["SetupIntentId"]
    });
  };
  submitModify = (
    email,
    firstName,
    lastName,
    phoneNumber,
    language,
    contactpref_email,
    contactpref_sms,
    recentResidents,
    dateStart,
    oldDateStart,
    dateEnd,
    oldDateEnd,
    venueID,
    unitID,
    building,
    floor,
    servicePlanID,
    isUpdated,
    isUpdatedServicePlan,
    isUpdatedStartDate,
    isUpdatedEndDate,
		isUpdatedSubscriptionDiscount,
    unitID_internal,
    selectedServicePlanName,
		subscriberToken,
		updatedUnit,
		subscriptionDiscount,
    setupIntentID
  ) => {
    this.setState({ loading: true });
    let bodyDateStart = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart)
        .toISOString()
        .replace(/:00.\d+Z$/g, "Z"),
      "NewStartDateTimeUTC.ISO8601": new Date(dateStart)
        .toISOString()
        .replace(/:00.\d+Z$/g, "Z"),
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken
    };
    let bodyDateEnd = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      "NewEndDateTimeUTC.ISO8601":
        dateEnd !== undefined
          ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
          : undefined,
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken
    };
    let bodyServicePlan = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "CurrentStartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      NewServicePlanID: servicePlanID,
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken
    };
    let bodyUpdatePII = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      GivenName: firstName,
      FamilyName: lastName,
      "PhoneNumber.E164": phoneNumber,
      locale: language,
      contactpref_email: true,
      contactpref_sms: contactpref_sms,
      VenueID: venueID,
      SubscriberToken: subscriberToken
    };
    let bodyUpdateUnit = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber"),
      SetupIntentId:setupIntentID,
      NewUnitID_internal: unitID_internal,
      VenueID: venueID
    };
    let bodyUpdateSubscriberPrice = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber"),
			SetupIntentId:setupIntentID,
			NewVenueUnitPriceAdjustment: -Math.abs(subscriptionDiscount) * 100,
      "NewEndDateTimeUTC.ISO8601":
        dateEnd !== undefined
          ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
          : "",
		  }
    Promise.all([
      new Date(dateStart) > new Date() && isUpdatedStartDate === true
        ? axios.post(
            "/venuemgmtconsole/subscribers/resetSubscriptionStartDate",
            bodyDateStart,
            { headers: {} }
          )
        : 
        console.log("🚀 ~ file: ServicesEndingSoon.js:205 ~ UpcomingExpirations ~ bodyDateStart", bodyDateStart),
      dateEnd !== undefined && isUpdatedEndDate === true
        ? axios.post(
            "/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
            bodyDateEnd,
            { headers: {} }
          )
        : 
        console.log("🚀 ~ file: ServicesEndingSoon.js:213 ~ UpcomingExpirations ~ bodyDateEnd", bodyDateEnd),
      isUpdated === true
        ? axios.post(
            "/venuemgmtconsole/subscribers/updateSubscriberPII",
            bodyUpdatePII,
            { headers: {} }
          )
        : 
        console.log("🚀 ~ file: ServicesEndingSoon.js:221 ~ UpcomingExpirations ~ bodyUpdatePII", bodyUpdatePII),
      isUpdatedServicePlan === true
        ? axios.post(
            "/venuemgmtconsole/subscribers/modifyServicePlan",
            bodyServicePlan,
            { headers: {} }
          )
        : 
        console.log("🚀 ~ file: ServicesEndingSoon.js:229 ~ UpcomingExpirations ~ bodyServicePlan", bodyServicePlan),
        updatedUnit === true 
        ? axios.post(
          "/venuemgmtconsole/subscribers/modifyVenueUnit",
          bodyUpdateUnit,
          { headers: {} }
        )
        : 
        console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateUnit", bodyUpdateUnit),
        isUpdatedSubscriptionDiscount === true 
        ? axios.post(
          "/venuemgmtconsole/subscribers/modifySubscriptionPrice",
          bodyUpdateSubscriberPrice,
          { headers: {} }
        )
        : 
        console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateSubscriberPrice", bodyUpdateSubscriberPrice),
    ])
      .then(async ([res1, res2, res3, res4, res5, res6]) => {
        toast.success("Updated Resident!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.props.updateName(this.props.venueName, this.props.venueID);
        if (isUpdatedServicePlan === true) {
          this.setState({
            loading: false,
            nextInvoiceAdjustedAmount:
              res4.data["NextInvoiceAdjustedAmount.USD"] === undefined ? "" : res4.data["NextInvoiceAdjustedAmount.USD"] / 100,
            nextInvoiceDate:
              res4.data["NextInvoiceDateTimeUTC.ISO8601"],
            billingCycleAdjustment:
              res4.data["BillingCyclePriceAdjustment"] === undefined ? "" : res4.data["BillingCyclePriceAdjustment"] / 100,
            subscriberName: firstName + " " + lastName,
            subscriberEmail: email,
            newServicePlanName: selectedServicePlanName,
            servicePlanID: servicePlanID,
            paymentFailed: res4.data["paymentFailed"]
          });
          sessionStorage.setItem("servicePlanIDSubscriber", servicePlanID);
          sessionStorage.setItem("newServicePlanID", servicePlanID);
          this.toggleModifyService();
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.setState({ loading: false });
      });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  submitDelete = (
    email,
    venueID,
    unitID,
    building,
    floor,
    recentResidents,
    oldDateStart,
    unitID_internal,
    cancellationReason,
		refundAmount
  ) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      endImmediately: true,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      UserTimeZone: new Date().getTimezoneOffset(),
      "CancellationReasons": cancellationReason,
			"requestedRefundAmount":refundAmount
    };
    axios
      .post("/venuemgmtconsole/subscribers/resetSubscriptionEndDate", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Deleted Resident!", {
            closeButton: false,
          });
          this.toggleModifyResident();
          this.props.updateName(this.props.venueName, this.props.venueID);
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.toggleModifyResident();
        }
      );
  };
  render() {
    if (this.state.postMessage1) {
      var h = document.getElementById("overlayConfirm");
      h.style.display = "block";
      setTimeout(function () {
        h.style.display = "block";
      }, 5000);
      setTimeout(() => {
        h.style.display = "none";
        this.setState({ postMessage1: "" });
      }, 6000);
    }
    const { t } = this.props;
    return (
      <>
        {this.state.toggleConfirm ? (
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>
              Confirm Expiration
            </MDBModalHeader>
            <MDBModalBody>
              <p>Name: {this.state.confirmName}</p>
              <p>Email: {this.state.confirmEmail}</p>
              <p>Expiring: {this.state.confirmEndDate}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="danger"
                onClick={() => this.confirmExpiration(this.state.confirmKey)}
              >
                Confirm
              </MDBBtn>
              <MDBBtn color="danger" onClick={this.toggle}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        ) : null}
        <MDBModal
          isOpen={this.state.modalModifyServiceInfo}
          toggle={this.toggle}
          size="md"
          className="form"
          centered
        >
          <MDBModalHeader toggle={this.state.paymentFailed?"":this.toggleModifyService}>
            <h4>Modified Service Information</h4>
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBRow>
                <MDBCol sm="6" lg="4">
                  <p>Subscriber:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  {this.state.subscriberName}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol sm="6" lg="4">
                  <p>Email:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  {this.state.subscriberEmail}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol sm="6" lg="4">
                  <p>New Service Plan:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  {this.state.newServicePlanName}
                </MDBCol>
              </MDBRow>
              {this.state.nextInvoiceDate !== undefined ? 
                <>
              <MDBRow>
                <MDBCol sm="6" lg="4">
                  <p>Next Invoice Date:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  {this.state.nextInvoiceDate !== undefined
                    ? Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(this.state.nextInvoiceDate))
                    : ""}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol sm="6" lg="4">
                  <p>Next Invoice Amount:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  ${this.state.nextInvoiceAdjustedAmount}
                </MDBCol>
              </MDBRow>
              <MDBRow className="align-items-end">
                <MDBCol sm="6" lg="4">
                  <p>Billing Cycle Adjustment Amount:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  ${this.state.billingCycleAdjustment}
                </MDBCol>
              </MDBRow>
              </>
              : ""}
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            {
              this.state.paymentFailed ? 
              <MDBBtn
											color="danger"
											className="btn Ripple-parent btn-default centered upgradePlan"
											href={
												"/update/checkout?ui=" +
												window.location.pathname.slice(
													1
												)
											}
										>
											Modify Billing CC
										</MDBBtn>
                    :
                    <MDBBtn
                    color="danger"
                    onClick={this.toggleModifyService}
                    type="submit"
                  >
                    OK
                  </MDBBtn>
            }
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalModifyResident}
          toggle={this.toggle}
          size="fluid"
          className="form"
          centered
        >
          <ModifyResident
            toggleModifyResident={this.toggleModifyResident}
            firstName={this.state.SubscriberGivenName}
            lastName={this.state.SubscriberFamilyName}
            email={this.state.SubscriberEmail}
            phoneNumber={this.state.SubscriberPhoneNumber}
            receivetext={this.state.contactpref_email}
            receivephone={this.state.contactpref_sms}
            contactpref_sms={this.state.contactpref_sms}
            contactpref_email={this.state.contactpref_email}
            language={this.state.SubscriberLocale}
            floor={this.state.UnitFloor}
            building={this.state.UnitBuilding}
            startDate={this.state.StartDateTime}
            endDate={this.state.EndDateTime}
            unitID={this.state.UnitID}
            submitModify={this.submitModify}
            venueID={this.props.venueID}
            updateName={this.props.updateName}
            loading={this.state.loading}
            SSID={this.state.ssid}
            unitID_internal={this.state.UnitID_internal}
            servicePlanID={this.state.servicePlanID}
            servicePlan={this.state.ServicePlan}
            submitDelete={this.submitDelete}
            vlan={this.state.vlan}
            status={this.state.status}
            realpageSyncDate={this.state.realpageSyncDate}
            subscriberManagedviaSSO={this.state.subscriberManagedviaSSO}
            StripeClientSecret={this.state.StripeClientSecret}
            paymentFailed={this.state.paymentFailed}
            RealPageUnitID={this.state.RealPageUnitID}
            country={this.state.country}
            subscriptionDiscount={this.state.subscriptionDiscount}
            venueDiscount={this.state.venueDiscount}
            externalSubscriberID={this.state.externalSubscriberID}
            selectedServicePlanPrice={this.state.selectedServicePlanPrice}
            setupIntentID={this.state.setupIntentID}
          />
        </MDBModal>
        <div id="overlayConfirm">
          <div id="overlayMessage" class="overlayMessage">
            {this.state.postMessage1}
          </div>
        </div>
        <MDBModalHeader toggle={this.props.toggleServicesEndingSoon}>
          <h4>{t("residentnav.servicesending")}</h4>
        </MDBModalHeader>
        <MDBModalBody>
          <h2>{t("upcomingexpirations.title")}</h2>
          <div className="expandCollapse">
            <p onClick={() => this.setState({ active: !this.state.active })}>
              {this.state.active
                ? t("recentservice.showless")
                : t("recentservice.showmore")}
            </p>
          </div>
          {this.props.expirations !== undefined ? (
            <div
              className={
                this.state.active
                  ? "upcomingExpirationsExpanded"
                  : "upcomingExpirations"
              }
            >
              <UpcomingExpirationsTable
                upcomingexpirations={this.props.expirations}
                toggleConfirmExpiration={this.toggleConfirmExpiration}
                modifyResident={this.modifyResident}
              />
            </div>
          ) : (
            <MDBSpinner small />
          )}
        </MDBModalBody>
      </>
    );
  }
}
export default withTranslation()(UpcomingExpirations);