import React, { Component } from "react";

import {
    MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBInput, MDBStepper, MDBStep, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSpinner, toast, MDBProgress,
    MDBTooltip,
    MDBIcon
} from "mdbreact";

import axios from "../../../../axios";

import "react-phone-number-input/style.css";

import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from "react-phone-number-input";

import { withTranslation } from "react-i18next";

import $ from "jquery";

import {
    CountryDropdown,
    RegionDropdown,
} from "vault-react-country-region-selector";

import SelectSearch, { fuzzySearch } from "react-select-search";

import VenueServicePlansTable from "../../../../components/NetworkMgmt/Venues/Datatables/VenueServicePlansTable";

import AddServicePlanToVenue from "../../../../components/NetworkMgmt/Forms/AddServicePlanToVenue/AddServicePlanToVenue";

import VenueEquipmentTable from "../../../../components/NetworkMgmt/Venues/Datatables/VenueEquipmentTable";

import AddVenueEquipment from "../../../../components/NetworkMgmt/Forms/AddVenueEquipment/AddVenueEquipment";

import ModifyEquipment from "../../../../components/NetworkMgmt/Forms/ModifyEquipment/ModifyEquipment";

import VenueKeypoolsTable from "../../../../components/NetworkMgmt/Venues/Datatables/VenueKeypoolsTable";

import AddKeypool from "../../../../components/NetworkMgmt/Forms/AddKeypool/AddKeypool";

import VenueAdministratorsTable from "../../../../components/NetworkMgmt/Venues/Datatables/VenueAdministratorsTable";

import AddVenueAdministrator from "../../../../components/NetworkMgmt/Forms/AddVenueAdministrator/AddVenueAdministrator";

import CSVReader from "react-csv-reader";


const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    quotes: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

class AddVenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null,
            venueName: undefined,
            address1: undefined,
            address2: undefined,
            city: undefined,
            state: undefined,
            postalcode: undefined,
            country: undefined,
            phoneNumber: undefined,
            phone: undefined,
            email: undefined,
            region: undefined,
            value: "en",
            countdown: undefined,
            timezone: undefined,
            loading_prop_groups: false,
            invalidPhone: null,
            invalidCountry: null,
            invalidState: null,
            durationResident: 0,
            durationGuest: 0,
            minVlanRes: 1,
            maxVlanRes: 4000,
            minVlanGuest: 1,
            maxVlanGuest: 4000,
            minVlanRoaming: 1,
            maxVlanRoaming: 4000,
            venueTypeOptions: [

                { value: "WIRED+WIFI", name: "Wi-Fi and Wired Access" },


                {
                    value: "WIRED",
                    name: "Wired Access Only",
                },

            ],
            formActivePanel1: this.props.tabNumber === undefined ? 1 : this.props.tabNumber,
            formActivePanel1Changed: this.props.tabChanged === undefined ? false : this.props.tabChanged,
            modalAddServicePlan: false,
            servicePlans: this.props.servicePlans,
            selectDefaultServicePlanResident: this.props.selectDefaultServicePlanResident,
            selectDefaultServicePlanWireless: this.props.selectDefaultServicePlanWireless,
            venueEquipList: this.props.venueEquipList,
            staffArrayAll: this.props.staffArrayAll,
            lastProcessed: undefined,
            trialCyclesOptions: [
                { value: -1, name: -1 },
                { value: 0, name: 0 },
                { value: 1, name: 1 }
            ],
            venueID: this.props.venueID,
            toggleConfirmContinue: this.props.toggleConfirmContinue,
            allowOverlapping: this.props.allowOverlapping,
            sendSubscriptionAddedEmail: true,
            sendSubscriptionEndEmail: true,
            addedVenue: false,
            enableShoppingCart: JSON.parse(sessionStorage.getItem("cartEnabledGlobally")),
            enableRealPageSync: false
        };
    }

    componentDidMount() {
        this.getPropertyManagementGroups();
        this.getKeyPools();
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("🚀 ~ file: AddVenueWizard.js:111 ~ AddVenue ~ componentDidUpdate ~ this.state:", this.state)
        // console.log("🚀 ~ file: AddVenueWizard.js:111 ~ AddVenue ~ componentDidUpdate ~ this.props:", this.props)
        if (this.props.tabNumber !== prevState.tabNumber) {
            this.setState({
                tabNumber: this.props.tabNumber,
                tabChanged: this.props.tabChanged,
                formActivePanel1: this.props.tabNumber,
                formActivePanel1Changed: this.props.tabChanged
            })
        }
        if (this.props.venueEquipList !== prevState.venueEquipList) {
            this.setState({
                venueEquipList: this.props.venueEquipList
            })
        }
        if (this.props.keyPoolList !== prevState.keyPoolList) {
            this.setState({
                keyPoolList: this.props.keyPoolList
            })
        }
        if (this.props.staffArrayAll !== prevState.staffArrayAll) {
            this.setState({
                staffArrayAll: this.props.staffArrayAll
            })
        }
        var servicePlanArray = [];
        if (this.props.servicePlans !== prevProps.servicePlans && this.props.servicePlans !== undefined) {
            this.setState({
                servicePlans: this.props.servicePlans
            })
            let optionTemplateServicePlans = [];

            for (var i = 0; i < this.props.servicePlans.length; i++) {
                optionTemplateServicePlans.push(
                    {
                        value: this.props.servicePlans[i]["ServicePlanID"],
                        name: this.props.servicePlans[i]["ServicePlanName"]
                    }
                );
            }
            servicePlanArray.push(
                <MDBSelect id="selectServicePlan" className="form-control" getValue={this.handleSelectServicePlan}>
                    <MDBSelectInput className="selectInput selectServicePlan" selected="Choose the Service Plan to Add" />
                    <MDBSelectOptions search>{optionTemplateServicePlans}</MDBSelectOptions>
                </MDBSelect>
            );
            this.setState({
                servicePlanForm: servicePlanArray,
                optionTemplateServicePlans: optionTemplateServicePlans,
                loading_prop_serviceplans: false,
                selectDefaultServicePlanResident: this.props.selectDefaultServicePlanResident
            });
        }

    }

    validateEmail = (email) => {
        var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        this.setState({
            invalidPhone: undefined,
            userFound: false,
            userFoundWithPhone: false,
        });

        if (!this.state.email.match(mailformat)) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
            $(".emailInput").addClass("is-invalid");
        } else {
            this.setState({ invalidEmail: undefined });
            $(".emailInput").removeClass("is-invalid");
        }
    };

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ region: val });
    }

    getValueOfSelect = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        this.setState({
            propertyMUTCGroupSecret: optionElement.getAttribute("value"),
        });
        this.setState({ propertyMUTCGroup: optionElement.getAttribute("id") });
    };

    getValueOfSelectCountry = (value) => {
        this.setState({ country: value[0] });
    };

    getValueOfSelectState = (value) => {
        this.setState({ region: value[0] });
    };

    handleSelectGroup = (value) => {
        this.setState({ selectGroup: value });
    };

    handleSelectKeyPool = (value) => {
        this.setState({ selectKeypool: value });
    };

    handleSelectVenueType = (value) => {
        this.setState({ selectedVenueType: value });
    };

    handleSelectTimeZone = (value) => {
        this.setState({ selectTimeZone: value[0] });
    };

    getPropertyManagementGroups = () => {
        this.setState({ loading_prop_groups: true });

        const data = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        axios
            .post(
                "/networkoperatorconsole/venues/listPropertyManagementGroups",
                data,
                {
                    headers: {},
                }
            )
            .then((response) => {
                let optionTemplateGroup = [];
                Object.keys(response.data.PropertyManagementGroups).map(
                    (oneKey, i) => {
                        optionTemplateGroup.push({
                            value: oneKey,
                            name: response.data.PropertyManagementGroups[
                                oneKey
                            ]["PropertyManagementGroupName"],
                        });
                        return true;
                    }
                );

                this.setState({
                    optionTemplateGroup: optionTemplateGroup,
                    loading_prop_groups: false,
                });
            })
            .catch((error) => {
                this.setState({ loading_prop_groups: false });
            });
    };

    getKeyPools = () => {
        const data = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        axios
            .post("/networkoperatorconsole/keypools/listKeypools", data, {
                headers: {},
            })
            .then((response) => {
                let optionTemplateKeyPools = [];

                Object.keys(response.data.myKeyPoolList).map((oneKey, i) => {
                    optionTemplateKeyPools.push({
                        value: oneKey,
                        name: response.data.myKeyPoolList[oneKey]["ssid"],
                    });
                    return true;
                });

                this.setState({
                    optionTemplateKeyPools: optionTemplateKeyPools,
                    loading_prop_groups: false,
                });
            })
            .catch((error) => {
                console.log("🚀 ~ file: AddVenue.js:199 ~ AddVenue ~ error", error)
            });
    };

    toggleCheckboxValuesendSubscriptionAddedEmail = () => {
        this.setState({
            sendSubscriptionAddedEmail: !this.state.sendSubscriptionAddedEmail
        });
    };

    toggleCheckboxValuesendSubscriptionEndedEmail = () => {
        this.setState({
            sendSubscriptionEndEmail: !this.state.sendSubscriptionEndEmail
        });
    };

    toggleCheckboxValueAllowOverlapping = () => {
        this.setState({ allowOverlapping: !this.state.allowOverlapping });
    };

    toggleCheckboxValueDisplayFCCLabel = () => {
     this.setState({
       displayFCCLabel: !this.state.displayFCCLabel,
     });
    };

    submitHandler = (a, event, param) => {
        event.preventDefault();
        $("#selectTimeZone").removeClass("is-invalid");
        $("#selectCountry").removeClass("is-invalid");
        $("#selectState").removeClass("is-invalid");
        $("#selectGroup").removeClass("is-invalid");
        // $("#selectKeypool").removeClass("is-invalid");
        $("#selectVenueType").removeClass("is-invalid");
        event.target.className += " was-validated";
        if (this.state.country === undefined) {
            this.setState({ invalidCountry: "Please provide a valid country" });
            $("#selectCountry").addClass("is-invalid");
        } else {
            this.setState({ invalidCountry: null });
            $("#selectCountry").addClass("is-valid");
        }
        if (this.state.selectTimeZone === undefined) {
            this.setState({
                invalidTimeZone: "Please provide a valid time zone",
            });
            $("#selectTimeZone").addClass("is-invalid");
        } else {
            this.setState({ invalidTimeZone: null });
            $("#selectTimeZone").addClass("is-valid");
        }
        if (this.state.region === undefined) {
            this.setState({
                invalidState: "Please provide a valid state/region",
            });
            $("#selectState").addClass("is-invalid");
        } else {
            this.setState({ invalidState: null });
            $("#selectState").addClass("is-valid");
        }
        // if (this.state.selectGroup === undefined) {
        //     this.setState({
        //         invalidGroup: "Please provide a valid group name",
        //     });
        //     $("#selectGroup").addClass("is-invalid");
        // } else {
        //     $("#selectGroup").addClass("is-valid");
        // }
        // if (this.state.selectKeypool === undefined) {
        //     this.setState({ invalidKeypool: "Please provide a valid KeyPool" });
        //     $("#selectKeypool").addClass("is-invalid");
        // } else {
        //     $("#selectKeypool").addClass("is-valid");
        // }
        // if (this.state.selectedVenueType === undefined) {
        //     this.setState({
        //         invalidVenueType: "Please provide a valid Venue Type",
        //     });
        //     $("#selectVenueType").addClass("is-invalid");
        // } else {
        //     $("#selectVenueType").addClass("is-valid");
        // }
        if (this.state.email === undefined) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
        }
        if (
            this.state.invalidPhone === null ||
            this.state.invalidPhone !== undefined
        ) {
            this.setState({
                invalidPhone: "Please provide a valid phone number",
            });
            $(".PhoneInput").addClass("is-invalid");
        } else {
            $(".PhoneInput").addClass("is-valid");
        }
        if (this.state.email === undefined) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
        }

        if (
            this.state.venueName !== undefined &&
            this.state.invalidEmail === undefined &&
            this.state.invalidPhone === undefined &&
            this.state.address1 !== undefined &&
            this.state.country !== undefined &&
            this.state.city !== undefined &&
            this.state.region !== undefined &&
            this.state.postalcode !== undefined 
            // &&
            // this.state.selectGroup !== undefined &&
            // // this.state.selectKeypool !== undefined &&
            // this.state.selectedVenueType !== undefined &&
            // this.state.firstName !== undefined &&
            // this.state.lastName !== undefined
        ) {
            this.props.submitHandler(
                this.state.venueName,
                this.state.email,
                this.state.address1,
                this.state.address2,
                this.state.phoneNumber,
                this.state.country,
                this.state.city,
                this.state.region,
                this.state.postalcode,
                this.state.selectGroup,
                this.state.countdown,
                this.state.durationResident,
                this.state.durationGuest,
                this.state.firstName,
                this.state.lastName,
                this.state.minVlanRes,
                this.state.maxVlanRes,
                this.state.minVlanGuest,
                this.state.maxVlanGuest,
                this.state.minVlanRoaming,
                this.state.maxVlanRoaming,
                this.state.selectedVenueType,
                this.state.enableShoppingCart,
                this.state.sendSubscriptionAddedEmail,
                this.state.sendSubscriptionEndEmail,
                this.state.allowOverlapping,
                this.state.displayFCCLabel,
                this.state.typicalLatency
            );
        }

    };

    modifyVenueInfo = (a, event, param) => {
        event.preventDefault();
        event.target.className += " was-validated";
        $("#selectGroup").removeClass("is-invalid");
        if (this.state.selectGroup === undefined) {
            this.setState({
                invalidLanguage: "Please provide a valid group name",
            });
            $("#selectGroup").addClass("is-invalid");
        } else {
            $("#selectGroup").addClass("is-valid");
        }
        $("#selectVenueType").removeClass("is-invalid");
        if (this.state.selectedVenueType === undefined) {
            this.setState({
                invalidLanguage: "Please provide a valid Venue Type",
            });
            $("#selectVenueType").addClass("is-invalid");
        } else {
            $("#selectVenueType").addClass("is-valid");
        }
        if (this.state.email === undefined) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
        }
        if (
            this.state.invalidPhone === null ||
            this.state.invalidPhone !== undefined
        ) {
            this.setState({
                invalidPhone: "Please provide a valid phone number",
            });
            $(".PhoneInput").addClass("is-invalid");
        } else {
            $(".PhoneInput").addClass("is-valid");
        }
        if (this.state.venueName !== undefined) {
            this.props.modifyVenueInfo(
                this.state.address1,
                this.state.address2,
                this.state.phoneNumber,
                this.state.country,
                this.state.city,
                this.state.region,
                this.state.postalcode,
                this.state.email,
                this.state.countdown,
                this.state.durationResident,
                this.state.durationGuest,
                this.state.firstName,
                this.state.lastName,
                this.state.minVlanRes,
                this.state.maxVlanRes,
                this.state.minVlanGuest,
                this.state.maxVlanGuest,
                this.state.minVlanRoaming,
                this.state.maxVlanRoaming,
                this.state.realpageSiteID,
                this.state.realpagePMCID,
                this.state.realpageSyncDate,
                this.state.selectedVenueType,
                this.state.shoppingCartPriceAdjustment,
                this.state.enableRealPageSync,
                this.state.enableShoppingCart,
                this.state.sendSubscriptionAddedEmail,
                this.state.sendSubscriptionEndEmail,
                this.state.allowOverlapping,
                this.state.selectedTrialCycles,
                this.state.displayFCCLabel,
                this.state.typicalLatency
            );
            this.setState({
                formActivePanel1: this.props.tabNumber,
                formActivePanel1Changed: this.props.tabChanged
            });
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changeHandlerPhone = (event) => {
        this.setState({ phoneNumber: event });
        var value = event
            ? isPossiblePhoneNumber(event)
                ? undefined
                : "Please provide a valid phone number"
            : "Phone number required";
        this.setState({ invalidPhone: value });
    };

    createMask = () => {
        this.setState({
            phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
        });
    };

    toggleCheckboxValueEnable = () => {
        this.setState({
            enableShoppingCart: !this.state.enableShoppingCart
        });
    };

    swapFormActive = (a) => (param) => (e) => {
        this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
        });
    }

    handleNextPrevClick = (a) => (param) => (event) => {
        if (a === 1 && param === 2) {
            if (this.props.venueID !== undefined) {
                this.modifyVenueInfo(a, event, param)
            } else {
                this.submitHandler(a, event, param)
            }
        }
        else if (a === 1 && param === 6) {
            this.handleShoppingCart(a, event, param)
        }
        else if (a === 1 && param === 8) {
            this.handleRealPage(a, event, param)
        }
        else {
            this.setState({
                formActivePanel1: param,
                formActivePanel1Changed: true
            });
        }

    }



    /* Bulk Upload Units */
    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    formatzip(num) {
        var s = "00000" + num;
        return s.substr(s.length - 5);
    }

    convertCSV = async (data) => {
        this.setState({ loading_upload: true });
        this.setState({ modalError: false });
        this.setState({
            unitUnprocessed: undefined,
            buildingUnprocessed: undefined,
            floorUnprocessed: undefined,
            lastProcessed: undefined,
        });
        let dataImported = [];

        for (let i = 0; i < data.length; i++) {
            console.log(data)
            dataImported[i] = {
                VenueID: this.props.venueID,
                UnitID: data[i]["unit_id_"].toString(),
                UnitFloor: data[i]["floor_"].toString(),
                UnitBuilding: data[i]["building_"].toString(),
                AddressLine1: data[i]["address_1_"].toString(),
                AddressLine2: data[i]["address_2"] === null ? "" : data[i]["address_2"].toString(),
                City: data[i]["city_"].toString(),
                State: data[i]["state_"].toString(),
                PostalCode: data[i]["country_"] === "USA" ? this.formatzip(data[i]["postal_code_"]) : data[i]["postal_code_"],
                "Country.ISO3166A3": data[i]["country_"],
                SharedSecret: this.props.sharedSecret,
                AccessToken: sessionStorage.getItem("AccessToken"),
                vlan: data[i]["vlan"],
                purpose: data[i]["purpose__wiredunit__localwirelessonly_or_globalroaming__"].toLowerCase(),
                ServicePlanID: data[i]["service_plan_id"],
                externalVenueUnitID: data[i]["unit_external_id"],
                ShoppingCartPriceAdjustment: data[i]["shopping_cart_price_adjustment"]
            };
            let unitID = data[i]["unit_id_"];
            let building = data[i]["building_"];
            let floor = data[i]["floor_"];
            await axios
                .post("/networkoperatorconsole/venues/addVenueUnits", dataImported[i], {
                    headers: {},
                })

                .then(
                    (response) => {
                        this.props.updateName(this.props.venueName, this.props.venueID);
                        toast.success("Unit " + unitID + ", Building " + building + ", Floor " + floor + " processed", {
                            closeButton: false,
                        });
                    },
                    (error) => {
                        this.setState({
                            unitUnprocessed: unitID,
                            buildingUnprocessed: building,
                            floorUnprocessed: floor,
                            lastProcessed: i + 1,
                        });
                        this.cancelUploadedFile();
                        i = data.length;
                    }
                );

            this.setState({ progress: ((i + 1) / data.length) * 100 });
            this.setState({ modalError: true });
        }
        this.setState({ loading_upload: false });
    };

    cancelUploadedFile() {
        const randomString = Math.random().toString(36);
        this.setState({
            theInputKey: randomString,
        });
    }

    convertToCSV(objArray) {
        var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        var str = "";

        for (var i = 0; i < array.length; i++) {
            var line = "";
            for (var index in array[i]) {
                if (line !== "") line += ",";

                line += '"' + array[i][index] + '"';
            }

            str += line + "\r\n";
        }

        return str;
    }

    exportCSVFile(headersExport, items, fileTitle) {

        if (headersExport !== items[0]) {
            items.unshift(headersExport);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = this.convertToCSV(jsonObject);
        var exportedFilename = "Unit_Upload_Template.csv";

        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }

    /*Service Plan Section*/
    toggleAddServicePlan = () => {
        this.setState({
            modalAddServicePlan: !this.state.modalAddServicePlan,
        });
    };

    handleSelectDefaultServicePlanResident = (value) => {
        this.setState({
            selectGroup: value,
            loading: true
        });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: this.props.venueID,
            DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess: value,
        };

        axios
            .post("/networkoperatorconsole/venues/configureVenue", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Default Service Plan for Residential Unit Set!", {
                        closeButton: false,
                    });
                    this.setState({ loading: false });
                    this.props.getVenueDetails(this.props.venueID);
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.setState({ loading: false })
                }
            );
    };

    handleSelectDefaultServicePlanWireless = (value) => {
        this.setState({
            selectGroup: value,
            loading: true
        });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: this.props.venueID,
            DefaultServicePlanID_LocalWirelessAccess: value,
        }
        console.log(body)
        axios
            .post("/networkoperatorconsole/venues/configureVenue", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Default Service Plan for Guest Subscription Set!", {
                        closeButton: false,
                    });
                    this.setState({ loading: false });
                    this.props.getVenueDetails(this.props.venueID);
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.setState({ loading: false })
                }
            );
    };

    submitAddServicePlan = (servicePlanID) => {
        this.setState({ add_serviceplan: true });

        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: this.props.venueID,
            SharedSecret: this.props.sharedSecret,
            ServicePlanID: servicePlanID,
        };
        axios
            .post("/networkoperatorconsole/venues/enableServicePlan", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Service Plan to Venue!", {
                        closeButton: false,
                    });
                    this.toggleAddServicePlan();
                    this.props.updateName(this.props.venueName, this.props.venueID);
                    this.props.getVenueDetails(this.props.venueID);
                    this.setState({ add_serviceplan: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleAddServicePlan();
                    this.setState({ add_serviceplan: false });
                }
            );
    };

    toggleConfirmDisablePlan = (key) => {
        this.setState({
            modalConfirmDisablePlan: !this.state.modalConfirmDisablePlan,
        });
    };

    disablePlanConfirm = (key) => {
        this.toggleConfirmDisablePlan();
        this.setState({
            toggleConfirmDisablePlan: true,
            modal: !this.state.modal,
            servicePlanID: this.props.servicePlans[key]["ServicePlanID"],
            servicePlanName: this.props.servicePlans[key]["ServicePlanName"],
        });
    };

    disablePlan = () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            SharedSecret: this.props.sharedSecret,
            VenueID: this.props.venueID,
            ServicePlanID: this.state.servicePlanID,
        };
        this.setState({ disable_serviceplan: true });
        axios
            .post("/networkoperatorconsole/venues/disableServicePlan", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Service Plan Disabled!", {
                        closeButton: false,
                    });
                    this.props.getVenueDetails(this.props.venueID);
                    this.toggleConfirmDisablePlan();
                    this.setState({ disable_serviceplan: false });
                },
                (error) => {
                    toast.error("There was an error", {
                        closeButton: false,
                    });
                    this.toggleConfirmDisablePlan();
                    this.setState({ disable_serviceplan: false });
                }
            );
    };

    /* Venue Equipment */
    toggleAddVenueEquipment = () => {
        this.setState({
            modalAddVenueEquipment: !this.state.modalAddVenueEquipment,
        });
    };

    submitAddVenueEquipment = (equipID, manufacturer, model, type, zone, protocol, ipaddy, port, userName, userPassword, status, pre643) => {
        this.setState({ add_venueequip: true });

        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: this.props.venueID,
            EquipmentID: equipID,
            Manufacturer: manufacturer,
            Model: model,
            EquipmentType: type,
            WirelessZone: zone,
            ConnectionProtocol: protocol,
            IPAddress: ipaddy,
            Port: port,
            Username: userName,
            Password: userPassword,
            Status: status,
            MikrotikPre643: pre643,
        };

        axios
            .post("/networkoperatorconsole/venues/addVenueEquipment", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Equipment to Venue!", {
                        closeButton: false,
                    });
                    this.toggleAddVenueEquipment();
                    this.props.updateName(this.props.venueName, this.props.venueID);
                    this.props.getVenueDetails(this.props.venueID);
                    this.setState({ add_venueequip: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleAddVenueEquipment();
                    this.setState({ add_venueequip: false });
                }
            );
    };

    toggleModifyEquipment = () => {
        this.setState({
            modalModifyEquipment: !this.state.modalModifyEquipment,
        });
    };

    modifyEquipment = (key) => {
        this.setState({
            modalModifyEquipment: !this.state.modalModifyEquipment,
            equipID: key,
        });
    };

    submitModifyEquipment = (equipName, manufacturer, model, type, zone, protocol, ipaddy, port, userName, userPassword, status, pre643) => {
        this.setState({ modify_equipment: true });

        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            SharedSecret: this.props.sharedSecret,
            VenueID: this.props.venueID,
            EquipmentID: this.state.equipID,
            Manufacturer: manufacturer,
            Model: model,
            EquipmentType: type,
            WirelessZone: zone,
            ConnectionProtocol: protocol,
            IPAddress: ipaddy,
            Port: port,
            Username: userName,
            Password: userPassword,
            Status: status,
            MikrotikPre643: pre643,
        };
        axios
            .post("/networkoperatorconsole/venues/updateVenueEquipment", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Updated Equipment!", {
                        closeButton: false,
                    });
                    this.toggleModifyEquipment();
                    this.props.updateName(this.props.venueName, this.props.venueID);
                    this.props.getVenueDetails(this.props.venueID);
                    this.setState({ modify_equipment: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleModifyEquipment();
                    this.setState({ modify_equipment: false });
                }
            );
    };

    /* Shopping Cart */
    toggleCheckboxValueEnableShoppingCart = () => {
        this.setState({
            enableShoppingCart: !this.state.enableShoppingCart
        });
    };

    handleSelectTrialCycles = (value) => {
        this.setState({ selectedTrialCycles: value });
    };

    handleShoppingCart = (a, event, param) => {
        console.log("Shopping Cart")
        event.preventDefault();
        this.setState({
            loading: true
        });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            enableShoppingCart: this.state.enableShoppingCart,
            "ShoppingCartPriceAdjustment": -Math.abs(this.state.shoppingCartPriceAdjustment) * 100,
            TrialCycleAdjustment: this.state.selectedTrialCycles,
            VenueID: this.props.venueID,
            StripePublishableKey: this.state.stripePublishableKey,
            Stripe_Secret_key: this.state.stripeSecretKey,
            Stripe_Webhook_signingsecret: this.state.stripeWebhookSigningSecret
        };

        axios
            .post("/networkoperatorconsole/venues/configureVenue", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Updated Shopping Cart for Venue!", {
                        closeButton: false,
                    });
                    this.setState({ loading: false });
                    this.props.getVenueDetails(this.props.venueID);
                    this.setState({
                        formActivePanel1: 6,
                        formActivePanel1Changed: this.props.tabChanged
                    });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.setState({ loading: false })
                }
            );
    };

    /* Keypools */
    toggleAddKeypool = () => {
        this.setState({
            modalAddKeypool: !this.state.modalAddKeypool,
        });
    };

    submitAddKeypool = (ssid) => {
        this.setState({ add_keypool: true });

        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            SSID: ssid,
            VenueID: this.props.venueID,
        };

        axios
            .post("/networkoperatorconsole/keypools/addKeypool", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Keypool to Venue!", {
                        closeButton: false,
                    });
                    this.toggleAddKeypool();
                    this.props.updateName(this.props.venueName, this.props.venueID);
                    this.props.getVenueDetails(this.props.venueID);
                    this.setState({ add_keypool: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleAddKeypool();
                    this.setState({ add_keypool: false });
                }
            );
    };

    /* RealPage */
    changeHandlerRadio = (nr) => () => {
        this.setState({
            enableRealPageSync: nr,
        });
    };

    handleRealPage = (a, event, param) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            RealPageSiteId: this.state.realpageSiteID,
            RealPagePMCId: this.state.realpagePMCID,
            "RealPageSyncDateTimeUTC.ISO8601": this.state.realpageSyncDate,
            enableRealPageSync: this.state.enableRealPageSync,
            VenueID: this.props.venueID
        };

        axios
            .post("/networkoperatorconsole/venues/configureVenue", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Updated RealPage Settings for Venue!", {
                        closeButton: false,
                    });
                    this.setState({ loading: false });
                    this.props.getVenueDetails(this.props.venueID);
                    this.setState({
                        formActivePanel1: 8,
                        formActivePanel1Changed: this.props.tabChanged
                    });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.setState({ loading: false })
                }
            );
    };

    /* Administration */
    toggleAddVenueAdministrators = () => {
        this.setState({
            modalAddVenueAdministrators: !this.state.modalAddVenueAdministrators,
        });
    };

    submitAddVenueAdministrators = (email, firstName, lastName, phoneNumber, language, contactpref_email, contactpref_sms, role, venueid) => {
        this.setState({ add_venueadmin: true });

        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            SharedSecret: this.props.sharedSecret,
            VenueID: this.props.venueID,
            EmailAddress: email,
            Role: role,
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            contactpref_sms: contactpref_email,
            contactpref_voice: contactpref_sms,
        };

        axios
            .post("/networkoperatorconsole/venues/enableStaffAccess", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Administrator to Venue!", {
                        closeButton: false,
                    });
                    this.toggleAddVenueAdministrators();
                    this.props.updateName(this.props.venueName, this.props.venueID);
                    this.props.getVenueDetails(this.props.venueID);
                    this.setState({ add_venueadmin: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleAddVenueAdministrators();
                    this.setState({ add_venueadmin: false });
                }
            );
    };

    toggleConfirmDisable = (key) => {
        this.setState({
            toggleConfirm: true,
            modal: !this.state.modal,

            confirmEmail: key[0],
        });
    };

    toggleDisable = (email) => {
        this.setState({ staffArrayAll: undefined });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: this.props.venueID,
            EmailAddress: email,
        };
        axios
            .post("/networkoperatorconsole/venues/disableStaffAccess", body, {
                headers: {},
            })
            .then(
                (response) => {

                    toast.success("Deleted Administrator", {
                        closeButton: false,
                    });
                    this.setState({
                        modal: !this.state.modal,
                    });
                    this.props.updateName(this.props.venueName, this.props.venueID);
                    this.props.getVenueDetails(this.props.venueID);
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                }
            );
    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    toggleError = () => {
        this.setState({
            modalError: !this.state.modalError,
        });
    };

    render() {
        const { country, region } = this.state;
        const { t } = this.props;
        const FORMAT = "MM/dd/yyyy";
        var headers = {
            unitID: "Unit ID*",
            unitBuilding: "Building*",
            unitFloor: "Floor*",
            purpose: "Purpose (wiredunit, localwirelessonly or globalroaming)*",
            AddressLine1: "Address 1*",
            SubscriberAddress2: "Address 2",
            SubscriberCity: "City*",
            SubscriberState: "State*",
            SubscriberPostalCode: "Postal Code*",
            SubscriberCountry: "Country*",
            VLAN: "VLAN",
            // ServicePlanID: "Service Plan ID",
            unitIDInternal: "Unit Internal ID",
            externalVenueUnitID: "Unit External ID",
            ShoppingCartPriceAdjustment: "Shopping Cart Price Adjustment"
        };
        var itemsFormatted = [];
        var fileTitle = sessionStorage.getItem("venueName"); // or 'my-unique-title'
        return (
            <>
                {this.props.loading === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModal
                    isOpen={this.state.modalConfirmDisablePlan}
                    toggle={this.toggleConfirmDisablePlan}
                >
                    <MDBModalHeader toggle={this.toggleConfirmDisablePlan}>
                        Confirm Service Plan Disable
                    </MDBModalHeader>
                    <MDBModalBody>
                        <p>Service Plan Name: {this.state.servicePlanName}</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.disablePlan}>
                            Confirm
                        </MDBBtn>
                        <MDBBtn
                            color="primary"
                            onClick={this.toggleConfirmDisablePlan}
                        >
                            Close
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleAddNewVenue}>
                    <h4>Add New Venue</h4>
                    <p>{t("addnewresident.required")}</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBStepper form>
                        <MDBStep form className={this.state.formActivePanel1 === 1 ? "stepActive" : ""} >
                            <a href="#formstep1" onClick={this.swapFormActive(1)(1)}>
                                <MDBBtn color={this.state.formActivePanel1 === 1 ? "indigo" : "white"} circle>
                                    <div className="fa">1</div>
                                </MDBBtn>
                                <p>General<br /> Information</p>
                            </a>
                        </MDBStep>
                        <MDBStep form className={this.state.formActivePanel1 === 2 ? "stepActive" : ""}>
                            {
                                sessionStorage.getItem("addedVenue") ?
                                    <a href="#formstep1" onClick={this.swapFormActive(1)(2)}>
                                        <MDBBtn color={this.state.formActivePanel1 === 2 ? "indigo" : "white"} circle disabled={true}>
                                            <div className="fa">2</div>
                                        </MDBBtn>
                                        <p>Upload<br /> Units</p>
                                    </a> :
                                    <>
                                        <MDBBtn color={this.state.formActivePanel1 === 2 ? "indigo" : "white"} circle disabled={true}>
                                            <div className="fa">2</div>
                                        </MDBBtn>
                                        <p>Upload<br /> Units</p>
                                    </>
                            }

                        </MDBStep>
                        <MDBStep form className={this.state.formActivePanel1 === 3 ? "stepActive disabled" : ""} >
                            {
                                sessionStorage.getItem("addedVenue") ?
                                    <a href="#formstep1" onClick={this.swapFormActive(1)(3)}>
                                        <MDBBtn color={this.state.formActivePanel1 === 3 ? "indigo" : "white"} circle>
                                            <div className="fa">3</div>
                                        </MDBBtn>
                                        <p>Service<br /> Plans</p>
                                    </a> :
                                    <>
                                        <MDBBtn color={this.state.formActivePanel1 === 3 ? "indigo" : "white"} circle disabled={true}>
                                            <div className="fa">3</div>
                                        </MDBBtn>
                                        <p>Service<br /> Plans</p>
                                    </>
                            }

                        </MDBStep>
                        <MDBStep form className={this.state.formActivePanel1 === 4 ? "stepActive disabled" : ""} >
                            {
                                sessionStorage.getItem("addedVenue") ?
                                    <a href="#formstep1" onClick={this.swapFormActive(1)(4)}>
                                        <MDBBtn color={this.state.formActivePanel1 === 4 ? "indigo" : "white"} circle>
                                            <div className="fa">4</div>
                                        </MDBBtn>
                                        <p>Equipment</p>
                                    </a> :
                                    <>
                                        <MDBBtn color={this.state.formActivePanel1 === 4 ? "indigo" : "white"} circle disabled={true}>
                                            <div className="fa">4</div>
                                        </MDBBtn>
                                        <p>Equipment</p>
                                    </>
                            }

                        </MDBStep>
                        <MDBStep form className={this.state.formActivePanel1 === 5 ? "stepActive disabled" : ""} >
                            {
                                sessionStorage.getItem("addedVenue") ?
                                    <a href="#formstep1" onClick={this.swapFormActive(1)(5)}>
                                        <MDBBtn color={this.state.formActivePanel1 === 5 ? "indigo" : "white"} circle>
                                            <div className="fa">5</div>
                                        </MDBBtn>
                                        <p>Shopping<br /> Cart</p>
                                    </a> :
                                    <>
                                        <>
                                            <MDBBtn color={this.state.formActivePanel1 === 5 ? "indigo" : "white"} circle disabled={true}>
                                                <div className="fa">5</div>
                                            </MDBBtn>
                                            <p>Shopping<br /> Cart</p>
                                        </>
                                    </>
                            }
                        </MDBStep>
                        <MDBStep form className={this.state.formActivePanel1 === 6 ? "stepActive disabled" : ""} >
                            {
                                sessionStorage.getItem("addedVenue") ?
                                    <a href="#formstep1" onClick={this.swapFormActive(1)(6)}>
                                        <MDBBtn color={this.state.formActivePanel1 === 6 ? "indigo" : "white"} circle>
                                            <div className="fa">6</div>
                                        </MDBBtn>
                                        <p>Keypools</p>
                                    </a> :
                                    <>
                                        <MDBBtn color={this.state.formActivePanel1 === 6 ? "indigo" : "white"} circle disabled={true}>
                                            <div className="fa">6</div>
                                        </MDBBtn>
                                        <p>Keypools</p>
                                    </>
                            }
                        </MDBStep>
                        <MDBStep form className={this.state.formActivePanel1 === 7 ? "stepActive disabled" : ""} >
                            {
                                sessionStorage.getItem("addedVenue") ?
                                    <a href="#formstep1" onClick={this.swapFormActive(1)(7)}>
                                        <MDBBtn color={this.state.formActivePanel1 === 7 ? "indigo" : "white"} circle>
                                            <div className="fa">7</div>
                                        </MDBBtn>
                                        <p>RealPage</p>
                                    </a> :
                                    <>
                                        <MDBBtn color={this.state.formActivePanel1 === 7 ? "indigo" : "white"} circle disabled={true}>
                                            <div className="fa">7</div>
                                        </MDBBtn>
                                        <p>RealPage</p>
                                    </>
                            }
                        </MDBStep>
                        <MDBStep form className={this.state.formActivePanel1 === 8 ? "stepActive disabled" : ""} >
                            {
                                sessionStorage.getItem("addedVenue") ?
                                    <a href="#formstep1" onClick={this.swapFormActive(1)(8)}>
                                        <MDBBtn color={this.state.formActivePanel1 === 8 ? "indigo" : "white"} circle>
                                            <div className="fa">8</div>
                                        </MDBBtn>
                                        <p>Administration</p>
                                    </a> :
                                    <>
                                        <MDBBtn color={this.state.formActivePanel1 === 8 ? "indigo" : "white"} circle disabled={true}>
                                            <div className="fa">8</div>
                                        </MDBBtn>
                                        <p>Administration</p>
                                    </>
                            }

                        </MDBStep>
                    </MDBStepper>
                    {this.props.toggleConfirmContinueOpen ? (
                        <MDBModal isOpen={this.props.modal} toggle={this.toggle}>
                            <MDBModalHeader toggle={this.toggle}>Venue Created</MDBModalHeader>
                            <MDBModalBody>
                                <p>The Venue has been created. You may continue through the Wizard, or click cancel and use the Venue Management options for the remaining configuration later</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="primary" type="submit" onClick={this.props.toggleConfirmContinue}>
                                    Continue
                                </MDBBtn>
                                <MDBBtn color="primary" onClick={this.props.toggleAddNewVenue}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    ) : null}
                    {
                        this.state.formActivePanel1 === 1 &&
                        <form
                            className="needs-validation"
                            onSubmit={this.handleNextPrevClick(1)(2)}
                            noValidate
                        >
                            <h5 className="font-weight-bold pl-0 my-4">
                                <strong>General Information</strong>
                            </h5>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Venue Name*
                                    </label>
                                    <input
                                        required
                                        name="venueName"
                                        id="venueName"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.venueName}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid name
                                    </div>
                                </MDBCol>
                                <MDBCol col="6" sm="12" lg="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Venue Type*
                                    </label>
                                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            - Wi-Fi and Wired Access – Select if users have Wi-Fi access as well as wired access<br />
                                            - Wired Only Access – Select if there is no Managed Wi-Fi access. E.g. Bring your own Device/CPE’
                                        </span>
                                    </MDBTooltip>
                                    <SelectSearch
                                        id="selectVenueType"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.venueTypeOptions}
                                        inputId="venueTypeArray"
                                        classNamePrefix="unit-select form-control"
                                        placeholder="Select..."
                                        onChange={this.handleSelectVenueType}
                                        value={this.state.selectedVenueType}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a Venue Type
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Property Management Group
                                    </label>
                                    <SelectSearch
                                        id="selectGroup"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.optionTemplateGroup}
                                        isOptionSelected={true}
                                        inputId="groupArray"
                                        classNamePrefix="group-select"
                                        placeholder="Select..."
                                        onChange={this.handleSelectGroup}
                                        value={this.state.selectGroup}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a Property Management Group
                                    </div>
                                </MDBCol>
                                {/* <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        KeyPool*
                                    </label>
                                    <SelectSearch
                                        id="selectKeypool"
                                        search={true}
                                        filterOptions={fuzzySearch}
                                        options={this.state.optionTemplateKeyPools}
                                        isOptionSelected={true}
                                        inputId="keypoolArray"
                                        classNamePrefix="group-select"
                                        placeholder="Select..."
                                        onChange={this.handleSelectKeyPool}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a KeyPool
                                    </div>
                                </MDBCol> */}

                            </MDBRow>
                            <MDBRow>
                                <MDBCol sm="12" lg="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Address Line 1*
                                    </label>
                                    <input
                                        required
                                        name="address1"
                                        id="address1"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.address1}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid address
                                    </div>
                                </MDBCol>
                                <MDBCol sm="12" lg="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Address Line 2
                                    </label>
                                    <input
                                        className="form-control"
                                        name="address2"
                                        id="address2"
                                        onChange={this.changeHandler}
                                        value={this.state.address2}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol sm="12" lg="12">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Country*
                                    </label>
                                    <div className="select-box">
                                        <CountryDropdown
                                            value={country}
                                            onChange={(val) => this.selectCountry(val)}
                                            priorityOptions={["CAN", "USA", "GBR"]}
                                            valueType="short"
                                            countryvaluetype="short"
                                            className="form-control"
                                            id="selectCountry"
                                        />
                                    </div>
                                    {this.state.invalidCountry !== null ? (
                                        <div className="invalid">
                                            {this.state.invalidCountry}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol sm="12" lg="4">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        City*
                                    </label>
                                    <input
                                        required
                                        name="city"
                                        id="city"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.city}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid city
                                    </div>
                                </MDBCol>
                                <MDBCol sm="12" lg="4">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        State*
                                    </label>
                                    <div className="select-box">
                                        <RegionDropdown
                                            country={country}
                                            value={region}
                                            onChange={(val) => this.selectRegion(val)}
                                            valueType="short"
                                            className="form-control"
                                            id="selectState"
                                        />
                                    </div>
                                    {this.state.invalidState !== null ? (
                                        <div className="invalid">
                                            {this.state.invalidState}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </MDBCol>
                                <MDBCol sm="12" lg="4">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Zip Code*
                                    </label>
                                    <input
                                        required
                                        name="postalcode"
                                        id="postalcode"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.postalcode}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid zipcode
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Primary Contact - First Name
                                    </label>
                                    <input
                                        // required
                                        name="firstName"
                                        id="firstName"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.firstName}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a first name
                                    </div>
                                </MDBCol>
                                <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Primary Contact - Last Name
                                    </label>
                                    <input
                                        // required
                                        name="lastName"
                                        id="lastName"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.lastName}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a last name
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Property Support Phone #*
                                    </label>
                                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            The phone number subscribers will see on support pages.
                                        </span>
                                    </MDBTooltip>
                                    <PhoneInput
                                        placeholder={t("addnewresident.enterphone")}
                                        value={this.state.phoneNumber}
                                        format={FORMAT}
                                        onChange={this.changeHandlerPhone}
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        className={
                                            this.state.loading === true
                                                ? "form-control loading"
                                                : "form-control"
                                        }
                                        defaultCountry="US"
                                        required
                                        error={
                                            this.state.phoneNumber
                                                ? isValidPhoneNumber(
                                                    this.state.phoneNumber
                                                )
                                                    ? undefined
                                                    : "Invalid phone number"
                                                : "Phone number required"
                                        }
                                        disabled={this.state.userFoundWithPhone}
                                    />
                                    {this.state.invalidPhone !== null ? (
                                        <div className="invalid">
                                            {this.state.invalidPhone}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </MDBCol>
                                <MDBCol sm="12" lg="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Property Support E-mail*
                                    </label>
                                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            The e-mail address subscribers will see on support pages.
                                        </span>
                                    </MDBTooltip>
                                    <input
                                        required
                                        name="email"
                                        id="email"
                                        className="form-control emailInput"
                                        onBlur={(event) =>
                                            this.validateEmail(this.state.email)
                                        }
                                        onChange={this.changeHandler}
                                        value={this.state.email}
                                    />
                                    {this.state.invalidEmail !== undefined ? (
                                        <div className="invalid">
                                            {this.state.invalidEmail}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </MDBCol>
                            </MDBRow>
                            <h5 className="font-weight-bold pl-0 my-4">
                                <strong>Configuration Options</strong>
                            </h5>
                            <MDBRow>
                                <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Welcome E-mail Send Advance
                                    </label>
                                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            Enter the number of hours prior to a subscriber’s service start time that you want them to receive their welcome e-mail. Enter 0 if you want the e-mails to be sent as soon as the subscriber is added
                                        </span>
                                    </MDBTooltip>
                                    <input
                                        required
                                        name="countdown"
                                        id="countdown"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.countdown}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid number
                                    </div>
                                </MDBCol>
                                <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Resident Devices VLAN Range *
                                    </label>
                                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            This range will be used for dynamic VLANs if overlapping subscriptions is enabled. It will also be used to assign VLANs to units when adding a unit if you do not provide a VLAN when adding a unit.
                                        </span>
                                    </MDBTooltip>
                                    <MDBRow>
                                        <MDBCol col="6" sm="12" lg="6">
                                            <input
                                                required
                                                name="minVlanRes"
                                                id="minVlanRes"
                                                className="form-control"
                                                onChange={this.changeHandler}
                                                value={this.state.minVlanRes}
                                            />
                                        </MDBCol>
                                        <div className="invalid-feedback">
                                            Please provide a valid number
                                        </div>
                                        <MDBCol col="6" sm="12" lg="6" className="dash">
                                            <input
                                                required
                                                name="maxVlanRes"
                                                id="maxVlanRes"
                                                className="form-control"
                                                onChange={this.changeHandler}
                                                value={this.state.maxVlanRes}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <div className="invalid-feedback">
                                        Please provide a valid number
                                    </div>
                                </MDBCol>

                            </MDBRow>
                            {/* <MDBRow>
                                <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Default Subscription Duration (Hours) -{" "}
                                        <br />
                                        Residential Units
                                        <br />0 = unlimited
                                    </label>
                                    <input
                                        required
                                        name="durationResident"
                                        id="durationResident"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.durationResident}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid number
                                    </div>
                                </MDBCol>
                                <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Default Subscription Duration (Hours) -{" "}
                                        <br />
                                        Guest Subscriptions
                                        <br />0 = unlimited
                                    </label>
                                    <input
                                        required
                                        name="durationGuest"
                                        id="durationGuest"
                                        className="form-control"
                                        onChange={this.changeHandler}
                                        value={this.state.durationGuest}
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid number
                                    </div>
                                </MDBCol>
                            </MDBRow> */}
                            <MDBRow>
                                <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Guest Devices VLAN Range *
                                    </label>
                                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            Not Yet In Use: In a future update, this range will be used to dynamically assign VLANs to Guest devices
                                        </span>
                                    </MDBTooltip>
                                    <MDBRow>
                                        <MDBCol col="6" sm="12" lg="6">
                                            <input
                                                required
                                                name="minVlanGuest"
                                                id="minVlanGuest"
                                                className="form-control"
                                                onChange={this.changeHandler}
                                                value={this.state.minVlanGuest}
                                            />
                                            <div className="invalid-feedback">
                                                Please provide a valid number
                                            </div>
                                        </MDBCol>
                                        <MDBCol col="6" sm="12" lg="6" className="dash">
                                            <input
                                                required
                                                name="maxVlanGuest"
                                                id="maxVlanGuest"
                                                className="form-control"
                                                onChange={this.changeHandler}
                                                value={this.state.maxVlanGuest}
                                            />
                                            <div className="invalid-feedback">
                                                Please provide a valid number
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol col="6">
                                    <label
                                        htmlFor="defaultFormLoginEmailEx"
                                        className="black-text"
                                    >
                                        Roaming Devices VLAN Range *
                                    </label>
                                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            Not Yet In Use: In a future update, this range will be used to dynamically assign VLANs to roaming devices
                                        </span>
                                    </MDBTooltip>
                                    <MDBRow>
                                        <MDBCol col="6" sm="12" lg="6">
                                            <input
                                                required
                                                name="minVlanRoaming"
                                                id="minVlanRoaming"
                                                className="form-control"
                                                onChange={this.changeHandler}
                                                value={this.state.minVlanRoaming}
                                            />
                                            <div className="invalid-feedback">
                                                Please provide a valid number
                                            </div>
                                        </MDBCol>
                                        <MDBCol col="6" sm="12" lg="6" className="dash">
                                            <input
                                                required
                                                name="maxVlanRoaming"
                                                id="maxVlanRoaming"
                                                className="form-control"
                                                onChange={this.changeHandler}
                                                value={this.state.maxVlanRoaming}
                                            />
                                            <div className="invalid-feedback">
                                                Please provide a valid number
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>

                            </MDBRow>

                            <MDBRow>
                <MDBCol col="6" sm="12" lg="6">
                  <MDBInput
                    label="Send email for added subscriptions?"
                    type="checkbox"
                    id="sendSubscriptionAddedEmail"
                    name="sendSubscriptionAddedEmail"
                    onChange={
                      this.toggleCheckboxValuesendSubscriptionAddedEmail
                    }
                    checked={this.state.sendSubscriptionAddedEmail}
                  />

                  <MDBInput
                    label="Send email for ended subscriptions?"
                    type="checkbox"
                    id="sendSubscriptionEndEmail"
                    name="sendSubscriptionEndEmail"
                    onChange={
                      this.toggleCheckboxValuesendSubscriptionEndedEmail
                    }
                    checked={this.state.sendSubscriptionEndEmail}
                  />
                </MDBCol>
                <MDBCol col="6" sm="12" lg="6">
                  <MDBInput
                    label="Allow Overlapping Subscriptions"
                    type="checkbox"
                    id="allowOverlapping"
                    name="allowOverlapping"
                    onChange={this.toggleCheckboxValueAllowOverlapping}
                    checked={this.state.allowOverlapping}
                  >
                    <MDBTooltip
                      material
                      placement="left"
                      className="rightTooltip"
                      style={{ display: "block" }}
                    >
                      <MDBIcon far icon="question-circle" />
                      <span>
                        Allow multiple subscribers to sign up for service in the
                        same unit. If enabled, a temporary unit on a dynamic
                        VLAN from the Residential Devices Range will be created
                        while the subscription is overlapping.
                      </span>
                    </MDBTooltip>
                  </MDBInput>
                  <MDBInput
                    label="Enable Broadband Label?"
                    type="checkbox"
                    id="displayFCCLabel"
                    name="displayFCCLabel"
                    onChange={this.toggleCheckboxValueDisplayFCCLabel}
                    checked={this.state.displayFCCLabel}
                    disabled={this.state.disabled}
                  >
                    <MDBTooltip
                      material
                      placement="left"
                      className="rightTooltip"
                      style={{ display: "block" }}
                    >
                      <MDBIcon far icon="question-circle" />
                      <span>
                        Enabling will show broadband labels for all service
                        plans at the venue in the shopping cart and subscriber
                        account settings
                      </span>
                    </MDBTooltip>
                  </MDBInput>
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow>
                <MDBCol col="6" sm="12" lg="6">
                  <label
                    htmlFor="defaultFormLoginEmailEx"
                    className="black-text"
                  >
                    Typical Latency
                  </label>
                  <MDBTooltip
                    material
                    placement="left"
                    className="rightTooltip"
                    style={{ display: "block" }}
                  >
                    <MDBIcon far icon="question-circle" />
                    <span>
                      The expected latency for subscribers. This field is
                      required if the broadband label is enabled.
                    </span>
                  </MDBTooltip>
                  <input
                    // required
                    name="typicalLatency"
                    id="typicalLatency"
                    className="form-control"
                    onChange={this.changeHandler}
                    value={this.state.typicalLatency}
                    // disabled={!this.state.displayFCCLabel}
                  />
                  <div className="invalid-feedback">
                    Please provide a valid number
                  </div>
                </MDBCol>
              </MDBRow>
              <br />
                            <div className="text-right submitButton">
                                <MDBBtn color="primary" onClick={this.props.toggleAddNewVenue}>
                                    Cancel
                                </MDBBtn>
                                <MDBBtn color="danger" type="submit" className="float-right">
                                    {this.props.loading === true ? (
                                        <div
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </div>
                                    ) : (
                                        "Next"
                                    )}
                                </MDBBtn>
                            </div>
                        </form>
                    }{
                        this.state.formActivePanel1 === 2 &&
                        <>
                            {this.state.lastProcessed > 0 ? (
                                <>
                                    <MDBModal isOpen={this.state.modalError} toggle={this.toggle} size="md" className="form" centered>
                                        <MDBModalHeader toggle={this.toggleError}>
                                            <h4>Error Uploading Units</h4>
                                        </MDBModalHeader>
                                        <MDBModalBody>
                                            {this.state.lastProcessed === 1 ? (
                                                <p>
                                                    Line {this.state.lastProcessed} (Unit: {this.state.unitUnprocessed}, Building: {this.state.buildingUnprocessed}, Floor:{" "}
                                                    {this.state.floorUnprocessed}) could not be processed. Re-upload the CSV after fixing line 1.
                                                </p>
                                            ) : (
                                                <p>
                                                    Line {this.state.lastProcessed} (Unit: {this.state.unitUnprocessed}, Building: {this.state.buildingUnprocessed}, Floor:{" "}
                                                    {this.state.floorUnprocessed}) could not be processed. Re-upload the CSV after fixing line {this.state.lastProcessed}{" "}
                                                    and removing lines 1-{this.state.lastProcessed - 1}.
                                                </p>
                                            )}
                                        </MDBModalBody>
                                    </MDBModal>
                                </>
                            ) : (
                                ""
                            )}
                            {this.state.loading_upload === true ? (
                                <div className="overlay" id="overlay" style={{ display: "block" }}>
                                    <div className="overlayProgressBar">
                                        <MDBProgress material value={this.state.progress} height="20px" color="danger" striped />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {this.state.loading_upload === true ? (
                                <div className="overlay" id="overlay" style={{ display: "block" }}>
                                    <div className="overlayProgressBar">
                                        <MDBProgress material value={this.state.progress} height="20px" color="danger" striped />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <h5 className="font-weight-bold pl-0 my-4">
                                        <strong>Upload Units</strong>
                                    </h5>
                                    <MDBRow>
                                        <MDBCol col="6" sm="12" lg="4">
                                            <h5>Venue Name: {sessionStorage.getItem("venueName")}</h5>
                                        </MDBCol>
                                    </MDBRow>
                                    <br />
                                    <p>Please note, only CSV files are accepted for the upload. Fields marked with "*" in template are required.</p>
                                    <MDBModalBody>
                                        <div className="text-right submitButton" key={this.state.theInputKey || ""}>
                                            <MDBBtn color="primary" onClick={this.handleNextPrevClick(1)(1)}>
                                                Previous
                                            </MDBBtn>
                                            <MDBBtn color="danger" onClick={() => this.exportCSVFile(headers, itemsFormatted, fileTitle)}>
                                                Download Template
                                            </MDBBtn>
                                            <MDBBtn color="danger">
                                                Upload Units
                                                <CSVReader
                                                    cssClass="csv-reader-input"
                                                    onFileLoaded={this.convertCSV}
                                                    parserOptions={papaparseOptions}
                                                    inputId="csvVenueUnitUploadData"
                                                    inputStyle={{ color: "transparent" }}
                                                />
                                            </MDBBtn>
                                            <MDBBtn color="primary" onClick={this.handleNextPrevClick(1)(3)}>
                                                Next
                                            </MDBBtn>
                                        </div>
                                    </MDBModalBody>
                                </>
                            )}
                        </>
                    }{
                        this.state.formActivePanel1 === 3 &&
                        <>

                            <h5 className="font-weight-bold pl-0 my-4">
                                <strong>Service Plans</strong>
                            </h5>
                            {this.state.disable_serviceplans === true || this.state.loading === true ? (
                                <div id="overlay" style={{ display: "block" }}>
                                    <div className="overlayMessage">
                                        <div className="spinner-border spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}

                            <MDBModal isOpen={this.state.modalAddServicePlan} toggle={this.toggle} size="fluid" className="form" centered>
                                <AddServicePlanToVenue
                                    toggleAddServicePlan={this.toggleAddServicePlan}
                                    venueName={this.props.venueName}
                                    submitAddServicePlan={this.submitAddServicePlan}
                                    add_serviceplan={this.state.add_serviceplan}
                                    servicePlans={this.props.servicePlans}
                                />
                            </MDBModal>
                            {this.props.servicePlans !== undefined ? (
                                <>
                                    <div className="servicePlans">
                                        <MDBRow>
                                            <MDBCol col="6" sm="12" lg="4">
                                                <h5>Venue Name: {sessionStorage.getItem("venueName")}</h5>
                                            </MDBCol>
                                        </MDBRow>
                                        <br />
                                        <MDBRow>
                                            <MDBCol col="6">
                                                <label
                                                    htmlFor="defaultFormLoginEmailEx"
                                                    className="black-text"
                                                >
                                                    Default Service Plan: Residential Units
                                                </label>
                                                <SelectSearch
                                                    id="selectDefaultServicePlanResident"
                                                    search={true}
                                                    filterOptions={fuzzySearch}
                                                    options={
                                                        this.state
                                                            .optionTemplateServicePlans
                                                    }
                                                    inputId="groupArray"
                                                    classNamePrefix="group-select"
                                                    onChange={
                                                        this.handleSelectDefaultServicePlanResident
                                                    }
                                                    value={
                                                        this.props.selectDefaultServicePlanResident
                                                    }
                                                    placeholder="Select..."
                                                />
                                            </MDBCol>
                                            <MDBCol col="6">
                                                <label
                                                    htmlFor="defaultFormLoginEmailEx"
                                                    className="black-text"
                                                >
                                                    Default Service Plan: Guest Subscription
                                                </label>
                                                <SelectSearch
                                                    id="selectDefaultServicePlanWireless"
                                                    search={true}
                                                    filterOptions={fuzzySearch}
                                                    options={
                                                        this.state
                                                            .optionTemplateServicePlans
                                                    }
                                                    inputId="groupArray"
                                                    classNamePrefix="group-select"
                                                    onChange={
                                                        this.handleSelectDefaultServicePlanWireless
                                                    }
                                                    value={
                                                        this.props.selectDefaultServicePlanWireless
                                                    }
                                                    placeholder="Select..."
                                                />

                                            </MDBCol>
                                        </MDBRow>
                                        <br />
                                        <VenueServicePlansTable
                                            servicePlans={this.props.servicePlans}
                                            refreshed={this.props.refreshed}
                                            disablePlanConfirm={this.disablePlanConfirm}
                                        />
                                    </div>
                                </>
                            ) : (
                                <MDBSpinner small />
                            )}
                            <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(4)}>
                                Next
                            </MDBBtn>
                            <MDBBtn onClick={this.toggleAddServicePlan} className="btn-right btn-danger">
                                Add Service Plan
                            </MDBBtn>
                            <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(2)}>
                                Previous
                            </MDBBtn>

                        </>
                    }{
                        this.state.formActivePanel1 === 4 &&
                        <>
                            <MDBModal isOpen={this.state.modalModifyEquipment} toggle={this.toggle} size="lg" className="form" centered>
                                <ModifyEquipment
                                    toggleModifyEquipment={this.toggleModifyEquipment}
                                    equipID={this.state.equipID}
                                    venueName={this.props.venueName}
                                    submitModifyEquipment={this.submitModifyEquipment}
                                    modify_equipment={this.state.modify_equipment}
                                    venueEquipList={this.state.venueEquipList}
                                />
                            </MDBModal>
                            <MDBModal isOpen={this.state.modalAddVenueEquipment} toggle={this.toggle} size="lg" className="form" centered>
                                <AddVenueEquipment
                                    toggleAddVenueEquipment={this.toggleAddVenueEquipment}
                                    venueName={this.props.venueName}
                                    submitAddVenueEquipment={this.submitAddVenueEquipment}
                                    add_venueequip={this.state.add_venueequip}
                                />
                            </MDBModal>
                            {!this.props.loading ? (
                                <>
                                    <h5 className="font-weight-bold pl-0 my-4">
                                        <strong>Equipment</strong>
                                    </h5>
                                    <MDBRow>
                                        <MDBCol col="6" sm="12" lg="4">
                                            <h5>Venue Name: {sessionStorage.getItem("venueName")}</h5>
                                        </MDBCol>
                                    </MDBRow>
                                    <br />
                                    <div className="servicePlans">
                                        <VenueEquipmentTable venueEquipList={this.state.venueEquipList} refreshed={this.props.refreshed} modifyEquipment={this.modifyEquipment} />
                                    </div>
                                </>
                            ) : (
                                <MDBSpinner small />
                            )}
                            <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(5)}>
                                Next
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={this.toggleAddVenueEquipment} className="btn-right">
                                Add Equipment
                            </MDBBtn>
                            <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(3)}>
                                Previous
                            </MDBBtn>

                        </>
                    }{
                        this.state.formActivePanel1 === 5 &&
                        <>
                            <h5 className="font-weight-bold pl-0 my-4">
                                <strong>Shopping Cart</strong>
                            </h5>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="4">
                                    <h5>Venue Name: {sessionStorage.getItem("venueName")}</h5>
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <form className="needs-validation" onSubmit={this.handleNextPrevClick(1)(6)} noValidate>
                                <MDBInput
                                    label="Enable shopping cart?"
                                    type="checkbox"
                                    id="enableShoppingCart"
                                    name="enableShoppingCart"
                                    onChange={this.toggleCheckboxValueEnableShoppingCart}
                                    checked={this.state.enableShoppingCart}
                                >
                                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            The venue will display in your shopping cart for self sign up
                                        </span>
                                    </MDBTooltip>
                                </MDBInput>
                                <br />
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                            Shopping Cart Price Discount (US Dollars)
                                        </label>
                                        <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                            <MDBIcon far icon="question-circle" />
                                            <span>
                                                Apply a discount to all service plan billing cycle prices at this venue.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            type="number"
                                            id="shoppingCartPriceAdjustment"
                                            name="shoppingCartPriceAdjustment"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={-Math.abs(this.state.shoppingCartPriceAdjustment)}
                                        />
                                        <div className="invalid-feedback">Please provide a value.</div>
                                    </MDBCol>

                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Trial Cycles Adjustments
                                        </label>
                                        <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                            <MDBIcon far icon="question-circle" />
                                            <span>
                                                Adjust trial cycles for all services plans at this venue
                                            </span>
                                        </MDBTooltip>
                                        <SelectSearch
                                            id="selectTrialCycles"
                                            search={true}
                                            filterOptions={fuzzySearch}
                                            options={
                                                this.state
                                                    .trialCyclesOptions
                                            }
                                            inputId="trialCycleArray"
                                            classNamePrefix="unit-select form-control"
                                            onChange={
                                                this.handleSelectTrialCycles
                                            }
                                            value={
                                                this.state.selectedTrialCycles
                                            }
                                            placeholder="Please select..."
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a value
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                            Stripe Publishable Key
                                        </label>
                                        <input
                                            id="stripePublishableKey"
                                            name="stripePublishableKey"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.stripePublishableKey}
                                        />
                                        <div className="invalid-feedback">Please provide a value.</div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                            Stripe Secret Key
                                        </label>
                                        <input
                                            id="stripeSecretKey"
                                            name="stripeSecretKey"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.stripeSecretKey}
                                        />
                                        <div className="invalid-feedback">Please provide a value.</div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                            Stripe Webhook Signing Secret
                                        </label>
                                        <input
                                            id="stripeWebhookSigningSecret"
                                            name="stripeWebhookSigningSecret"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.stripeWebhookSigningSecret}
                                        />
                                        <div className="invalid-feedback">Please provide a value.</div>
                                    </MDBCol>
                                </MDBRow>
                                <div className="text-right submitButton">
                                    <MDBBtn className="btn-right btn-danger" color="primary" type="submit">
                                        {!this.props.loading ? (
                                            <>
                                                Save (Next)
                                            </>
                                        ) : (
                                            <MDBSpinner small />
                                        )}
                                    </MDBBtn>

                                    <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(4)}>
                                        Previous
                                    </MDBBtn>

                                </div>
                            </form>
                        </>
                    }{
                        this.state.formActivePanel1 === 6 &&
                        <>
                            <h5 className="font-weight-bold pl-0 my-4">
                                <strong>Keypools</strong>
                            </h5>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="4">
                                    <h5>Venue Name: {sessionStorage.getItem("venueName")}</h5>
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <MDBModal isOpen={this.state.modalAddKeypool} toggle={this.toggle} size="md" className="form" centered>
                                <AddKeypool
                                    toggleAddKeypool={this.toggleAddKeypool}
                                    venueName={this.props.venueName}
                                    submitAddKeypool={this.submitAddKeypool}
                                    add_keypool={this.state.add_keypool}
                                    loadingAddKeypool={this.state.loadingAddKeypool}
                                />
                            </MDBModal>
                            {!this.props.loading ? (
                                <>
                                    <div className="servicePlans">
                                        <VenueKeypoolsTable keyPoolList={this.state.keyPoolList} refreshed={this.props.refreshed} disablePlanConfirm={this.disablePlanConfirm} />
                                    </div>
                                </>
                            ) : (
                                <MDBSpinner small />
                            )}
                            <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(7)}>
                                Next
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={this.toggleAddKeypool} className="btn-right">
                                Add Keypool
                            </MDBBtn>
                            <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(5)}>
                                Previous
                            </MDBBtn>
                        </>
                    }{
                        this.state.formActivePanel1 === 7 &&
                        <>
                            <h5 className="font-weight-bold pl-0 my-4">
                                <strong>RealPage</strong>
                            </h5>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="4">
                                    <h5>Venue Name: {sessionStorage.getItem("venueName")}</h5>
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <form className="needs-validation" onSubmit={this.handleNextPrevClick(1)(8)} noValidate>
                                <p className="mb-0">Realpage Sync Options:</p>
                                <MDBInput
                                    label="Download unit data (once a day)"
                                    type="radio"
                                    id="enableRealPageSyncFalse"
                                    name="Download unit data (once a day)"
                                    onClick={this.changeHandlerRadio(
                                        false
                                    )}
                                    checked={
                                        this.state
                                            .enableRealPageSync ===
                                            false
                                            ? true
                                            : false
                                    }
                                />
                                <MDBInput
                                    label="Download units and subscriber data (once a day)"
                                    type="radio"
                                    id="enableRealPageSyncTrue"
                                    name="Download units and subscriber data (once a day)"
                                    onClick={this.changeHandlerRadio(
                                        true
                                    )}
                                    checked={
                                        this.state
                                            .enableRealPageSync ===
                                            true
                                            ? true
                                            : false
                                    }
                                />
                                <br />
                                <p>{this.state.enableSyncMessage}</p>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="12">
                                        <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                            RealPage Site ID
                                        </label>
                                        <input
                                            id="realpageSiteID"
                                            name="realpageSiteID"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.realpageSiteID}
                                        />
                                        <div className="invalid-feedback">Please provide a value.</div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="12">
                                        <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                            RealPage PMC ID
                                        </label>
                                        <input
                                            id="realpagePMCID"
                                            name="realpagePMCID"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.realpagePMCID}
                                        />
                                        <div className="invalid-feedback">Please provide a value.</div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="12">
                                        <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                            RealPage Sync Date
                                        </label>
                                        <input
                                            disabled
                                            id="realpageSyncDate"
                                            name="realpageSyncDate"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.realpageSyncDate}
                                        />
                                        <div className="invalid-feedback">Please provide a value.</div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>



                                <div className="text-right submitButton">
                                    <MDBBtn className="btn-right btn-danger" type="submit" color="primary" onClick={this.handleNextPrevClick(1)(8)}>
                                        Save (Next)
                                    </MDBBtn>
                                    <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(6)}>
                                        Previous
                                    </MDBBtn>

                                </div>
                            </form>
                        </>
                    }{
                        this.state.formActivePanel1 === 8 &&
                        <>
                            <h5 className="font-weight-bold pl-0 my-4">
                                <strong>Administrators</strong>
                            </h5>
                            <MDBRow>
                                <MDBCol col="6" sm="12" lg="4">
                                    <h5>Venue Name: {sessionStorage.getItem("venueName")}</h5>
                                </MDBCol>
                            </MDBRow>
                            <br />
                            {this.state.toggleConfirm ? (
                                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                                    <MDBModalHeader toggle={this.toggle}>Confirm Disable</MDBModalHeader>
                                    <MDBModalBody>
                                        <p>Email: {this.state.confirmEmail}</p>
                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        <MDBBtn color="primary" onClick={() => this.toggleDisable(this.state.confirmEmail)}>
                                            Confirm
                                        </MDBBtn>
                                        <MDBBtn color="primary" onClick={this.toggle}>
                                            Close
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModal>
                            ) : null}

                            <MDBModal isOpen={this.state.modalAddVenueAdministrators} toggle={this.toggle} size="lg" className="form" centered>
                                <AddVenueAdministrator
                                    toggleAddVenueAdministrators={this.toggleAddVenueAdministrators}
                                    venueName={this.props.venueName}
                                    submitAddVenueAdministrators={this.submitAddVenueAdministrators}
                                    add_venueadmin={this.state.add_venueadmin}
                                />
                            </MDBModal>
                            {!this.props.loading ? (
                                <>
                                    <div className="servicePlans">
                                        <VenueAdministratorsTable staff={this.state.staffArrayAll} refreshed={this.props.refreshed} toggleDisable={this.toggleConfirmDisable} />
                                    </div>
                                </>
                            ) : (
                                <MDBSpinner small />
                            )}
                            <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.props.toggleAddNewVenue}>
                                Finish
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={this.toggleAddVenueAdministrators} className="btn-right">
                                Add Administrator
                            </MDBBtn>
                            <MDBBtn className="btn-right btn-danger" color="primary" onClick={this.handleNextPrevClick(1)(7)}>
                                Previous
                            </MDBBtn>

                        </>
                    }
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(AddVenue);