import React, { Component } from "react";

import { MDBBtn, MDBSpinner, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, toast } from "mdbreact";

import VenueAdministratorsTable from "../../../components/NetworkMgmt/Venues/Datatables/VenueAdministratorsTable";

import AddVenueAdministrator from "../../../components/NetworkMgmt/Forms/AddVenueAdministrator/AddVenueAdministrator";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";




class ViewVenueAdministrators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loading: false,
			disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false
    };
  }

  toggleAddVenueAdministrators = () => {
    this.setState({
      modalAddVenueAdministrators: !this.state.modalAddVenueAdministrators,
    });
  };

  submitAddVenueAdministrators = (email, firstName, lastName, phoneNumber, language, contactpref_email, contactpref_sms, role, venueid) => {
    this.setState({ 
      add_venueadmin: true,
      loading: true
    });

    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      SharedSecret: this.props.sharedSecret,
      VenueID: this.props.venueID,
      EmailAddress: email,
      Role: role,
      GivenName: firstName,
      FamilyName: lastName,
      "PhoneNumber.E164": phoneNumber,
      locale: language,
      contactpref_sms: contactpref_email,
      contactpref_voice: contactpref_sms
    };

    axios
      .post("/networkoperatorconsole/venues/enableStaffAccess", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Added Administrator to Venue!", {
            closeButton: false,
          });
          this.toggleAddVenueAdministrators();
          this.props.updateName(this.props.venueName, this.props.venueID);
          this.props.getVenueDetails(this.props.venueID);
          this.setState({ 
            add_venueadmin: false,
            loading: false
          });
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.toggleAddVenueAdministrators();
          this.setState({ 
            add_venueadmin: false,
            loading: false 
          });
        }
      );
  };

  toggleConfirmDisable = (key) => {
    this.setState({
      toggleConfirm: true,
      modal: !this.state.modal,

      confirmEmail: key[0],
    });
  };

  toggleDisable = (email) => {
    this.setState({ staffArrayAll: undefined });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: this.props.venueID,
      EmailAddress: email,
    };
    axios
      .post("/networkoperatorconsole/venues/disableStaffAccess", body, {
        headers: {},
      })
      .then(
        (response) => {

          toast.success("Deleted Administrator", {
            closeButton: false,
          });
          this.setState({
            modal: !this.state.modal,
          });
          this.props.updateName(this.props.venueName, this.props.venueID);
          this.props.getVenueDetails(this.props.venueID);
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
        }
      );
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {

    return (
      <>
        {this.state.toggleConfirm ? (
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>Confirm Disable</MDBModalHeader>
            <MDBModalBody>
              <p>Email: {this.state.confirmEmail}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={() => this.toggleDisable(this.state.confirmEmail)}>
                Confirm
              </MDBBtn>
              <MDBBtn color="primary" onClick={this.toggle}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        ) : null}

        <MDBModal isOpen={this.state.modalAddVenueAdministrators} toggle={this.toggle} size="lg" className="form" centered>
          <AddVenueAdministrator
            toggleAddVenueAdministrators={this.toggleAddVenueAdministrators}
            venueName={this.props.venueName}
            submitAddVenueAdministrators={this.submitAddVenueAdministrators}
            add_venueadmin={this.state.add_venueadmin}
            loading={this.state.loading}
          />
        </MDBModal>
        {this.props.staffArrayAll !== undefined ? (
          <>
            <div className="servicePlans">
              <VenueAdministratorsTable staff={this.props.staffArrayAll} refreshed={this.props.refreshed} toggleDisable={this.toggleConfirmDisable} />
            </div>
          </>
        ) : (
          <MDBSpinner small />
        )}
        <MDBBtn 
          color="danger" 
          onClick={this.toggleAddVenueAdministrators} 
          className="btn-right"
          disabled={this.state.disabled}
        >
          Add Administrator
        </MDBBtn>
      </>
    );
  }
}

export default withTranslation()(ViewVenueAdministrators);