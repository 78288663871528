import React, { Component } from "react";
import {
  toast,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions
} from "mdbreact";
import { withTranslation } from "react-i18next";
import axios from "../../../axios";
import ModifyDisplayOptions from "../../../components/NetworkMgmt/Forms/ModifyDisplayOptions/ModifyDisplayOptions";
import { S3 } from "aws-sdk";
var AWS = require('aws-sdk');
class OperatorInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sms: [],
      logoURL: undefined,
      disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false
    };
  }
  componentDidMount() {
    this.getDisplayInfo();
  }
  getDisplayInfo = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    this.setState({
      loading_info: true,
      operatorInfo: undefined,
    });
    axios
      .post("/operatorconsole/config/listDisplayOptions", body, {
        headers: {},
      })
      .then(
        (response) => {
          var formArrayDisplayOptions = [];
          let optionTemplateDisplayOptions = [];
          this.setState({
            allDisplayOptions: response.data.DisplayOptions,
            allMetaOptions: response.data.metaOptions
          })
          Object.keys(response.data.DisplayOptions).map((oneKey, i) => {
            optionTemplateDisplayOptions.push({
              value: oneKey,
              name: response.data.DisplayOptions[oneKey]["DisplayOptionName"],
            });
            return true;
          });
          this.setState({ loading_info: false });
          this.setState({
            optionTemplateDisplayOptions: optionTemplateDisplayOptions,
            loading_info: false,
          });
        },
        (error) => {
          this.setState({ loading_info: false });
        }
      );
  };
  submitModifyDisplayOptions = (
    displayOptionID,
    displayOptionName,
    s3Directory,
    s3DirectoryCustomComponents,
    displayMode,
    primaryColor,
    secondaryColor,
    primaryTextColor,
    secondaryTextColor,
    backgroundColor,
    borderColor,
    linkColor,
    modalColor,
    bannerColor1,
    bannerColor2,
    logoFileNameNormal,
    navbarColor,
    tableColor,
    inputBoxColor,
    loginDesktopLogoText,
    loginMobileLogoText,
    loginBackgroundColor,
    loginTextColor,
    fileLogo,
    fileLogoDesktop,
    fileLogoMobile,
    descriptionContent,
    fileLogoFavIcon,
    logoFavIconText,
    titleTextContent,
    cancellationMethod,
    googleAnalyticsID,
    privacyPolicyURL,
    showFooterOnLogin,
    showTour,
    allowSelfCancel,
    allowSelfPaymentUpdate,
    allowSelfServicePlanUpdate,
    externallyManagedSupport,
    externalSupportSystemURL,
    showEquipmentStatusVenueAdmin,
    showSupportLinks
  ) => {
    this.setState({ modify_sms: true });
    let body = {
      "AccessToken": sessionStorage.getItem("AccessToken"),
      "DisplayOptionName": displayOptionName,
      "DisplayConfiguration": {
        [displayMode]: {
          "LogoFileNameDesktopLogin": loginDesktopLogoText,
          "LogoFileNameMobileLogin": loginMobileLogoText,
          "LogoFileNameDesktopMobileAfterLogin": logoFileNameNormal,
          "LoginBackgroundColorHex": loginBackgroundColor,
          "LoginTextColorHex": loginTextColor,
          "BackgroundColorHex": backgroundColor,
          "PrimaryColorHex": primaryColor,
          "SecondaryColorHex": secondaryColor,
          "PrimaryTextColorHex": primaryTextColor,
          "SecondaryTextColorHex": secondaryTextColor,
          "BorderColorHex": borderColor,
          "LinkColorHex": linkColor,
          "TableColorHex": tableColor,
          "BannerColor1Hex": bannerColor1,
          "BannerColor2Hex": bannerColor2,
          "NavbarColorHex": navbarColor,
          "ModalColorHex": modalColor,
          "InputBoxColorHex": inputBoxColor
        },
        ["Behavior"]: {
          "S3Directory": s3Directory,
          "S3DirectoryCustomComponents": s3DirectoryCustomComponents,
          "CancellationMethod": cancellationMethod,
          "GoogleAnalyticsID": googleAnalyticsID,
          "PrivacyPolicyURL": privacyPolicyURL,
          "ShowFooterOnLoginPage": showFooterOnLogin,
          "ShowTour": showTour
        },
        ["MetaData"]: {
          "DescriptionContent": descriptionContent,
          "FavIconHRef": logoFavIconText,
          "TitleTextContent": titleTextContent
        },
        ["ShoppingCart"]: {
          "AllowSelfCancellation": allowSelfCancel,
          "AllowSelfPaymentMethodUpdate": allowSelfPaymentUpdate,
          "AllowSelfServicePlanUpdate": allowSelfServicePlanUpdate
        },
        ["Support"]: {
          "ExternallyManagedSupport": externallyManagedSupport,
          "ExternalSupportSystemURL": externalSupportSystemURL,
          "ShowEquipmentStatusToVenueAdministrator": showEquipmentStatusVenueAdmin,
          "ShowSupportLinks": showSupportLinks
        }
      }
    }
    console.log(body);
    Promise.all([
      axios.post("/operatorconsole/config/addDisplayOptions", body, {
        headers: {},
      }),
    ])
      .then
      (async ([res1]) => {
        toast.success("Modified Display Options! Browser will now refresh to show updates.", {
          closeButton: false,
        });
        this.props.toggleDisplayOptionsInfo();
        this.setState({ uploading: true });
        // AWS.config.update({
        //   accessKeyId: "AKIASEI4GRAFGMRQ67XL",
        //   secretAccessKey: "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",
        //   AWS_SDK_LOAD_CONFIG: 1
        // });
        // var s3 = new AWS.S3({ region: "us-east-1" });
        // let params;
        // if (fileLogo !== undefined) {
        //   params = {
        //     Bucket: 'ui.us-east-1.wifikey.link/' + s3Directory,
        //     Key: fileLogo.name,
        //     Body: fileLogo,
        //     ContentType: fileLogo.type
        //   };
        // }
        // if (fileLogoDesktop !== undefined) {
        //   params = {
        //     Bucket: 'ui.us-east-1.wifikey.link/' + s3Directory,
        //     Key: fileLogoDesktop.name,
        //     Body: fileLogoDesktop,
        //     ContentType: fileLogoDesktop.type
        //   };
        // }
        // if (fileLogoMobile !== undefined) {
        //   params = {
        //     Bucket: 'ui.us-east-1.wifikey.link/' + s3Directory,
        //     Key: fileLogoMobile.name,
        //     Body: fileLogoMobile,
        //     ContentType: fileLogoMobile.type
        //   };
        // }
        // if (fileLogoFavIcon !== undefined) {
        //   params = {
        //     Bucket: 'ui.us-east-1.wifikey.link/' + s3Directory,
        //     Key: fileLogoFavIcon.name,
        //     Body: fileLogoFavIcon,
        //     ContentType: fileLogoFavIcon.type
        //   };
        // }
        // s3.putObject(params, function (err) {
        //   if (err) {
        //     console.log(err);
        //   }
        //   else {
        //     console.log("Success");
        //     // this.props.getDisplayInfo();
        //     // window.addEventListener("beforeunload", onConfirmRefresh, { capture: false });
        //   }
        // });
        this.setState({ modify_sms: false });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.props.toggleDisplayOptionsInfo();
        this.setState({ modify_sms: false });
      }
      );
  };
  render() {
    return (
      <>
        {this.state.loading_info === true ? (
          <div className="overlay" id="overlay" style={{ display: "block" }}>
            <div className="overlayMessage">
              <div className="spinner-border spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <ModifyDisplayOptions
          getDisplayInfo={this.getDisplayInfo}
          allDisplayOptions={this.state.allDisplayOptions}
          allMetaOptions={this.state.allMetaOptions}
          optionTemplateDisplayOptions={this.state.optionTemplateDisplayOptions}
          toggleDisplayOptionsInfo={this.props.toggleDisplayOptionsInfo}
          submitModifyDisplayOptions={this.submitModifyDisplayOptions}
          modify_sms={this.state.modify_sms}
          displayOptionID={this.props.displayOptionID}
        />
      </>
    );
  }
}
export default withTranslation()(OperatorInfo);