/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBLink,
  MDBContainer,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBInput,
  MDBTooltip
} from "mdbreact";
import { FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import reactCSS from 'reactcss'
import { ChromePicker } from 'react-color';
import { withTranslation } from "react-i18next";
import SelectSearch, { fuzzySearch } from 'react-select-search';
import parse from 'html-react-parser';
import { S3 } from "aws-sdk";
import axios from "../../../../axios";
import Toggle from 'react-toggle';
import OperatorSupportedLocalesTable from "../../../../components/NetworkMgmt/Settings/Datatables/OperatorSupportedLocalesTable";
var AWS = require('aws-sdk');
class AddNewVenueEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SMS: "",
      sms: false,
      voice: false,
      invalidPhone: null,
      loading: false,
      userFound: false,
      activeItem: "1",
      activeItemPreview: "6",
      displayOptionID: undefined,
      displayOptionValue: undefined,
      // primaryTextColor: "#000000",
      displayColorPicker: false,
      disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false,
      allDisplayOptions: this.props.allDisplayOptions,
      collapse1: false,
      darkmodeDisplayOptions: localStorage.getItem("darkModeDisplayOptions") === "true" ? true : false,
      modeOptions: ([
        { value: 'ThemeLight', name: 'Light' },
        { value: 'ThemeDark', name: 'Dark' }
      ]),
      displayMode: 'ThemeLight',
      cancelMethodOptions: ([
        { value: 'radio', name: 'Radio Button' },
        { value: 'checkbox', name: 'Checkbox' }
      ])
    };
    this.handleEditorChangeMainTemplate = this.handleEditorChangeMainTemplate.bind(this);
    this.handleEditorChangePreheader = this.handleEditorChangePreheader.bind(this);
    this.handleEditorChangeHeader = this.handleEditorChangeHeader.bind(this);
    this.handleEditorChangeTitle = this.handleEditorChangeTitle.bind(this);
    this.handleEditorChangeActionButton = this.handleEditorChangeActionButton.bind(this);
    this.handleEditorChangeAdditionalInfo = this.handleEditorChangeAdditionalInfo.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleEditorChangeTemplate = this.handleEditorChangeTemplate.bind(this);
    this.handleEditorChangeNetworkName = this.handleEditorChangeNetworkName.bind(this);
    this.handleEditorChangeWifiPassword = this.handleEditorChangeWifiPassword.bind(this);
    this.handleEditorChangeSubscriptionStart = this.handleEditorChangeSubscriptionStart.bind(this);
    this.handleEditorChangeSubscriptionEnd = this.handleEditorChangeSubscriptionEnd.bind(this);
    this.getDisplayInfo = this.getDisplayInfo.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.displayMode !== this.state.displayMode) {
      this.setState({ displayModePicked: this.state.displayMode });
    }
  }
  getDisplayInfo = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    this.setState({
      loading_info: true,
      operatorInfo: undefined,
      allDisplayOptions: undefined
    });
    axios
      .post("/operatorconsole/config/listDisplayOptions", body, {
        headers: {},
      })
      .then(
        (response) => {
          var formArrayDisplayOptions = [];
          let optionTemplateDisplayOptions = [];
          console.log("🚀 ~ file: DisplayOptionsInfo.js:45 ~ OperatorInfo ~ response.data:", response.data)
          this.setState({
            allDisplayOptions: response.data.DisplayOptions
          })
          Object.keys(response.data.DisplayOptions).map((oneKey, i) => {
            console.log(oneKey)
            optionTemplateDisplayOptions.push({
              value: oneKey,
              name: response.data.DisplayOptions[oneKey]["DisplayOptionName"],
            });
            return true;
          });
          this.setState({ loading_info: false });
          this.setState({
            optionTemplateDisplayOptions: optionTemplateDisplayOptions,
            loading_info: false,
          });
        },
        (error) => {
          this.setState({ loading_info: false });
        }
      );
  };
  submitModifyDisplayOptions = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (this.state.s3Directory !== undefined) {
      this.props.submitModifyDisplayOptions(
        this.state.displayOptionID,
        this.state.displayOptionName,
        this.state.s3Directory,
        this.state.s3DirectoryCustomComponents,
        this.state.displayMode,
        this.state.primaryColor,
        this.state.secondaryColor,
        this.state.primaryTextColor,
        this.state.secondaryTextColor,
        this.state.backgroundColor,
        this.state.borderColor,
        this.state.linkColor,
        this.state.modalColor,
        this.state.bannerColor1,
        this.state.bannerColor2,
        this.state.logoFileNameText,
        this.state.navbarColor,
        this.state.tableColor,
        this.state.inputBoxColor,
        this.state.loginDesktopLogoText,
        this.state.loginMobileLogoText,
        this.state.loginBackgroundColor,
        this.state.loginTextColor,
        this.state.file,
        this.state.fileLogoDesktop,
        this.state.fileLogoMobile,
        this.state.descriptionContent,
        this.state.fileLogoFavIcon,
        this.state.logoFavIconText,
        this.state.titleTextContent,
        this.state.cancellationMethod,
        this.state.googleAnalyticsID,
        this.state.privacyPolicyURL,
        this.state.showFooterOnLogin,
        this.state.showTour,
        this.state.allowSelfCancel,
        this.state.allowSelfPaymentUpdate,
        this.state.allowSelfServicePlanUpdate,
        this.state.externallyManagedSupport,
        this.state.externalSupportSystemURL,
        this.state.showEquipmentStatusVenueAdmin,
        this.state.showSupportLinks
      );
    }
  };
  HexToHSL(variableName, hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
      throw new Error("Could not parse Hex Color");
    }
    const rHex = parseInt(result[1], 16);
    const gHex = parseInt(result[2], 16);
    const bHex = parseInt(result[3], 16);
    const r = rHex / 255;
    const g = gHex / 255;
    const b = bHex / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = (max + min) / 2;
    let s = h;
    let l = h;
    // if (max === min) {
    //   // Achromatic
    //   return { h: 0, s: 0, l: 0 };
    // }
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    l = l / 100;
    h = Math.round(360 * h);
    // return { h,s,l };
    if (l > 0.8) {
      this.setState({
        [variableName]: "#000"
      })
    } else {
      this.setState({
        [variableName]: "#FFF"
      })
    }
  }
  changeHandlerDisplayOptionID = (value) => {
    let displayModeOption;
    if (this.props.displayOptionID === this.props.allDisplayOptions[value]["DisplayOptionID"]) {
      this.setState({
        defaultConfig: true
      })
    } else {
      this.setState({
        defaultConfig: false
      })
    }
    this.setState({
      displayOptionValue: value,
      displayOptionID: this.props.allDisplayOptions[value]["DisplayOptionID"],
      displayOptionName: this.props.allDisplayOptions[value]["DisplayOptionName"],
      logoFileNameText: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LogoFileNameDesktopMobileAfterLogin"],
      logoFileNameDesktopMobileAfterLogin: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LogoFileNameDesktopMobileAfterLogin"],
      s3Directory: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Behavior"]["S3Directory"],
      s3DirectoryCustomComponents: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Behavior"]["S3DirectoryCustomComponents"],
      primaryColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["PrimaryColorHex"],
      secondaryColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["SecondaryColorHex"],
      primaryTextColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["PrimaryTextColorHex"],
      secondaryTextColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["SecondaryTextColorHex"],
      backgroundColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["BackgroundColorHex"],
      borderColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["BorderColorHex"],
      linkColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LinkColorHex"],
      tableColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["TableColorHex"],
      bannerColor1: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["BannerColor1Hex"],
      bannerColor2: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["BannerColor2Hex"],
      navbarColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["NavbarColorHex"],
      modalColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["ModalColorHex"],
      inputBoxColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["InputBoxColorHex"],
      loginBackgroundColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LoginBackgroundColorHex"],
      loginTextColor: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LoginTextColorHex"],
      loginDesktopLogo: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LogoFileNameDesktopLogin"],
      loginDesktopLogoText: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LogoFileNameDesktopLogin"],
      loginMobileLogo: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LogoFileNameMobileLogin"],
      loginMobileLogoText: this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LogoFileNameMobileLogin"],
      logoFavIcon: this.props.allDisplayOptions[value]["DisplayConfiguration"]["MetaData"]["FavIconHRef"],
      logoFavIconText: this.props.allDisplayOptions[value]["DisplayConfiguration"]["MetaData"]["FavIconHRef"],
      descriptionContent: this.props.allDisplayOptions[value]["DisplayConfiguration"]["MetaData"]["DescriptionContent"],
      descriptionName: this.props.allDisplayOptions[value]["DisplayConfiguration"]["MetaData"]["DescriptionName"],
      titleTextContent: this.props.allDisplayOptions[value]["DisplayConfiguration"]["MetaData"]["TitleTextContent"],
      allowedLocales: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Behavior"]["AllowedLocales"],
      cancellationMethod: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Behavior"]["CancellationMethod"],
      googleAnalyticsID: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Behavior"]["GoogleAnalyticsID"],
      privacyPolicyURL: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Behavior"]["PrivacyPolicyURL"],
      showFooterOnLogin: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Behavior"]["ShowFooterOnLoginPage"],
      showTour: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Behavior"]["ShowTour"],
      allowSelfCancel: this.props.allDisplayOptions[value]["DisplayConfiguration"]["ShoppingCart"]["AllowSelfCancellation"],
      allowSelfPaymentUpdate: this.props.allDisplayOptions[value]["DisplayConfiguration"]["ShoppingCart"]["AllowSelfPaymentMethodUpdate"],
      allowSelfServicePlanUpdate: this.props.allDisplayOptions[value]["DisplayConfiguration"]["ShoppingCart"]["AllowSelfServicePlanUpdate"],
      externallyManagedSupport: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Support"]["ExternallyManagedSupport"],
      externalSupportSystemURL: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Support"]["ExternalSupportSystemURL"],
      showEquipmentStatusVenueAdmin: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Support"]["ShowEquipmentStatusToVenueAdministrator"],
      showSupportLinks: this.props.allDisplayOptions[value]["DisplayConfiguration"]["Support"]["ShowSupportLinks"]
    });
    this.HexToHSL("primaryDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["PrimaryColorHex"]);
    this.HexToHSL("secondaryDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["SecondaryColorHex"]);
    this.HexToHSL("primaryTextDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["PrimaryTextColorHex"]);
    this.HexToHSL("secondaryTextDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["SecondaryTextColorHex"]);
    this.HexToHSL("backgroundDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["BackgroundColorHex"]);
    this.HexToHSL("borderDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["BorderColorHex"]);
    this.HexToHSL("linkDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LinkColorHex"]);
    this.HexToHSL("modalDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["ModalColorHex"]);
    this.HexToHSL("tableDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["TableColorHex"]);
    this.HexToHSL("banner1DisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["BannerColor1Hex"]);
    this.HexToHSL("banner2DisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["BannerColor2Hex"]);
    this.HexToHSL("loginBackgroundDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LoginBackgroundColorHex"]);
    this.HexToHSL("loginTextDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["LoginTextColorHex"]);
    this.HexToHSL("inputBoxDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["InputBoxColorHex"]);
    this.HexToHSL("navbarDisplayTextColor", this.props.allDisplayOptions[value]["DisplayConfiguration"][this.state.displayMode]["NavbarColorHex"]);
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleEditorChangeMainTemplate(smsMainTemplate, editor) {
    this.setState({ smsMainTemplate });
  }
  handleEditorChangePreheader(smsPreheader, editor) {
    console.log(this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][this.state.smsUserType]["preheader"])
    this.setState({ smsPreheader });
  }
  handleEditorChange(smsMessage, editor) {
    this.setState({ smsMessage });
  }
  handleEditorChangeHeader(smsHeader, editor) {
    this.setState({ smsHeader });
  }
  handleEditorChangeTitle(smsTitle, editor) {
    this.setState({ smsTitle });
  }
  handleEditorChangeActionButton(smsActionButton, editor) {
    this.setState({ smsActionButton });
  }
  handleEditorChangeAdditionalInfo(smsAdditionalInfo, editor) {
    this.setState({ smsAdditionalInfo });
  }
  handleEditorChangeTemplate(smsTemplate, editor) {
    this.setState({ smsTemplate });
  }
  handleEditorChangeNetworkName(smsNetworkName, editor) {
    this.setState({ smsNetworkName });
  }
  handleEditorChangeWifiPassword(smsWifiPassword, editor) {
    this.setState({ smsWifiPassword });
  }
  handleEditorChangeSubscriptionStart(smsSubscriptionStart, editor) {
    this.setState({ smsSubscriptionStart });
  }
  handleEditorChangeSubscriptionEnd(smsSubscriptionEnd, editor) {
    this.setState({ smsSubscriptionEnd });
  }
  //Primary Color Picker
  handleClickPrimaryColor = () => {
    this.setState({ displayColorPickerPrimaryColor: !this.state.displayColorPickerPrimaryColor })
  };
  handleClosePrimaryColor = () => {
    this.setState({ displayColorPickerPrimaryColor: false })
  };
  handleChangePrimaryColor = (color) => {
    this.setState({ primaryColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        primaryDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        primaryDisplayTextColor: "#FFF"
      })
    }
  };
  //Secondary Color Picker
  handleClickSecondaryColor = () => {
    this.setState({ displayColorPickerSecondaryColor: !this.state.displayColorPickerSecondaryColor })
  };
  handleCloseSecondaryColor = () => {
    this.setState({ displayColorPickerSecondaryColor: false })
  };
  handleChangeSecondaryColor = (color) => {
    this.setState({ secondaryColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        secondaryDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        secondaryDisplayTextColor: "#FFF"
      })
    }
  };
  //Primary Text Color Picker
  handleClickPrimaryTextColor = () => {
    this.setState({ displayColorPickerPrimaryTextColor: !this.state.displayColorPickerPrimaryTextColor })
  };
  handleClosePrimaryTextColor = () => {
    this.setState({ displayColorPickerPrimaryTextColor: false })
  };
  handleChangePrimaryTextColor = (color) => {
    this.setState({ primaryTextColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        primaryTextDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        primaryTextDisplayTextColor: "#FFF"
      })
    }
  };
  //Secondary Text Color Picker
  handleClickSecondaryTextColor = () => {
    this.setState({ displayColorPickerSecondaryTextColor: !this.state.displayColorPickerSecondaryTextColor })
  };
  handleCloseSecondaryTextColor = () => {
    this.setState({ displayColorPickerSecondaryTextColor: false })
  };
  handleChangeSecondaryTextColor = (color) => {
    this.setState({ secondaryTextColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        secondaryTextDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        secondaryTextDisplayTextColor: "#FFF"
      })
    }
  };
  //Background Color Picker
  handleClickBackgroundColor = () => {
    this.setState({ displayColorPickerBackgroundColor: !this.state.displayColorPickerBackgroundColor })
  };
  handleCloseBackgroundColor = () => {
    this.setState({ displayColorPickerBackgroundColor: false })
  };
  handleChangeBackgroundColor = (color) => {
    this.setState({ backgroundColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        backgroundDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        backgroundDisplayTextColor: "#FFF"
      })
    }
  };
  //Border Color Picker
  handleClickBorderColor = () => {
    this.setState({ displayColorPickerBorderColor: !this.state.displayColorPickerBorderColor })
  };
  handleCloseBorderColor = () => {
    this.setState({ displayColorPickerBorderColor: false })
  };
  handleChangeBorderColor = (color) => {
    this.setState({ borderColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        borderDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        borderDisplayTextColor: "#FFF"
      })
    }
  };
  //Link Color Picker
  handleClickLinkColor = () => {
    this.setState({ displayColorPickerLinkColor: !this.state.displayColorPickerLinkColor })
  };
  handleCloseLinkColor = () => {
    this.setState({ displayColorPickerLinkColor: false })
  };
  handleChangeLinkColor = (color) => {
    this.setState({ linkColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        linkDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        linkDisplayTextColor: "#FFF"
      })
    }
  };
  //Modal Color Picker
  handleClickModalColor = () => {
    this.setState({ displayColorPickerModalColor: !this.state.displayColorPickerModalColor })
  };
  handleCloseModalColor = () => {
    this.setState({ displayColorPickerModalColor: false })
  };
  handleChangeModalColor = (color) => {
    this.setState({ modalColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        modalDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        modalDisplayTextColor: "#FFF"
      })
    }
  };
  //Table Color Picker
  handleClickTableColor = () => {
    this.setState({ displayColorPickerTableColor: !this.state.displayColorPickerTableColor })
  };
  handleCloseTableColor = () => {
    this.setState({ displayColorPickerTableColor: false })
  };
  handleChangeTableColor = (color) => {
    this.setState({ tableColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        tableDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        tableDisplayTextColor: "#FFF"
      })
    }
  };
  //Banner Color 1 Picker
  handleClickBanner1Color = () => {
    this.setState({ displayColorPickerBanner1Color: !this.state.displayColorPickerBanner1Color })
  };
  handleCloseBanner1Color = () => {
    this.setState({ displayColorPickerBanner1Color: false })
  };
  handleChangeBanner1Color = (color) => {
    this.setState({ bannerColor1: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        banner1DisplayTextColor: "#000"
      })
    } else {
      this.setState({
        banner1DisplayTextColor: "#FFF"
      })
    }
  };
  //Banner Color 2 Picker
  handleClickBanner2Color = () => {
    this.setState({ displayColorPickerBanner2Color: !this.state.displayColorPickerBanner2Color })
  };
  handleCloseBanner2Color = () => {
    this.setState({ displayColorPickerBanner2Color: false })
  };
  handleChangeBanner2Color = (color) => {
    this.setState({ bannerColor2: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        banner2DisplayTextColor: "#000"
      })
    } else {
      this.setState({
        banner2DisplayTextColor: "#FFF"
      })
    }
  };
  //Navbar Color Picker
  handleClickNavbarColor = () => {
    this.setState({ displayColorPickerNavbarColor: !this.state.displayColorPickerNavbarColor })
  };
  handleCloseNavbarColor = () => {
    this.setState({ displayColorPickerNavbarColor: false })
  };
  handleChangeNavbarColor = (color) => {
    this.setState({ navbarColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        navbarDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        navbarDisplayTextColor: "#FFF"
      })
    }
  };
  //Input Box Color Picker
  handleClickInputBoxColor = () => {
    this.setState({ displayColorPickerInputBoxColor: !this.state.displayColorPickerInputBoxColor })
  };
  handleCloseInputBoxColor = () => {
    this.setState({ displayColorPickerInputBoxColor: false })
  };
  handleChangeInputBoxColor = (color) => {
    this.setState({ inputBoxColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        inputBoxDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        inputBoxDisplayTextColor: "#FFF"
      })
    }
  };
  //Login Background Color Picker
  handleClickLoginBackgroundColor = () => {
    this.setState({ displayColorPickerLoginBackgroundColor: !this.state.displayColorPickerLoginBackgroundColor })
  };
  handleCloseLoginBackgroundColor = () => {
    this.setState({ displayColorPickerLoginBackgroundColor: false })
  };
  handleChangeLoginBackgroundColor = (color) => {
    this.setState({ loginBackgroundColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        loginBackgroundDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        loginBackgroundDisplayTextColor: "#FFF"
      })
    }
  };
  //Login Text Color Picker
  handleClickLoginTextColor = () => {
    this.setState({ displayColorPickerLoginTextColor: !this.state.displayColorPickerLoginTextColor })
  };
  handleCloseLoginTextColor = () => {
    this.setState({ displayColorPickerLoginTextColor: false })
  };
  handleChangeLoginTextColor = (color) => {
    this.setState({ loginTextColor: color.hex })
    if (color.hsl.l > 0.8) {
      this.setState({
        loginTextDisplayTextColor: "#000"
      })
    } else {
      this.setState({
        loginTextDisplayTextColor: "#FFF"
      })
    }
  };
  changeHandlerDisplayCancelMethod = (value) => {
    this.setState({ cancellationMethod: value });
  };
  toggleTab = (tab) => () => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };
  toggleTabPreview = (tab) => () => {
    if (this.state.activeItemPreview !== tab) {
      this.setState({
        activeItemPreview: tab,
      });
    }
  };
  handleFileChange = (e) => {
    console.log(e.target.files)
    this.setState({
      file: e.target.files[0],
      logoFileNameText: e.target.files[0]["name"],
      logoFileNameDesktopMobileAfterLogin: URL.createObjectURL(e.target.files[0])
    });
  };
  handleFileChangeLogoDesktop = (e) => {
    console.log(e.target.files)
    console.log("Upload Desktop Logo")
    this.setState({
      fileLogoDesktop: e.target.files[0],
      loginDesktopLogoText: e.target.files[0]["name"],
      LogoFileNameDesktopLogin: URL.createObjectURL(e.target.files[0])
    });
  };
  handleFileChangeLogoMobile = (e) => {
    console.log(e.target.files)
    this.setState({
      fileLogoMobile: e.target.files[0],
      loginMobileLogoText: e.target.files[0]["name"],
      LogoFileNameMobileLogin: URL.createObjectURL(e.target.files[0])
    });
  };
  handleFileChangeFavIcon = (e) => {
    console.log(e.target.files)
    this.setState({
      fileLogoFavIcon: e.target.files[0],
      logoFavIconText: e.target.files[0]["name"],
      logoFavIcon: URL.createObjectURL(e.target.files[0])
    });
  };
  toggleSingleCollapse = (collapseId) => () => {
    this.setState({
      [collapseId]: !this.state[collapseId],
    });
  };
  hexToRgb(hex) {
    // Convert the hex string to a 3-digit integer
    var r = parseInt(hex.substring(1, 3), 16);
    var g = parseInt(hex.substring(3, 5), 16);
    var b = parseInt(hex.substring(5, 7), 16);
    // Return the rgb object
    return {
      r: r,
      g: g,
      b: b
    };
  }
  handleSwitchChange = (nr) => () => {
    console.log(this.state.darkmodeDisplayOptions)
    let leftToggle;
    let displayMode;
    this.setState({
      darkmodeDisplayOptions: !JSON.parse(this.state.darkmodeDisplayOptions)
    });
    if (!JSON.parse(this.state.darkmodeDisplayOptions) === false) {
      leftToggle = "1px";
      displayMode = "ThemeLight";
      this.setState({ displayModePicked: "ThemeLight" });
      this.setState({ displayMode: displayMode });
    } else {
      leftToggle = "27px";
      displayMode = "ThemeDark";
      this.setState({ displayModePicked: "ThemeDark" });
      this.setState({ displayMode: displayMode });
    }
    console.log(displayMode)
    //   localStorage.setItem("darkModeDisplayOptions", !JSON.parse(this.state.darkmodeDisplayOptions));
    //   let element = document.getElementsByTagName('body')[0]
    //   element.setAttribute('data-theme', "darkModeDisplayOptions-"+!JSON.parse(this.state.darkmodeDisplayOptions));
    this.changeHandlerDisplayOptionID(this.state.displayOptionValue)
    let elementColor1 = document.getElementsByClassName('react-toggle-thumbDisplayOptionsNetwork')[0];
    elementColor1.setAttribute('style', "left:" + leftToggle);
    let elementColor2 = document.getElementsByClassName('react-toggle-thumbDisplayOptionsVenue')[0];
    elementColor2.setAttribute('style', "left:" + leftToggle);
    let elementColor3 = document.getElementsByClassName('react-toggle-thumbDisplayOptionsSubscriber')[0];
    elementColor3.setAttribute('style', "left:" + leftToggle);
    if (this.props.displayOptionID === this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayOptionID"]) {
      this.setState({
        defaultConfig: true
      })
    } else {
      this.setState({
        defaultConfig: false
      })
    }
    this.setState({
      displayOptionValue: this.state.displayOptionValue,
      displayOptionID: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayOptionID"],
      displayOptionName: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayOptionName"],
      logoFileNameText: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LogoFileNameDesktopMobileAfterLogin"],
      logoFileNameDesktopMobileAfterLogin: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LogoFileNameDesktopMobileAfterLogin"],
      s3Directory: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"]["Behavior"]["S3Directory"],
      s3DirectoryCustomComponents: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"]["Behavior"]["S3DirectoryCustomComponents"],
      primaryColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["PrimaryColorHex"],
      secondaryColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["SecondaryColorHex"],
      primaryTextColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["PrimaryTextColorHex"],
      secondaryTextColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["SecondaryTextColorHex"],
      backgroundColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["BackgroundColorHex"],
      borderColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["BorderColorHex"],
      linkColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LinkColorHex"],
      tableColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["TableColorHex"],
      bannerColor1: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["BannerColor1Hex"],
      bannerColor2: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["BannerColor2Hex"],
      navbarColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["NavbarColorHex"],
      modalColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["ModalColorHex"],
      inputBoxColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["InputBoxColorHex"],
      loginBackgroundColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LoginBackgroundColorHex"],
      loginTextColor: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LoginTextColorHex"],
      loginDesktopLogo: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LogoFileNameDesktopLogin"],
      loginDesktopLogoText: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LogoFileNameDesktopLogin"],
      loginMobileLogo: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LogoFileNameMobileLogin"],
      loginMobileLogoText: this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LogoFileNameMobileLogin"]
    });
    this.HexToHSL("primaryDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["PrimaryColorHex"]);
    this.HexToHSL("secondaryDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["SecondaryColorHex"]);
    this.HexToHSL("primaryTextDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["PrimaryTextColorHex"]);
    this.HexToHSL("secondaryTextDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["SecondaryTextColorHex"]);
    this.HexToHSL("backgroundDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["BackgroundColorHex"]);
    this.HexToHSL("borderDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["BorderColorHex"]);
    this.HexToHSL("linkDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LinkColorHex"]);
    this.HexToHSL("modalDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["ModalColorHex"]);
    this.HexToHSL("tableDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["TableColorHex"]);
    this.HexToHSL("banner1DisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["BannerColor1Hex"]);
    this.HexToHSL("banner2DisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["BannerColor2Hex"]);
    this.HexToHSL("loginBackgroundDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LoginBackgroundColorHex"]);
    this.HexToHSL("loginTextDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["LoginTextColorHex"]);
    this.HexToHSL("inputBoxDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["InputBoxColorHex"]);
    this.HexToHSL("navbarDisplayTextColor", this.props.allDisplayOptions[this.state.displayOptionValue]["DisplayConfiguration"][displayMode]["NavbarColorHex"]);
  };
  handleUpload = () => {
    // e.preventDefault();
    this.setState({ uploading: true });
    AWS.config.update({
      accessKeyId: "AKIASEI4GRAFGMRQ67XL",
      secretAccessKey: "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",
      AWS_SDK_LOAD_CONFIG: 1
    });
    var s3 = new AWS.S3({ region: "us-east-1" });
    // // AWS.config.loadFromPath('./MYPATH.json'); //this is my path to the aws credentials.
    var params = {
      Bucket: 'ui.us-east-1.wifikey.link/' + this.state.s3Directory,
      Key: this.state.file.name,
      Body: this.state.file,
      ContentType: this.state.file.type
    };
    s3.putObject(params, function (err) {
      if (err) {
        console.log(err);
      }
      else {
        console.log("Success");
        // this.props.getDisplayInfo();
        window.addEventListener("beforeunload", onConfirmRefresh, { capture: false });
      }
    });
    this.props.getDisplayInfo();
    const onConfirmRefresh = function (event) {
      event.preventDefault();
      this.setState({
        logoFileNameDesktopMobileAfterLogin: this.state.file.name
      })
      // this.props.getDisplayInfo();
      // return event.returnValue = "Are you sure you want to leave the page?";
    }
    // const s3 = new S3();
    // s3.upload({
    //   Bucket: "ui.us-east-1.wifikey.link/"+this.state.s3Directory,
    //   Key: this.state.file.name,
    //   Body: this.state.file,
    // }, (err, data) => {
    //   if (err) {
    //     console.log(err);
    //   } else {
    //     console.log("File uploaded successfully!");
    //   }
    // });
    // this.setState({ uploading: false });
  };
  toggleCheckboxDefaultConfig = () => {
    this.setState({
      defaultConfig: !this.state.defaultConfig
    })
  }
  toggleCheckboxValueShowFooter = () => {
    this.setState({
      showFooterOnLogin: !this.state.showFooterOnLogin
    });
  };
  toggleCheckboxValueAllowSelfCancel = () => {
    this.setState({
      allowSelfCancel: !this.state.allowSelfCancel
    });
  };
  toggleCheckboxValueAllowSelfPaymentUpdate = () => {
    this.setState({
      allowSelfPaymentUpdate: !this.state.allowSelfPaymentUpdate
    });
  };
  toggleCheckboxValueAllowSelfServicePlanUpdate = () => {
    this.setState({
      allowSelfServicePlanUpdate: !this.state.allowSelfServicePlanUpdate
    });
  };
  toggleCheckboxValueExternallyManagedSupport = () => {
    this.setState({
      externallyManagedSupport: !this.state.externallyManagedSupport
    });
  };
  toggleCheckboxValueShowEquipmentStatusVenueAdmin = () => {
    this.setState({
      showEquipmentStatusVenueAdmin: !this.state.showEquipmentStatusVenueAdmin
    });
  };
  toggleCheckboxValueShowSupportLinks = () => {
    this.setState({
      showSupportLinks: !this.state.showSupportLinks
    });
  };
  render() {
    let previewMessage;
    previewMessage = this.state.previewMessage !== undefined ? parse(this.state.previewMessage) : "";
    const { t } = this.props;
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${this.state.primaryColor}`,
        },
        swatch: {
          width: '100%',
          padding: '5px',
          background: `#FFF`,
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    if (this.state.primaryColor !== undefined) {
      var rgb = this.hexToRgb(this.state.primaryColor);
    }
    return (
      <>
        {
          this.props.allDisplayOptions === undefined ? (
            <div
              className="overlay"
              id="overlay"
              style={{ display: "block" }}
            >
              <div className="overlayMessage">
                <div
                  className="spinner-border spinner-border"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <MDBModalHeader toggle={this.props.toggleDisplayOptionsInfo}>
                <h4>Modify Display Options</h4>
                <p>{t("addnewresident.required")}</p>
              </MDBModalHeader>
              <MDBModalBody>
                <form className="needs-validation" onSubmit={this.submitModifyDisplayOptions} noValidate>
                  <MDBRow>
                    <MDBCol col="6" sm="12" lg="12">
                      <div>
                        <label htmlFor="defaultFormLoginSMSEx" className="black-text">
                          Display Option ID
                        </label>
                        <SelectSearch
                          id="selectOptionID"
                          search={true}
                          filterOptions={fuzzySearch}
                          options={this.props.optionTemplateDisplayOptions}
                          isOptionSelected={true}
                          classNamePrefix="language-select"
                          onChange={this.changeHandlerDisplayOptionID}
                          value={this.state.displayOptionValue}
                          placeholder="Select..."
                        />
                        <div className="invalid-feedback">Please provide a valid language.</div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBNav className="nav-tabs">
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={this.state.activeItem === "1"}
                        onClick={this.toggleTab("1")}
                        role="tab"
                      >
                        <MDBIcon icon="palette" /> Theme
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={this.state.activeItem === "2"}
                        onClick={this.toggleTab("2")}
                        role="tab"
                      >
                        <MDBIcon icon="code" /> Meta Data
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={this.state.activeItem === "3"}
                        onClick={this.toggleTab("3")}
                        role="tab"
                      >
                        <MDBIcon icon="cogs" /> Behavior
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={this.state.activeItem === "4"}
                        onClick={this.toggleTab("4")}
                        role="tab"
                      >
                        <MDBIcon icon="shopping-cart" /> Shopping Cart
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={this.state.activeItem === "5"}
                        onClick={this.toggleTab("5")}
                        role="tab"
                      >
                        <MDBIcon icon="question-circle" /> Support
                      </MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>
                  <MDBTabContent className="" activeItem={this.state.activeItem}>
                    <MDBTabPane tabId="1" role="tabpanel">
                      {this.state.displayOptionValue !== undefined ?
                        <>
                          <label
                            htmlFor="defaultFormLoginEmailEx"
                            className="black-text"
                          >
                            Display Option ID Name
                          </label>
                          <input
                            required
                            name="displayOptionName"
                            id="displayOptionName"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.displayOptionName}
                            disabled={this.state.disabled}
                          />
                          <MDBInput
                            label="Default Configuration"
                            type="checkbox"
                            id="defaultConfig"
                            name="defaultConfig"
                            onChange={this.toggleCheckboxDefaultConfig}
                            checked={this.state.defaultConfig}
                          >
                          </MDBInput>
                          <label
                            htmlFor="defaultFormLoginEmailEx"
                            className="black-text"
                          >
                            S3 Directory
                          </label>
                          <input
                            required
                            name="s3Directory"
                            id="s3Directory"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.s3Directory}
                            disabled={this.state.disabled}
                          />
                          <label
                            htmlFor="defaultFormLoginEmailEx"
                            className="black-text"
                          >
                            S3 Directory Custom Components
                          </label>
                          <input
                            required
                            name="s3DirectoryCustomComponents"
                            id="s3DirectoryCustomComponents"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.s3DirectoryCustomComponents}
                            disabled={this.state.disabled}
                          />
                          <hr />
                          <h5 className="font-weight-bold pl-0 mt-2 mb-3">
                            <strong>Mode</strong>
                          </h5>
                          <MDBRow>
                            <MDBCol col="6" sm="12" lg="12">
                              <div>
                                <SelectSearch
                                  id="selectMode"
                                  options={this.state.modeOptions}
                                  isOptionSelected={true}
                                  classNamePrefix="language-select"
                                  onChange={this.handleSwitchChange(1)}
                                  value={this.state.displayMode}
                                />
                                <div className="invalid-feedback">Please provide a valid language.</div>
                              </div>
                            </MDBCol>
                          </MDBRow>
                          <hr/>
                          <h5 className="font-weight-bold pl-0 mt-2 mb-1">
                            <strong>Logos</strong>
                          </h5>
                          <MDBRow>
                            <MDBCol col="6" sm="12" lg="4">
                              <div>
                                <label
                                  htmlFor="defaultFormLoginEmailEx"
                                  className="black-text"
                                >
                                  <strong>Dashboard Logo</strong>
                                </label>
                                <br />
                                <div 
                                  className="Logo Logo-filename"
                                  style={{
                                    backgroundColor: `${this.state.backgroundColor}`,
                                    padding: "10px"
                                  }}
                                >
                                  <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFileNameText} alt="Logo" />
                                  {/* {this.state.file !== undefined ?
                              <img src={this.state.logoFileNameDesktopMobileAfterLogin} alt="Logo" /> :
                              <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFileNameDesktopMobileAfterLogin} alt="Logo" />
                            } */}
                                </div>
                              </div>
                              <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                              >
                                File Name
                              </label>
                              <input
                                required
                                name="logoFileNameText"
                                id="logoFileNameText"
                                className="form-control"
                                onChange={this.changeHandler}
                                value={this.state.logoFileNameText}
                                disabled={this.state.disabled}
                              />
                              {/* 
                          File Name: {this.state.logoFileNameText}
                          <div>
                            <label
                              for="file-upload"
                              class="custom-file-upload"
                              style={{ '--primary-text-color': "#FFF" }}
                            >
                              <MDBIcon icon="cloud-upload-alt" /> Custom Upload
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={this.handleFileChange}
                            />
                            <br /><br />
                          </div> */}
                            </MDBCol>
                            <MDBCol col="6" sm="12" lg="4">
                              <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                              >
                                <strong>Desktop Login Logo</strong>
                              </label>
                              <br />
                              <div 
                                className="Logo Logo-filename"
                                style={{
                                  padding: "10px"
                                }}
                              >
                                <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.loginDesktopLogoText} alt="Logo" />
                                {/* {this.state.fileLogoDesktop !== undefined ?
                              <img src={this.state.LogoFileNameDesktopLogin} alt="Logo" /> :
                              <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.loginDesktopLogo} alt="Logo" />
                            } */}
                              </div>
                              <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                              >
                                File Name
                              </label>
                              <input
                                required
                                name="loginDesktopLogoText"
                                id="loginDesktopLogoText"
                                className="form-control"
                                onChange={this.changeHandler}
                                value={this.state.loginDesktopLogoText}
                                disabled={this.state.disabled}
                              />
                              {/* 
                          File Name: {this.state.loginDesktopLogoText}
                          <div>
                            <label
                              for="file-upload-desktop-logo"
                              class="custom-file-upload"
                              style={{ '--primary-text-color': "#FFF" }}
                            >
                              <MDBIcon icon="cloud-upload-alt" /> Custom Upload
                            </label>
                            <input
                              id="file-upload-desktop-logo"
                              type="file"
                              onChange={this.handleFileChangeLogoDesktop}
                            />
                            <br /><br />
                          </div> */}
                            </MDBCol>
                            <MDBCol col="6" sm="12" lg="4">
                              <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                              >
                                <strong>Mobile Login Logo</strong>
                              </label>
                              <br />
                              <div 
                                className="Logo Logo-filename"
                                style={{
                                  padding: "10px"
                                }}
                              >
                                <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.loginMobileLogoText} alt="Logo" />
                                {/* {this.state.fileLogoMobile !== undefined ?
                              <img src={this.state.LogoFileNameMobileLogin} alt="Logo" /> :
                              <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.loginMobileLogo} alt="Logo" />
                            } */}
                              </div>
                              <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                              >
                                File Name
                              </label>
                              <input
                                required
                                name="loginMobileLogoText"
                                id="loginMobileLogoText"
                                className="form-control"
                                onChange={this.changeHandler}
                                value={this.state.loginMobileLogoText}
                                disabled={this.state.disabled}
                              />
                              {/* 
                          File Name: {this.state.loginMobileLogoText}<div>
                            <label
                              for="file-upload-mobile-logo"
                              class="custom-file-upload"
                              style={{ '--primary-text-color': "#FFF" }}
                            >
                              <MDBIcon icon="cloud-upload-alt" /> Custom Upload
                            </label>
                            <input
                              id="file-upload-mobile-logo"
                              type="file"
                              onChange={this.handleFileChangeLogoMobile}
                            />
                            <br /><br />
                          </div> */}
                            </MDBCol>
                          </MDBRow>
                          <hr/>
                                <h5 className="font-weight-bold pl-0 mt-2 mb-1">
                                  <strong>Colors</strong>
                                </h5>
                          <MDBRow>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Primary
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickPrimaryColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.primaryColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.primaryDisplayTextColor}` }}>{this.state.primaryColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerPrimaryColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleClosePrimaryColor} />
                                <ChromePicker color={this.state.primaryColor} onChange={this.handleChangePrimaryColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Secondary
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickSecondaryColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.secondaryColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.secondaryDisplayTextColor}` }}>{this.state.secondaryColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerSecondaryColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseSecondaryColor} />
                                <ChromePicker color={this.state.secondaryColor} onChange={this.handleChangeSecondaryColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Primary Text
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickPrimaryTextColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.primaryTextColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.primaryTextDisplayTextColor}` }}>{this.state.primaryTextColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerPrimaryTextColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleClosePrimaryTextColor} />
                                <ChromePicker color={this.state.primaryTextDisplayTextColor} onChange={this.handleChangePrimaryTextColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Secondary Text
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickSecondaryTextColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.secondaryTextColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.secondaryTextDisplayTextColor}` }}>{this.state.secondaryTextColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerSecondaryTextColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseSecondaryTextColor} />
                                <ChromePicker color={this.state.secondaryTextColor} onChange={this.handleChangeSecondaryTextColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Background
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickBackgroundColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.backgroundColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.backgroundDisplayTextColor}` }}>{this.state.backgroundColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerBackgroundColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseBackgroundColor} />
                                <ChromePicker color={this.state.backgroundColor} onChange={this.handleChangeBackgroundColor} />
                              </div> : null}
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Navbar
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickNavbarColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.navbarColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: `${this.state.navbarTextColor}`,
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.navbarDisplayTextColor}` }}>{this.state.navbarColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerNavbarColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseNavbarColor} />
                                <ChromePicker color={this.state.navbarColor} onChange={this.handleChangeNavbarColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Links
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickLinkColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.linkColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.linkDisplayTextColor}` }}>{this.state.linkColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerLinkColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseLinkColor} />
                                <ChromePicker color={this.state.linkColor} onChange={this.handleChangeLinkColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Modal Background
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickModalColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.modalColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.modalDisplayTextColor}` }}>{this.state.modalColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerModalColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseModalColor} />
                                <ChromePicker color={this.state.modalColor} onChange={this.handleChangeModalColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Table Headers
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickTableColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.tableColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.tableDisplayTextColor}` }}>{this.state.tableColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerTableColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseTableColor} />
                                <ChromePicker color={this.state.tableColor} onChange={this.handleChangeTableColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Input Box Background
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickInputBoxColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.inputBoxColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.inputBoxDisplayTextColor}` }}>{this.state.inputBoxColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerInputBoxColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseInputBoxColor} />
                                <ChromePicker color={this.state.inputBoxColor} onChange={this.handleChangeInputBoxColor} />
                              </div> : null}
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Borders
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickBorderColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.borderColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.borderDisplayTextColor}` }}>{this.state.borderColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerBorderColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseBorderColor} />
                                <ChromePicker color={this.state.borderColor} onChange={this.handleChangeBorderColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Login Page Background
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickLoginBackgroundColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.loginBackgroundColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.loginBackgroundDisplayTextColor}` }}>{this.state.loginBackgroundColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerLoginBackgroundColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseLoginBackgroundColor} />
                                <ChromePicker color={this.state.loginBackgroundColor} onChange={this.handleChangeLoginBackgroundColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Login Page Text
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickLoginTextColor}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.loginTextColor}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.loginTextDisplayTextColor}` }}>{this.state.loginTextColor}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerLoginTextColor ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseLoginTextColor} />
                                <ChromePicker color={this.state.loginTextColor} onChange={this.handleChangeLoginTextColor} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Banner Color 1
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickBanner1Color}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.bannerColor1}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.banner1DisplayTextColor}` }}>{this.state.bannerColor1}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerBanner1Color ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseBanner1Color} />
                                <ChromePicker color={this.state.bannerColor1} onChange={this.handleChangeBanner1Color} />
                              </div> : null}
                            </MDBCol>
                            <MDBCol>
                              <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                                Banner Color 2
                              </label><br />
                              <div style={styles.swatch} onClick={this.handleClickBanner2Color}>
                                <div style={{
                                  width: '100%',
                                  height: '50px',
                                  borderRadius: '2px',
                                  backgroundColor: `${this.state.bannerColor2}`, display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: "#FFF",
                                  fontSize: "20px"
                                }} >
                                  <p style={{ color: `${this.state.banner2DisplayTextColor}` }}>{this.state.bannerColor2}</p>
                                </div>
                              </div>
                              {this.state.displayColorPickerBanner2Color ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleCloseBanner2Color} />
                                <ChromePicker color={this.state.bannerColor2} onChange={this.handleChangeBanner2Color} />
                              </div> : null}
                            </MDBCol>
                          </MDBRow>
                          <br />
                          <hr/>
                                <h5 className="font-weight-bold pl-0 mt-2 mb-3">
                                  <strong>Preview</strong>
                                </h5>
                          {/* <label
                            htmlFor="defaultFormLoginEmailEx"
                            className="black-text"
                          >
                            Preview
                          </label> */}
                          <MDBNav className="nav-tabs">
                            {this.state.defaultConfig ?
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to="#"
                                  active={this.state.activeItemPreview === "6"}
                                  onClick={this.toggleTabPreview("6")}
                                  role="tab"
                                >
                                  <MDBIcon icon="palette" /> Network Op Dashboard
                                </MDBNavLink>
                              </MDBNavItem>
                              : ""
                            }
                            <MDBNavItem>
                              <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItemPreview === "7"}
                                onClick={this.toggleTabPreview("7")}
                                role="tab"
                              >
                                <MDBIcon icon="code" /> Venue Admin Dashboad
                              </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                              <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItemPreview === "8"}
                                onClick={this.toggleTabPreview("8")}
                                role="tab"
                              >
                                <MDBIcon icon="cogs" /> Subscriber Dashboard
                              </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                              <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItemPreview === "9"}
                                onClick={this.toggleTabPreview("9")}
                                role="tab"
                              >
                                <MDBIcon icon="shopping-cart" /> Login Page
                              </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                              <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItemPreview === "10"}
                                onClick={this.toggleTabPreview("10")}
                                role="tab"
                              >
                                <MDBIcon icon="shopping-cart" /> Modals
                              </MDBNavLink>
                            </MDBNavItem>
                          </MDBNav>
                          <MDBTabContent className="" activeItem={this.state.activeItemPreview}>
                            <MDBTabPane tabId="6" role="tabpanel">
                              <div className="networkManagement displayPreview">
                                <MDBNavbar
                                  color=""
                                  dark
                                  expand="md"
                                  style={{ "--navbar-color": this.state.navbarColor }}
                                >
                                  <div className="Logo first-step">
                                    <img
                                      id="logo"
                                      src={
                                        window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFileNameText
                                      }
                                      // src={this.state.file !== undefined ?
                                      //   this.state.logoFileNameDesktopMobileAfterLogin :
                                      //   window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFileNameDesktopMobileAfterLogin
                                      // }
                                      alt="Logo"
                                    />
                                  </div>
                                  <MDBNavbarNav>
                                    <div className="mainNavigation">
                                      <>
                                        <MDBNavItem>
                                          <MDBNavLink
                                            to="#"
                                            className="firstNav bold"
                                            style={{ color: this.state.primaryTextColor, '--base-color': this.state.primaryColor }}
                                          >
                                            Menu Item
                                          </MDBNavLink>
                                        </MDBNavItem>
                                      </>
                                      <div className="account">
                                        <div class="darkModeToggle">
                                          <label
                                            style={{ "marginTop": "0px" }}
                                          >
                                            <div
                                              class="react-toggle react-toggle--checked colorMode"
                                            >
                                              <div
                                                class="react-toggle-track"
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <div class="react-toggle-track-check">
                                                  <i data-test="fa" class="fa fa-sun"></i>
                                                </div>
                                                <div
                                                  class="react-toggle-track-x"
                                                  style={{ opacity: '1' }}
                                                >
                                                  <i data-test="fa" class="fa fa-moon"></i>
                                                </div>
                                              </div>
                                              <div class="react-toggle-thumbDisplayOptionsNetwork"></div>
                                              <input
                                                class="react-toggle-screenreader-only"
                                                type="checkbox"
                                                checked=""
                                              // onClick={this.handleSwitchChange(1)}
                                              />
                                            </div>
                                          </label>
                                        </div>
                                        <div className="accountLogout">
                                          <div className="select"><select className="">
                                            <option value="en-US">English</option>
                                            <option value="es-SP">Spanish</option>
                                          </select></div>
                                          <MDBDropdown
                                            style={{ '--base-color': this.state.primaryColor }}
                                          >
                                            <MDBDropdownToggle className="customClass eleventh-step twelveth-step" nav>
                                              <MDBIcon
                                                icon="user-circle"
                                                style={{ '--navbar-text-color': this.state.primaryTextColor }}
                                              />
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu right>
                                              <MDBDropdownItem
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >Welcome, {sessionStorage.getItem("firstName")}</MDBDropdownItem>
                                              <MDBDropdownItem divider />
                                              <MDBDropdownItem
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >My Account</MDBDropdownItem>
                                              <MDBDropdownItem
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >Reset Password</MDBDropdownItem>
                                              <div className="twelveth-step">
                                                <MDBDropdownItem style={{ '--base-color': this.state.primaryColor }}>
                                                  <MDBNavLink to="/networkdashboard" className="accountSwitch" style={{ '--base-color': "#FFF" }}>{t("subnavigation.networkadmin")}</MDBNavLink>
                                                </MDBDropdownItem>
                                                <MDBDropdownItem style={{ '--base-color': this.state.primaryColor }}>
                                                  <MDBNavLink to="/myaccount" className="accountSwitch" style={{ '--base-color': "#FFF" }}>
                                                    Subscriber Account
                                                  </MDBNavLink>
                                                </MDBDropdownItem>
                                              </div>
                                            </MDBDropdownMenu>
                                          </MDBDropdown>
                                          <li data-test="nav-item" className="nav-item"><a aria-current="page"
                                            className="nav-link Ripple-parent logout active" data-test="nav-link" href="/" style={{ color: this.state.primaryTextColor, '--base-color': this.state.primaryColor }}>Logout<div
                                              data-test="waves" className="Ripple "
                                              style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}></div></a></li>
                                        </div>
                                      </div>
                                    </div>
                                  </MDBNavbarNav>
                                </MDBNavbar>
                                <div
                                  className="mainDashboard"
                                  style={{
                                    '--background-color': this.state.backgroundColor,
                                    'padding': '20px'
                                  }}
                                >
                                  <MDBRow className="dashboardRow">
                                    <MDBCol
                                      md="4"
                                      className="summaryCards"
                                    >
                                      <MDBCard
                                        style={{ "--card-color": this.state.navbarColor }}
                                      >
                                        <MDBCardBody>
                                          <div
                                            className="summaryCard"
                                          >
                                            <a href="#link">
                                              <h2 style={{ '--primary-text-color': this.state.primaryTextColor }}>Summary Card</h2>
                                            </a>
                                            <div
                                              className="residentSummary rounded"
                                              style={{ background: this.state.primaryColor }}
                                            >
                                              <MDBRow>
                                                <MDBCol md="9">
                                                  <a
                                                    href="#link"
                                                  >
                                                    <p>Venues</p>
                                                  </a>
                                                </MDBCol>
                                                <MDBCol md="3">
                                                  <a
                                                    href="#link"
                                                  >
                                                    {" "}
                                                    <p>#</p>
                                                  </a>
                                                </MDBCol>
                                              </MDBRow>
                                            </div></div>
                                        </MDBCardBody>
                                      </MDBCard>
                                      <MDBCard
                                        style={{ "--card-color": this.state.navbarColor }}
                                      >
                                        <MDBCardBody>
                                          <div className="summaryCard">
                                            <a href="#link">
                                              <h2 style={{ '--primary-text-color': this.state.primaryTextColor }}>Subscribers</h2>
                                            </a>
                                            <div className="support rounded">
                                              <MDBRow>
                                                <MDBCol md="9">
                                                  <a
                                                    href="#link"
                                                  >
                                                    <p>Subscribers</p>
                                                  </a>
                                                </MDBCol>
                                                <MDBCol md="3">
                                                  <a
                                                    href="#link"
                                                  >
                                                    {" "}
                                                    <p>#</p>
                                                  </a>
                                                </MDBCol>
                                              </MDBRow>
                                            </div></div>
                                        </MDBCardBody>
                                      </MDBCard>
                                    </MDBCol>
                                    <MDBCol
                                      md="8"
                                    >
                                      <div className="quicklinks">
                                        <MDBRow>
                                          <MDBCol>
                                            <div
                                              className="quicklink"
                                              style={{ '--base-color': this.state.primaryColor }}
                                            >
                                              <MDBLink
                                                to="#"
                                                onClick={this.toggleAddNewSubscriber}
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <MDBIcon icon="user-plus" />
                                                <p
                                                  className=""
                                                  style={{ "--link-color": this.state.linkColor }}
                                                >Add New Subscriber</p>
                                              </MDBLink>
                                            </div>
                                          </MDBCol>
                                          <MDBCol>
                                            <div
                                              className="quicklink"
                                              style={{ '--base-color': this.state.primaryColor }}
                                            >
                                              <MDBLink
                                                to="#"
                                                onClick={this.toggleAddNewVenue}
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <MDBIcon icon="stopwatch" />
                                                <p
                                                  className=""
                                                  style={{ "--link-color": this.state.linkColor }}
                                                >
                                                  Add New
                                                  <br />
                                                  Venue
                                                </p>
                                              </MDBLink>
                                            </div>
                                          </MDBCol>
                                          <MDBCol>
                                            <div
                                              className="quicklink"
                                              style={{ '--base-color': this.state.primaryColor }}
                                            >
                                              <MDBLink
                                                to="#"
                                                onClick={this.toggleAddUnit}
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <MDBIcon icon="plus-circle" />
                                                <p
                                                  className=""
                                                  style={{ "--link-color": this.state.linkColor }}
                                                >
                                                  Add New
                                                  <br />
                                                  Unit
                                                </p>
                                              </MDBLink>
                                            </div>
                                          </MDBCol>
                                          <MDBCol>
                                            <div
                                              className="quicklink"
                                              style={{ '--base-color': this.state.primaryColor }}
                                            >
                                              <MDBLink
                                                to="#"
                                                onClick={this.toggleAddDomainAdministrator}
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <MDBIcon icon="edit" />
                                                <p
                                                  className=""
                                                  style={{ "--link-color": this.state.linkColor }}
                                                >
                                                  Add
                                                  <br />
                                                  Administrator
                                                </p>
                                              </MDBLink>
                                            </div>
                                          </MDBCol>
                                        </MDBRow>
                                      </div>
                                      <MDBCard
                                        style={{ "--card-color": this.state.navbarColor }}
                                      >
                                        <MDBCardBody>
                                          <h2
                                            style={{ '--primary-text-color': this.state.primaryTextColor }}
                                          >Venues</h2>
                                          <div className="expandCollapse"><p>Show More +</p></div><div className="allVenues"><div data-test="datatable" className="dt-bootstrap4 recentResidents"><div className="row ml-1 justify-content-between"><div data-test="datatable-search" className="false"><div data-test="datatable-input" className="mdb-datatable-filter flex-row">
                                            <input 
                                              className="form-control form-control-sm ml-0 my-1" 
                                              type="text" 
                                              placeholder="Filter" 
                                              aria-label="Search" 
                                              value="Filter" 
                                              style={{
                                                backgroundColor: `${this.state.inputBoxColor}`,
                                                color: `${this.state.primaryTextColor}`
                                              }}
                                            />
                                            </div></div></div><div className="row"><div data-test="mdb-datatable-table" className="col-sm-12"><div data-test="table" className="table-responsive"><table entries="10" className="table table-borderless table-hover"
                                            style={{ "--primary-text-color": this.state.linkColor }}
                                          >
                                            <thead
                                              data-test="datatable-head" className="mdb-dataTable-head"
                                              style={{ '--base-color': this.state.primaryColor }}
                                            ><tr><th className="sorting">Venue</th><th className="sorting">Address</th><th className="sorting">Unit Count</th><th className="sorting">Policy Count</th><th className="sorting">Subscriber Count</th><th className="sorting">Device Count</th></tr></thead>
                                            <tbody 
                                              data-test="table-body"
                                              style={{
                                                backgroundColor: `${this.state.inputBoxColor}`+'!important',
                                                color: `${this.state.primaryTextColor}`
                                              }}
                                            >
                                              <tr><td>1001 South Capital</td><td>1000 South St.
                                              Seekonk, MA 02881</td><td>262</td><td>6</td><td>6</td><td>0</td></tr></tbody></table></div></div></div></div></div>
                                        </MDBCardBody>
                                      </MDBCard>
                                    </MDBCol>
                                  </MDBRow>
                                </div>
                              </div>
                            </MDBTabPane>
                            <MDBTabPane tabId="7" role="tabpanel">
                              <div id="venueManagement" className="displayPreview">
                                <nav
                                  data-test="navbar"
                                  className="navbar-dark navbar navbar-expand-md"
                                  role="navigation"
                                  style={{ "--navbar-color": this.state.navbarColor }}
                                >
                                  <div
                                    className="Logo first-step"
                                  >
                                    <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFileNameText} alt="Logo" />
                                    {/* {this.state.file !== undefined ?
                                      <img src={this.state.logoFileNameDesktopMobileAfterLogin} alt="Logo" /> :
                                      <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFileNameDesktopMobileAfterLogin} alt="Logo" />
                                    } */}
                                  </div>
                                  <ul data-test="navbar-nav" className="navbar-nav justify-content-around w-100">
                                    <div className="venue" style={{ display: "block" }}>
                                      <div className="venueName">
                                        <div id="selectVenue" className="select-wrapper md-form" style={{ width: "190px" }}><span
                                          className="caret">▼</span>
                                          <input
                                            type="text"
                                            readonly=""
                                            className="select-dropdown selectInput venueSelected"
                                            value="1001 South Capital"
                                            style={{ "color": this.state.primaryColor }}
                                          />
                                          <ul
                                            className="dropdown-content select-dropdown fadeElement"
                                          >
                                            <div className="mx-2">
                                              <div className="md-form"><input data-test="input" data-search="true" type="text"
                                                className="form-control" id="selectSearchInput" aria-disabled="false"
                                                value="" /><label className="" for="selectSearchInput" data-error="" data-success=""
                                                  id="" aria-labelledby="">Search</label></div>
                                            </div>
                                            <li context="[object Object]" data-multiple="false"
                                              className="justify-content-between align-items-center active" style={{ display: "flex;" }}><span
                                                data-multiple="false" className="filtrable" style={{ flex: "1 1 0%;" }}>1001 South
                                                Capital</span></li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="address"
                                        style={{ '--navbar-text-color': this.state.primaryTextColor }}
                                      >
                                        <p>1000 South St.</p>
                                        <p></p>
                                        <p>Seekonk, MA 02881</p>
                                      </div>
                                    </div>
                                    <div className="mainNavigation">
                                      <li data-test="nav-item" className="nav-item seventh-step">
                                        <a
                                          aria-current="page"
                                          className="nav-link Ripple-parent firstNav residentModalLink active bold"
                                          data-test="nav-link"
                                          href="#"
                                          style={{ '--navbar-text-color': this.state.primaryTextColor, '--base-color': this.state.primaryColor }}
                                        >Residents<div data-test="waves" className="Ripple "
                                          style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}></div></a></li>
                                      <li data-test="nav-item" className="nav-item eighth-step">
                                        <a
                                          aria-current="page"
                                          className="nav-link Ripple-parent active bold"
                                          data-test="nav-link"
                                          href="#"
                                          style={{ color: this.state.primaryTextColor, '--base-color': this.state.primaryColor }}
                                        >Guests<div
                                          data-test="waves" className="Ripple " style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}>
                                          </div></a></li>
                                      <li data-test="nav-item" className="nav-item ninth-step">
                                        <a
                                          aria-current="page"
                                          className="nav-link Ripple-parent active bold"
                                          data-test="nav-link"
                                          href="#"
                                          style={{ color: this.state.primaryTextColor, '--base-color': this.state.primaryColor }}
                                        >Support<div
                                          data-test="waves" className="Ripple " style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}>
                                          </div></a></li>
                                      <li data-test="nav-item" className="nav-item tenth-step">
                                        <a
                                          aria-current="page"
                                          className="nav-link Ripple-parent active bold"
                                          data-test="nav-link"
                                          href="#"
                                          style={{ color: this.state.primaryTextColor, '--base-color': this.state.primaryColor }}
                                        >
                                          Settings<div
                                            data-test="waves" className="Ripple " style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}>
                                          </div></a></li>
                                      <div className="account">
                                        <div className="darkModeToggle">
                                          <label
                                            style={{ "marginTop": "0px" }}
                                          >
                                            <div
                                              class="react-toggle react-toggle--checked colorMode"
                                            >
                                              <div
                                                class="react-toggle-track"
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <div class="react-toggle-track-check">
                                                  <i data-test="fa" class="fa fa-sun"></i>
                                                </div>
                                                <div
                                                  class="react-toggle-track-x"
                                                  style={{ opacity: '1' }}
                                                >
                                                  <i data-test="fa" class="fa fa-moon"></i>
                                                </div>
                                              </div>
                                              <div class="react-toggle-thumbDisplayOptionsVenue"></div>
                                              <input
                                                class="react-toggle-screenreader-only"
                                                type="checkbox"
                                                checked=""
                                              // onClick={this.handleSwitchChange(1)}
                                              />
                                            </div>
                                          </label>
                                        </div>
                                        <div className="accountLogout">
                                          <div className="select"><select className="">
                                            <option value="en-US">English</option>
                                            <option value="es-SP">Spanish</option>
                                          </select></div>
                                          <MDBDropdown
                                            style={{ '--base-color': this.state.primaryColor }}
                                          >
                                            <MDBDropdownToggle className="customClass eleventh-step twelveth-step" nav>
                                              <MDBIcon
                                                icon="user-circle"
                                                style={{ '--navbar-text-color': this.state.primaryTextColor }}
                                              />
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu
                                              style={{ '--navbar-text-color': this.state.primaryTextColor }}
                                              right>
                                              <MDBDropdownItem
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >Welcome, {sessionStorage.getItem("firstName")}</MDBDropdownItem>
                                              <MDBDropdownItem divider />
                                              <MDBDropdownItem
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >My Account</MDBDropdownItem>
                                              <MDBDropdownItem
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >Reset Password</MDBDropdownItem>
                                              <div className="twelveth-step">
                                                <MDBDropdownItem style={{ '--base-color': this.state.primaryColor }}>
                                                  <MDBNavLink to="/networkdashboard" className="accountSwitch" style={{ '--base-color': "#FFF" }}>{t("subnavigation.networkadmin")}</MDBNavLink>
                                                </MDBDropdownItem>
                                                <MDBDropdownItem style={{ '--base-color': this.state.primaryColor }}>
                                                  <MDBNavLink to="/myaccount" className="accountSwitch" style={{ '--base-color': "#FFF" }}>
                                                    Subscriber Account
                                                  </MDBNavLink>
                                                </MDBDropdownItem>
                                              </div>
                                            </MDBDropdownMenu>
                                          </MDBDropdown>
                                          <li data-test="nav-item" className="nav-item">
                                            <a
                                              aria-current="page"
                                              className="nav-link Ripple-parent logout active"
                                              data-test="nav-link"
                                              href="#"
                                              style={{ '--navbar-text-color': this.state.primaryTextColor }}
                                            >Logout<div
                                              data-test="waves" className="Ripple "
                                              style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}></div></a></li>
                                        </div>
                                      </div>
                                    </div>
                                  </ul>
                                </nav>
                                <div id="overlayResidentManagement">
                                  <div id="overlayMessage" className="overlayMessage"></div>
                                </div>
                                <div
                                  className="mainDashboard"
                                  style={{
                                    '--background-color': this.state.backgroundColor,
                                    'padding': '20px'
                                  }}
                                >
                                  <MDBRow>
                                    <MDBCol className="summaryCards md-4" md="4">
                                      <div
                                        data-test="card"
                                        className="card"
                                        style={{ "--card-color": this.state.navbarColor }}
                                      >
                                        <div data-test="card-body" className="card-body">
                                          <div className="summaryCard">
                                            <h2
                                              style={{ '--primary-text-color': this.state.primaryTextColor }}
                                            >Activity Summary</h2>
                                            <div className="actionSummary rounded second-step">
                                              <div data-test="row" className="row">
                                                <div data-test="col" className="col-sm-9 col-md-9"><a href="#actionSummary">
                                                  <p>New Residents Today</p>
                                                </a></div>
                                                <div data-test="col" className="col-sm-9 col-md-3"><a href="#actionSummary">
                                                  <p>0</p>
                                                </a></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        data-test="card"
                                        className="card"
                                        style={{ "--card-color": this.state.navbarColor }}
                                      >
                                        <div data-test="card-body" className="card-body">
                                          <div>
                                            <div className="summaryCard"><a href="#residentSummary">
                                              <h2
                                                style={{ '--primary-text-color': this.state.primaryTextColor }}
                                              >Service Status</h2>
                                            </a>
                                              <div
                                                className="residentSummary rounded third-step"
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <div data-test="row" className="row">
                                                  <div data-test="col" className="col-md-9"><a href="#residentSummary">
                                                    <p>Assigned Units</p>
                                                  </a></div>
                                                  <div data-test="col" className="col-md-3"><a href="#residentSummary">
                                                    <p>6</p>
                                                  </a></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        data-test="card"
                                        className="card"
                                        style={{ "--card-color": this.state.navbarColor }}
                                      >
                                        <div data-test="card-body" className="card-body">
                                          <div>
                                            <div className="summaryCard"><a href="#support">
                                              <h2
                                                style={{ '--primary-text-color': this.state.primaryTextColor }}
                                              >Support Summary</h2>
                                            </a>
                                              <div className="summaryCard support-step">
                                                <div className="support rounded">
                                                  <div data-test="row" className="row">
                                                    <div data-test="col" className="col-md-9"><a href="#support">
                                                      <p>Open Tickets</p>
                                                    </a></div>
                                                    <div data-test="col" className="col-md-3"><a href="#support">
                                                      <p>0</p>
                                                    </a></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </MDBCol>
                                    <MDBCol className="md-8" md="8">
                                      <div className="fourth-step">
                                        <div className="ui grid">
                                          <p
                                            style={{ '--primary-text-color': this.state.primaryTextColor }}
                                          >Quick Search</p>
                                          <div className="sixteen wide column">
                                            <div className="ui category search quickSearch">
                                              <div className="ui icon input"
                                              ><input
                                                  autocomplete="off"
                                                  placeholder="Resident Name, Unit ID or Email"
                                                  type="text"
                                                  tabindex="0"
                                                  className="prompt"
                                                  value=""
                                                  style={{
                                                    "--input-color": this.state.inputBoxColor,
                                                    '--primary-text-color': this.state.primaryTextColor
                                                  }}
                                                /><i aria-hidden="true" className="search icon"></i></div>
                                              <div className="results transition">
                                                <div className="message empty">
                                                  <div className="header">No results found.</div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="quicklinks">
                                          <div data-test="row" className="row">
                                            <div data-test="col" className="col">
                                              <div
                                                className="quicklink"
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <a
                                                  data-test="link-router"
                                                  className="nav-link Ripple-parent"
                                                  href="#"
                                                  style={{ '--base-color': this.state.primaryColor, '--navbar-text-color': this.state.PrimaryColorHex }}
                                                ><i data-test="fa" className="fa fa-user-plus"></i>
                                                  <p
                                                    className=""
                                                    style={{ '--link-color': this.state.primaryTextColor }}
                                                  >Add
                                                    New Resident</p>
                                                  <div data-test="waves" className="Ripple "
                                                    style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}></div>
                                                </a></div>
                                            </div>
                                            <div data-test="col" className="col">
                                              <div
                                                className="quicklink"
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <a
                                                  data-test="link-router"
                                                  className="nav-link Ripple-parent"
                                                  href="#"
                                                  style={{ '--base-color': this.state.primaryColor }}
                                                ><i data-test="fa" className="fa fa-stopwatch"></i>
                                                  <p className=""
                                                    style={{ '--link-color': this.state.primaryTextColor }}
                                                  >Add
                                                    New Guest</p>
                                                  <div data-test="waves" className="Ripple "
                                                    style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}></div>
                                                </a></div>
                                            </div>
                                            <div data-test="col" className="col">
                                              <div
                                                className="quicklink"
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <a
                                                  data-test="link-router"
                                                  className="nav-link Ripple-parent"
                                                  href="#"
                                                  style={{ '--base-color': this.state.primaryColor }}
                                                ><i data-test="fa" className="fa fa-edit"></i>
                                                  <p className=""
                                                    style={{ '--link-color': this.state.primaryTextColor }}
                                                  >Add/Modify
                                                    Staff</p>
                                                  <div data-test="waves" className="Ripple "
                                                    style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}></div>
                                                </a></div>
                                            </div>
                                            <div data-test="col" className="col">
                                              <div
                                                className="quicklink"
                                                style={{ '--base-color': this.state.primaryColor }}
                                              >
                                                <a
                                                  data-test="link-router"
                                                  className="nav-link Ripple-parent"
                                                  href="#"
                                                  style={{ '--base-color': this.state.primaryColor }}
                                                ><i data-test="fa" className="fa fa-envelope"></i>
                                                  <p className=""
                                                    style={{ '--link-color': this.state.primaryTextColor }}
                                                  >Send<br /> Message</p>
                                                  <div data-test="waves" className="Ripple "
                                                    style={{ top: "0px;", left: "0px;", width: "0px;", height: "0px;" }}></div>
                                                </a></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        data-test="card"
                                        className="card"
                                        style={{ "--card-color": this.state.navbarColor }}
                                      >
                                        <div data-test="card-body" className="card-body">
                                          <h2
                                            style={{ '--primary-text-color': this.state.primaryTextColor }}
                                          >Recent Service Activations</h2>
                                          <div className="expandCollapse">
                                            <p>Show More +</p>
                                          </div>
                                          <div className="recentresidents">
                                            <div data-test="datatable" className="mdb-datatable dt-bootstrap4 recentResidents">
                                              <div className="row ml-1 justify-content-between">
                                                <div data-test="datatable-search" className="false"
                                                  style={{ display: "flex;", alignItems: "center;" }}>
                                                  <div data-test="datatable-input" className="mdb-datatable-filter flex-row">
                                                    <input className="form-control form-control-sm ml-0 my-1" type="text"
                                                      placeholder="Filter" aria-label="Search" value="" /></div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div data-test="mdb-datatable-table" className="col-sm-12">
                                                  <div data-test="table" className="table-responsive">
                                                    <table entries="10"
                                                      className="table table-borderless table-hover mdb-dataTable"
                                                      style={{ '--primary-text-color': this.state.primaryTextColor }}
                                                    >
                                                      <thead
                                                        data-test="datatable-head"
                                                        className="mdb-dataTable-head"
                                                        style={{ '--base-color': this.state.primaryColor }}
                                                      >
                                                        <tr>
                                                          <th className="sorting">Unit ID</th>
                                                          <th className="sorting">Building</th>
                                                          <th className="sorting">Floor</th>
                                                          <th className="sorting">Name</th>
                                                          <th className="sorting">Email Address</th>
                                                          <th className="sorting">Status</th>
                                                          <th className="sorting">Activated</th>
                                                          <th className="sorting">Expiring</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody data-test="table-body">
                                                        <tr>
                                                          <td>217</td>
                                                          <td>1</td>
                                                          <td>2</td>
                                                          <td>David Deslauriers</td>
                                                          <td>desl504+506@gmail.com</td>
                                                          <td>AUP not accepted</td>
                                                          <td>2023-09-18, 10:43 a.m.</td>
                                                          <td></td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="d-flex w-100 justify-content-end">
                                                  <div className="d-flex align-items-center justify-content-end">
                                                    <div data-test="datatable-pagination">
                                                      <div className="mdb-dataTables_paginate">
                                                        <ul data-test="pagination" className="pagination m-0">
                                                          <li data-test="page-item" className="disabled page-item"><a
                                                            data-test="page-link" aria-label="Prev"
                                                            className="page-link page-link"><span><i
                                                              className="fas fa-chevron-left"></i></span></a>
                                                          </li>
                                                          <li data-test="page-item" className="disabled page-item"><a
                                                            data-test="page-link" aria-label="Next"
                                                            className="page-link page-link"><span><i
                                                              className="fas fa-chevron-right"></i></span></a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        data-test="card"
                                        className="card"
                                        style={{ "--card-color": this.state.navbarColor }}
                                      >
                                        <div data-test="card-body" className="card-body">
                                          <h2
                                            style={{ '--primary-text-color': this.state.primaryTextColor }}
                                          >Renew or Confirm Upcoming Expirations</h2>
                                          <div className="expandCollapseExpirations">
                                            <p>Show More +</p>
                                          </div>
                                          <div className="upcomingExpirations">
                                            <div data-test="datatable" className="mdb-datatable dt-bootstrap4 upcomingExpirations">
                                              <div className="row">
                                                <div data-test="mdb-datatable-table" className="col-sm-12">
                                                  <div data-test="table" className="table-responsive">
                                                    <table entries="10"
                                                      className="table table-borderless table-hover mdb-dataTable"
                                                      style={{ '--primary-text-color': this.state.primaryTextColor }}
                                                    >
                                                      <thead data-test="datatable-head" className="mdb-dataTable-head">
                                                        <tr>
                                                          <th className="sorting">Unit ID</th>
                                                          <th className="sorting">Building</th>
                                                          <th className="sorting">Floor</th>
                                                          <th className="sorting">Name</th>
                                                          <th className="sorting">Email Address</th>
                                                          <th className="sorting">Status</th>
                                                          <th className="sorting">Expiring</th>
                                                          <th className="sorting">Action</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody data-test="table-body">
                                                        <tr>
                                                          <td colspan="8">No matching records found</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="d-flex w-100 justify-content-end">
                                                  <div className="d-flex align-items-center justify-content-end">
                                                    <div data-test="datatable-pagination">
                                                      <div className="mdb-dataTables_paginate">
                                                        <ul data-test="pagination" className="pagination m-0">
                                                          <li data-test="page-item" className="disabled page-item"><a
                                                            data-test="page-link" aria-label="Prev"
                                                            className="page-link page-link"><span><i
                                                              className="fas fa-chevron-left"></i></span></a>
                                                          </li>
                                                          <li data-test="page-item" className="disabled page-item"><a
                                                            data-test="page-link" aria-label="Next"
                                                            className="page-link page-link"><span><i
                                                              className="fas fa-chevron-right"></i></span></a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                </div>
                              </div>
                            </MDBTabPane>
                            <MDBTabPane tabId="8" role="tabpanel">
                              <div id="subscriberUI" className="displayPreview" data-theme="darkMode-false">
                                <div class="react-joyride"></div>
                                <div class="Toastify"></div>
                                <nav
                                  data-test="navbar"
                                  class="navbar subscriberNavbar"
                                  role="navigation"
                                  style={{ "--navbar-color": this.state.navbarColor }}
                                >
                                  <div class="Logo first-step" darkmode="false">
                                    <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFileNameText} alt="Logo" />
                                    {/* {
                                      this.state.file !== undefined ?
                                        <img src={this.state.logoFileNameDesktopMobileAfterLogin} alt="Logo" /> :
                                        <img src={"/" + this.state.s3Directory + "/" + this.state.logoFileNameDesktopMobileAfterLogin} alt="Logo" />
                                    } */}
                                  </div>
                                  <div class="networkName">
                                    <h1
                                      style={{ '--navbar-text-color': this.state.primaryTextColor }}
                                    >My Wi-Fi Network Dashboard</h1>
                                  </div>
                                  <div class="account">
                                    <nav
                                      data-test="navbar"
                                      class="navbar-light white lighten-4 navbar"
                                      role="navigation"
                                      style={{ "--navbar-color": this.state.navbarColor }}
                                    >
                                      <div data-test="navbar-brand" class="navbar-brand"></div>
                                      <label
                                        style={{ "marginTop": "0px" }}
                                      >
                                        <div
                                          class="react-toggle react-toggle--checked colorMode"
                                        >
                                          <div
                                            class="react-toggle-track"
                                            style={{ '--base-color': this.state.primaryColor }}
                                          >
                                            <div class="react-toggle-track-check">
                                              <i data-test="fa" class="fa fa-sun"></i>
                                            </div>
                                            <div
                                              class="react-toggle-track-x"
                                              style={{ opacity: '1' }}
                                            >
                                              <i data-test="fa" class="fa fa-moon"></i>
                                            </div>
                                          </div>
                                          <div class="react-toggle-thumbDisplayOptionsSubscriber"></div>
                                          <input
                                            class="react-toggle-screenreader-only"
                                            type="checkbox"
                                            checked=""
                                          // onClick={this.handleSwitchChange(1)}
                                          />
                                        </div>
                                      </label>
                                      <div class="select"><select class="">
                                        <option value="en-US">English</option>
                                        <option value="es-SP">Spanish</option>
                                      </select></div>
                                      <button
                                        data-test="navbar-toggler"
                                        type="button"
                                        class="navbar-toggler"
                                        onClick={this.toggleSingleCollapse("collapse1")}
                                      >
                                        <span
                                          class="navbar-toggler-icon2"
                                          style={{
                                            backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(` + rgb['r'] + ',' + rgb['g'] + ',' + rgb['b'] + `)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")`
                                          }}
                                        >
                                        </span>
                                      </button>
                                      {/* <MDBNavbarToggler
								onClick={this.toggleSingleCollapse("collapse1")}
							/> */}
                                      <MDBCollapse
                                        id="navbarCollapse1"
                                        isOpen={this.state.collapse1}
                                        navbar
                                      >
                                        <MDBNavbarNav left>
                                          <MDBNavItem active>
                                            <div
                                              onClick={this.toggleSingleCollapse(
                                                "collapse2"
                                              )}
                                              className="topMenuItem"
                                              style={{ '--base-color': this.state.primaryColor }}
                                            >
                                              <p
                                                style={{ '--navbar-text-color': this.state.primaryTextColor }}
                                              >
                                                <MDBIcon
                                                  icon="concierge-bell"
                                                  data-toggle="tooltip"
                                                  title="Support Settings"
                                                />
                                                Support
                                                <MDBIcon icon="sort-down" />
                                              </p>
                                            </div>
                                            <MDBCollapse
                                              id="navbarCollapse2"
                                              isOpen={this.state.collapse2}
                                              navbar
                                            >
                                              <MDBNavItem>
                                                <>
                                                  <MDBNavLink
                                                    to="#!"
                                                    style={{
                                                      '--base-color': this.state.primaryColor,
                                                      '--navbar-text-color': this.state.primaryTextColor
                                                    }}
                                                  >
                                                    View Tickets
                                                  </MDBNavLink>
                                                  <MDBNavLink
                                                    to="#!"
                                                    style={{
                                                      '--base-color': this.state.primaryColor,
                                                      '--navbar-text-color': this.state.primaryTextColor
                                                    }}
                                                  >
                                                    Add Ticket
                                                  </MDBNavLink>
                                                </>
                                                <MDBNavLink
                                                  to="#!"
                                                  style={{
                                                    '--base-color': this.state.primaryColor,
                                                    '--navbar-text-color': this.state.primaryTextColor
                                                  }}
                                                >
                                                  FAQs
                                                </MDBNavLink>
                                                <MDBNavLink
                                                  to="#!"
                                                  style=
                                                  {{
                                                    '--base-color': this.state.primaryColor, '--navbar-text-color': this.state.primaryTextColor
                                                  }}
                                                >
                                                  Contact Us
                                                </MDBNavLink>
                                              </MDBNavItem>
                                            </MDBCollapse>
                                          </MDBNavItem>
                                          <MDBNavItem>
                                            <div
                                              onClick={this.toggleSingleCollapse(
                                                "collapse3"
                                              )}
                                              className="topMenuItem"
                                              style={{
                                                '--base-color': this.state.primaryColor,
                                                '--navbar-text-color': this.state.primaryTextColor
                                              }}
                                            >
                                              <p>
                                                <MDBIcon
                                                  icon="user"
                                                  data-toggle="tooltip"
                                                  title="Account Settings"
                                                />
                                                My Account
                                                <MDBIcon icon="sort-down" />
                                              </p>
                                            </div>
                                            <MDBCollapse
                                              id="navbarCollapse3"
                                              isOpen={this.state.collapse3}
                                              navbar
                                            >
                                              <MDBNavLink
                                                to="#!"
                                                onClick={
                                                  this.toggleMyAccount
                                                }
                                                style={{
                                                  '--base-color': this.state.primaryColor,
                                                  '--navbar-text-color': this.state.primaryTextColor
                                                }}
                                              >
                                                Account Settings
                                              </MDBNavLink>
                                              <MDBNavLink
                                                to="#!"
                                                onClick={
                                                  this
                                                    .toggleViewTransactions
                                                }
                                                style={{
                                                  '--base-color': this.state.primaryColor,
                                                  '--navbar-text-color': this.state.primaryTextColor
                                                }}
                                              >
                                                View Transactions
                                              </MDBNavLink>
                                              <MDBNavItem>
                                                <MDBNavLink
                                                  to="/dashboard"
                                                  style={{
                                                    '--base-color': this.state.primaryColor,
                                                    '--navbar-text-color': this.state.primaryTextColor
                                                  }}
                                                >
                                                  Venue Admin
                                                </MDBNavLink>
                                                <MDBNavLink
                                                  to="/networkdashboard"
                                                  style={{
                                                    '--base-color': this.state.primaryColor,
                                                    '--navbar-text-color': this.state.primaryTextColor
                                                  }}
                                                >
                                                  Network Admin
                                                </MDBNavLink>
                                              </MDBNavItem>
                                            </MDBCollapse>
                                          </MDBNavItem>
                                          <MDBNavItem>
                                            <MDBNavLink
                                              to="#!"
                                              style={{
                                                '--base-color': this.state.primaryColor,
                                                '--navbar-text-color': this.state.primaryTextColor
                                              }}
                                            >
                                              Logout
                                            </MDBNavLink>
                                          </MDBNavItem>
                                        </MDBNavbarNav>
                                      </MDBCollapse>
                                    </nav>
                                  </div>
                                </nav>
                                <div
                                  class="mainDashboard subscriberUIDashboard"
                                  style={{
                                    '--background-color': this.state.backgroundColor,
                                    'padding': '20px'
                                  }}
                                >
                                  <MDBContainer
                                    style={{
                                      width: "100%",
                                      paddingRight: "15px",
                                      paddingLeft: "15px",
                                      marginRight: "auto",
                                      marginLeft: "auto",
                                      display: "block",
                                      maxWidth: "100%"
                                    }}
                                  >
                                    <MDBRow>
                                      <MDBCol lg="6">
                                        <div
                                          class="ctaButtonNoAction"
                                          style={{ '--base-color': this.state.primaryColor }}
                                        >
                                          <h2><i data-test="fa" class="fa fa-wifi"></i> Manage Profiles</h2>
                                        </div>
                                        <div
                                          data-test="card"
                                          class="card"
                                          style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "230px+!important", marginBottom: "20px", "--card-color": this.state.navbarColor }}
                                        >
                                          <div data-test="card-body" class="card-body messageCardBody1" style={{ flex: "unset" }}>
                                            <h4 style={{ color: "rgb(187, 187, 187)" }}>No keys to display yet</h4>
                                          </div>
                                        </div>
                                      </MDBCol>
                                      <MDBCol lg="6">
                                        <div class="keyInformation">
                                          <div
                                            class="ctaButtonNoAction"
                                            style={{ '--base-color': this.state.primaryColor }}
                                          >
                                            <h2><i data-test="fa" class="fa fa-wifi"></i>Cumulative Bandwidth</h2>
                                          </div>
                                          <div
                                            data-test="card"
                                            class="card"
                                            style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "230px+!important", marginBottom: "20px", "--card-color": this.state.navbarColor }}
                                          >
                                            <div data-test="card-body" class="card-body">
                                              <div style={{ minHeight: "195px" }}>
                                                <img
                                                  src="../common/bandwidthPreview.png"
                                                  alt="Bandwitdth Graph Preview"
                                                  style={{ width: "100%" }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    <div data-test="row" class="row dashboardRow">
                                      <div data-test="col" class="col-sm-12 col-xl-6 devices-step">
                                        <div class="keyInformation">
                                          <div
                                            class="ctaButton"
                                            style={{ '--base-color': this.state.primaryColor }}
                                          >
                                            <h2><i data-test="fa" class="fa fa-desktop"></i> Devices</h2>
                                          </div>
                                          <div data-test="card" class="card"
                                            style={{ display: "flex", justifyContent: "center", alignItems: "center", "--card-color": this.state.navbarColor }}>
                                            <div data-test="card-body" class="card-body messageCardBody1" style={{ flex: "unset" }}>
                                              <h4 style={{ color: "rgb(187, 187, 187)" }}>No devices to display yet</h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div data-test="col" class="col-sm-12 col-xl-6 fourth-step">
                                        <div class="keyInformation">
                                          <div
                                            class="ctaButton"
                                            style={{ '--base-color': this.state.primaryColor }}
                                          >
                                            <h2><i data-test="fa" class="far fa-comment-alt"></i> Messages</h2>
                                          </div>
                                          <div data-test="card" class="card messageData" style={{ "--card-color": this.state.navbarColor }}>
                                            <div data-test="card-body" class="card-body messageCardBody1">
                                              <div data-test="datatable" class="mdb-datatable dt-bootstrap4 messageData">
                                                <div class="row">
                                                  <div data-test="mdb-datatable-table" class="col-sm-12">
                                                    <div data-test="table" class="table-responsive">
                                                      <table entries="10"
                                                        class="table table-borderless table-hover mdb-dataTable">
                                                        <thead
                                                          data-test="datatable-head"
                                                          class="mdb-dataTable-head"
                                                          style={{ '--base-color': this.state.primaryColor }}
                                                        >
                                                          <tr>
                                                            <th class="sorting">Received</th>
                                                            <th class="sorting">Subject</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody
                                                          data-test="table-body"
                                                        >
                                                          <tr>
                                                            <td
                                                              style={{ color: this.state.primaryTextColor }}>2023-07-06, 2:27 p.m.</td>
                                                            <td>
                                                              <div class="readMessage">
                                                                <p
                                                                  style={{ '--navbar-text-color': this.state.primaryTextColor }}>Test 3</p>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              style={{ color: this.state.primaryTextColor }}>2023-07-06, 2:26 p.m.</td>
                                                            <td>
                                                              <div class="readMessage">
                                                                <p
                                                                  style={{ '--navbar-text-color': this.state.primaryTextColor }}>Test 2</p>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="d-flex w-100 justify-content-end">
                                                    <div class="d-flex align-items-center justify-content-end">
                                                      <div data-test="datatable-pagination">
                                                        <div class="mdb-dataTables_paginate">
                                                          <ul data-test="pagination" class="pagination m-0">
                                                            <li data-test="page-item" class="disabled page-item"><a
                                                              data-test="page-link" aria-label="Prev"
                                                              class="page-link page-link"><span><i
                                                                class="fas fa-chevron-left"></i></span></a></li>
                                                            <li data-test="page-item" class="disabled page-item"><a
                                                              data-test="page-link" aria-label="Next"
                                                              class="page-link page-link"><span><i
                                                                class="fas fa-chevron-right"></i></span></a>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </MDBContainer>
                                </div>
                              </div>
                            </MDBTabPane>
                            <MDBTabPane tabId="9" role="tabpanel">
                              <div className="Login FindDeals displayPreview">
                                <div className="logoMobile">
                                  <div class="Logo first-step" darkmode="false">
                                    <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.loginDesktopLogoText} alt="Logo" />
                                    {/* <img src={"/" + this.state.s3Directory + "/" + this.state.loginDesktopLogo} alt="Logo" /> */}
                                  </div>
                                </div>
                                <MDBRow className="g-0 m-0">
                                  <MDBCol lg="12" className="ml-auto p-0">
                                    <div class="Logo first-step" darkmode="false">
                                      <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.loginDesktopLogoText} alt="Logo" />
                                    </div>
                                    <div
                                      className="topBanner"
                                      style={{
                                        "--banner-color-1": this.state.bannerColor1,
                                        "--banner-color-2": this.state.bannerColor2
                                      }}
                                    >
                                      <h2>Log into your {this.state.s3Directory} account</h2>
                                    </div>
                                    <div className="loginFormContainer">
                                      <form>
                                        <div className="loginIcon"></div>
                                        <FormGroup controlId="email" bsSize="large">
                                          <FormControl
                                            autoFocus
                                            type="email"
                                            value="####"
                                            placeholder="Email address"
                                          />
                                        </FormGroup>
                                        <div className="password">
                                          <FormGroup
                                            controlId="password"
                                            bsSize="large"
                                          >
                                            <FormControl
                                              value="*******"
                                              placeholder={this.state.s3Directory + " account password"}
                                              id="pass_log_id"
                                            />
                                            <span
                                              toggle="#password-field"
                                              className={"fas fa-eye field_icon"}
                                            ></span>
                                          </FormGroup>
                                        </div>
                                        <div
                                          data-test="row" class="row"
                                          style={{ "marginBottom": "20px" }}
                                        >
                                          <div data-test="col" class="col-lg-6">
                                            <div><a href="/reset">Forgot password?</a></div></div><div data-test="col" class="col-lg-6 rememberMe" style={{
                                              "float": "right"
                                            }}>
                                            <div
                                              class="form-check"
                                              style={{ "paddingTop": "0px" }}
                                            >
                                              <input
                                                data-test="input"
                                                type="checkbox"
                                                class="form-control form-check-input"
                                                id="rememberMe"
                                                name="rememberMe"
                                                aria-disabled="false"
                                                value=""
                                                style={{ "marginTop": "0px" }}
                                              />
                                              <label
                                                class="form-check-label"
                                                for="rememberMe"
                                                data-error=""
                                                data-success=""
                                                id=""
                                                aria-labelledby=""
                                                style={{ "marginTop": "0px" }}
                                              >Remember me</label></div></div></div>
                                        <MDBBtn
                                          block
                                          color="danger"
                                          type="submit"
                                          className="btn Ripple-parent btn-default centered"
                                          style={{
                                            '--base-color': this.state.primaryColor
                                          }}
                                        >
                                          Login
                                        </MDBBtn>
                                        <div
                                          className="loginDisclosure FAQ"
                                          style={{ "marginTop": "20px" }}
                                        >
                                          <div className="loginIcon">
                                            <MDBIcon
                                              icon="user"
                                              className="faUser"
                                            />
                                            <p>
                                              Your email address and password
                                              are used to log into your {this.state.s3Directory} account. You can
                                              access your Wi-Fi Network
                                              password, profiles and more
                                              after logging into your {this.state.s3Directory} account. Still have
                                              questions?{" "}
                                              <Link
                                                to="#"
                                              >
                                                Click here
                                              </Link>
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="loginDisclosure"
                                          style={{ "paddingBottom": "40px" }}
                                        >
                                          <div className="loginIcon">
                                            <MDBIcon
                                              icon="user"
                                              className="faUser"
                                            />
                                            <p>
                                              <a href="/finddeals">
                                                Click here
                                              </a>{" "}
                                              to sign up
                                            </p>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </MDBCol>
                                  <MDBCol></MDBCol>
                                </MDBRow>
                                <div
                                  class="footerCreateAccount"
                                  style={{ "--base-color": this.state.primaryColor }}
                                >
                                  <p
                                    style={{ "color": "#FFF" }}
                                  >{this.state.s3Directory} Support +1 866-555-5555</p>
                                  <p
                                    class="text-piped"
                                    style={{ "--primary-text-color": "#FFF" }}
                                  ><span class="piped"
                                    style={{ "color": "#FFF" }}>© Copyright 2012 - 2023 {this.state.s3Directory}</span><span class="piped"
                                      style={{ "color": "#FFF" }}>All Rights Reserved</span><span class="piped"
                                        style={{ "color": "#FFF" }}><button>Terms of Service </button></span></p></div>
                              </div>
                            </MDBTabPane>
                            <MDBTabPane tabId="10" role="tabpanel">
                              <div class="modal-dialog-centered modal-lg modal-dialog form" role="document">
                                <div
                                  class="modal-content"
                                  style={{ "--modal-background-color": this.state.modalColor }}
                                >
                                  <div data-test="modal-header" class="modal-header">
                                    <h4
                                      class="modal-title"
                                      style={{ "--primary-text-color": this.state.primaryTextColor }}
                                    >
                                      <h4>Modal Title</h4>
                                      <p>* = required</p>
                                    </h4><button type="button" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                                  </div>
                                  <div data-test="modal-body" class="modal-body">
                                    <form class="needs-validation" novalidate="">
                                      <div data-test="row" class="row">
                                        <div data-test="col" col="6" class="col-sm-12 col-lg-12">
                                          <label
                                            for="defaultFormLoginEmailEx"
                                            class="black-text"
                                            style={{ "--primary-text-color": this.state.primaryTextColor }}
                                          >Email *</label>
                                          <input
                                            required=""
                                            id="email"
                                            type="email"
                                            class="form-control emailInput"
                                            value=""
                                            style={{
                                              "--input-color": this.state.inputBoxColor,
                                              "--primary-text-color": this.state.primaryTextColor
                                            }}
                                          />
                                          <div class="invalid-feedback">Please provide a valid email.</div>
                                        </div>
                                        <div data-test="col" col="6" class="col"></div>
                                      </div>
                                      <div data-test="row" class="row">
                                        <div data-test="col" class="col-sm-12 col-lg-6">
                                          <label
                                            for="defaultFormLoginEmailEx"
                                            class="black-text"
                                            style={{ "--primary-text-color": this.state.primaryTextColor }}
                                          >First Name *</label>
                                          <input
                                            required=""
                                            name="firstName"
                                            id="firstName"
                                            class="form-control"
                                            value=""
                                            style={{
                                              "--input-color": this.state.inputBoxColor,
                                              "--primary-text-color": this.state.primaryTextColor
                                            }}
                                          />
                                          <div class="invalid-feedback">Please provide a valid first name.</div>
                                        </div>
                                        <div data-test="col" class="col-sm-12 col-lg-6">
                                          <label
                                            for="defaultFormLoginEmailEx"
                                            class="black-text"
                                            style={{ "--primary-text-color": this.state.primaryTextColor }}
                                          >Last Name *</label>
                                          <input
                                            required=""
                                            class="form-control"
                                            name="lastName"
                                            id="lastName"
                                            value=""
                                            style={{
                                              "--input-color": this.state.inputBoxColor,
                                              "--primary-text-color": this.state.primaryTextColor
                                            }}
                                          />
                                          <div class="invalid-feedback">Please provide a valid last name.</div>
                                        </div>
                                      </div>
                                      <div class="text-right submitButton">
                                        <button
                                          data-test="button"
                                          type="submit"
                                          class="btn Ripple-parent btn-danger"
                                          style={{ "--button-color": this.state.primaryColor }}
                                        >Add Administrator</button></div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </MDBTabPane>
                          </MDBTabContent>
                        </> : ""}
                    </MDBTabPane>
                    <MDBTabPane tabId="2" role="tabpanel">
                      { }
                      <MDBRow>
                        <MDBCol sm="12" lg="12">
                          <label htmlFor="exampleFormControlTextarea1">Description Content</label>
                          <input
                            required
                            name="descriptionContent"
                            id="descriptionContent"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.descriptionContent}
                          />
                        </MDBCol>
                        <MDBCol sm="12" lg="12">
                          <label
                            htmlFor="defaultFormLoginEmailEx"
                            className="black-text"
                          >
                            Fav Icon
                          </label>
                          <br />
                          <div className="Logo first-step">
                          <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFavIconText} alt="Logo" />
                            {/* {this.state.fileLogoFavIcon !== undefined ?
                              <img src={this.state.logoFavIcon} alt="Logo" /> :
                              <img src={window.location.protocol + '//' + window.location.host + "/" + this.state.s3Directory + "/" + this.state.logoFavIconText} alt="Logo" />
                            } */}
                          </div>
                          <label
                                htmlFor="defaultFormLoginEmailEx"
                                className="black-text"
                              >
                                File Name
                              </label>
                              <input
                                required
                                name="loginDesktopLologoFavIconTextgoText"
                                id="logoFavIconText"
                                className="form-control"
                                onChange={this.changeHandler}
                                value={this.state.logoFavIconText}
                                disabled={this.state.disabled}
                              />
                          {/* 
                            File Name: {this.state.logoFavIconText}
                          <div>
                            <label
                              for="file-upload-favicon"
                              class="custom-file-upload"
                              style={{ '--primary-text-color': "#FFF" }}
                            >
                              <MDBIcon icon="cloud-upload-alt" /> Custom Upload
                            </label>
                            <input
                              id="file-upload-favicon"
                              type="file"
                              onChange={this.handleFileChangeFavIcon}
                            />
                          </div> */}
                        </MDBCol>
                        <MDBCol sm="12" lg="12">
                          <label htmlFor="exampleFormControlTextarea1">Title Text Content</label>
                          <input
                            required
                            name="titleTextContent"
                            id="titleTextContent"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.titleTextContent}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBTabPane>
                    <MDBTabPane tabId="3" role="tabpanel">
                    <p>
												<strong>Supported Locales</strong>
											</p>
                      <OperatorSupportedLocalesTable
                        supportedLocalesData={this.state.allowedLocales}
                      />
                      <MDBRow>
                        <MDBCol col="6" sm="12" lg="12">
                          <div>
                            <label htmlFor="defaultFormLoginSMSEx" className="black-text">
                              Cancellation Options Method
                            </label>
                            <SelectSearch
                              id="selectCancellationOption"
                              search={true}
                              filterOptions={fuzzySearch}
                              options={this.state.cancelMethodOptions}
                              // isOptionSelected={true}
                              classNamePrefix="language-select"
                              onChange={this.changeHandlerDisplayCancelMethod}
                              value={this.state.cancellationMethod}
                              placeholder="Select..."
                            />
                          </div>
                        </MDBCol>
                        <MDBCol sm="12" lg="12">
                          <label htmlFor="exampleFormControlTextarea1">Google Analytics ID</label>
                          <input
                            required
                            name="googleAnalyticsID"
                            id="googleAnalyticsID"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.googleAnalyticsID}
                          />
                        </MDBCol>
                        <MDBCol sm="12" lg="12">
                          <label htmlFor="exampleFormControlTextarea1">Privacy Policy URL</label>
                          <input
                            required
                            name="privacyPolicyURL"
                            id="privacyPolicyURL"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.privacyPolicyURL}
                          />
                        </MDBCol>
                        <MDBCol col="12">
                          <MDBInput
                            label="Show Footer On Login Page"
                            type="checkbox"
                            id="showFooterOnLogin"
                            name="showFooterOnLogin"
                            onChange={this.toggleCheckboxValueShowFooter}
                            checked={this.state.showFooterOnLogin}
                          >
                            {/* <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                                        <MDBIcon far icon="question-circle" />
                                                        <span>
                                                            The venue will display in your shopping cart for self sign up.
                                                        </span>
                                                    </MDBTooltip> */}
                          </MDBInput>
                        </MDBCol>
                      </MDBRow>
                    </MDBTabPane>
                    <MDBTabPane tabId="4" role="tabpanel">
                      <MDBRow>
                        <MDBCol lg="12">
                          <MDBInput
                            label="Allow Self Cancellation"
                            type="checkbox"
                            id="allowSelfCancel"
                            name="allowSelfCancel"
                            onChange={this.toggleCheckboxValueAllowSelfCancel}
                            checked={this.state.allowSelfCancel}
                          >
                          </MDBInput>
                        </MDBCol>
                        <MDBCol lg="12">
                          <MDBInput
                            label="Allow Self Payment Method Updates"
                            type="checkbox"
                            id="allowSelfPaymentUpdate"
                            name="allowSelfPaymentUpdate"
                            onChange={this.toggleCheckboxValueAllowSelfPaymentUpdate}
                            checked={this.state.allowSelfPaymentUpdate}
                          >
                          </MDBInput>
                        </MDBCol>
                        <MDBCol lg="12">
                          <MDBInput
                            label="Allow Self Service Plan Update"
                            type="checkbox"
                            id="allowSelfServicePlanUpdate"
                            name="allowSelfServicePlanUpdate"
                            onChange={this.toggleCheckboxValueAllowSelfServicePlanUpdate}
                            checked={this.state.allowSelfServicePlanUpdate}
                          >
                          </MDBInput>
                        </MDBCol>
                      </MDBRow>
                    </MDBTabPane>
                    <MDBTabPane tabId="5" role="tabpanel">
                      <MDBRow>
                        <MDBCol lg="12">
                          <MDBInput
                            label="Externally Managed Support"
                            type="checkbox"
                            id="externallyManagedSupport"
                            name="externallyManagedSupport"
                            onChange={this.toggleCheckboxValueExternallyManagedSupport}
                            checked={this.state.externallyManagedSupport}
                          >
                          </MDBInput>
                        </MDBCol>
                        <MDBCol sm="12" lg="12">
                          <label htmlFor="exampleFormControlTextarea1">External Support System URL</label>
                          <input
                            required
                            name="externalSupportSystemURL"
                            id="externalSupportSystemURL"
                            className="form-control"
                            onChange={this.changeHandler}
                            value={this.state.externalSupportSystemURL}
                            disabled={!this.state.externallyManagedSupport}
                          />
                        </MDBCol>
                        <MDBCol lg="12">
                          <MDBInput
                            label="Show Equipment Status To Venue Admininstrator"
                            type="checkbox"
                            id="showEquipmentStatusVenueAdmin"
                            name="showEquipmentStatusVenueAdmin"
                            onChange={this.toggleCheckboxValueShowEquipmentStatusVenueAdmin}
                            checked={this.state.showEquipmentStatusVenueAdmin}
                          >
                          </MDBInput>
                        </MDBCol>
                        <MDBCol lg="12">
                          <MDBInput
                            label="Show Support Links"
                            type="checkbox"
                            id="showSupportLinks"
                            name="showSupportLinks"
                            onChange={this.toggleCheckboxValueShowSupportLinks}
                            checked={this.state.showSupportLinks}
                          >
                          </MDBInput>
                        </MDBCol>
                      </MDBRow>
                    </MDBTabPane>
                  </MDBTabContent>
                  <div className="text-right submitButton">
                    <MDBBtn
                      color="danger"
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.props.modify_sms === true ? (
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Modify Display Options"
                      )}
                    </MDBBtn>
                  </div>
                </form>
              </MDBModalBody>
            </>
          )}</>
    );
  }
}
export default withTranslation()(AddNewVenueEquipment);